import { Box, Button, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import DateTimePicker, { DateTimePickerProps } from 'Components/Inputs/DateTimePicker';
import { TEvent } from 'Models/Event/@types';
import moment from 'moment';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';


export interface EventCustomRecurringDateTimeInputProps extends IFieldProps<Partial<TEvent>> {
    fieldProps?: {
        offset?: string;
        timezone?: string;
        dateTimePickerProps?: DateTimePickerProps
    }
}

const EventCustomRecurringDateTimeInput: FC<EventCustomRecurringDateTimeInputProps> = (props) => {

    const classes = useStyles();

    const { formikProps, fieldProps = {} } = props;

    const { timezone } = fieldProps;

    const eventDates = [...(formikProps?.values.eventDates || [])];

    const handleValueChange = (value: { startDate?: string, endDate?: string }, index: number) => {
        formikProps?.setFieldValue(`eventDates[${index}]`, value);
    }

    const addAnotherDate = () => {
        eventDates[eventDates.length] = {
            startDate: moment().toISOString(),
            endDate: moment().toISOString(),
        };
        formikProps?.setFieldValue('eventDates', eventDates);
    }

    const handleRemove = (index: number) => {
        formikProps?.setFieldValue('eventDates', eventDates.filter((_, i) => i !== index));
    }

    return (
        <>
            {
                eventDates.map((ed, i) => {

                    const value = {
                        startDate: formikProps?.values['eventDates']?.[i]?.startDate,
                        endDate: formikProps?.values['eventDates']?.[i]?.endDate,
                    }

                    if (i === 0) return null;
                    return (
                        <Box key={i} display={'flex'} alignItems={'center'} mb={2} >
                            <DateTimePicker
                                label={`Date ${i + 1}`}
                                value={value}
                                onChange={v => handleValueChange(v, i)}
                                timezone={timezone}
                                {...(fieldProps?.dateTimePickerProps || {})}
                            />
                            <IconButton className={classes.crossIcon} onClick={e => handleRemove(i)} >
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )
                })
            }
            <Button variant={'text'} startIcon={<AddCircleOutlineIcon />} onClick={addAnotherDate} >
                Add Another Date
            </Button>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        crossIcon: {
            transform: 'translateY(14px)'
        }
    }))
})

export default EventCustomRecurringDateTimeInput