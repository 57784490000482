import React, { FC, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Pagination from 'Components/Pagination';
import { User } from 'Models/User/@types';
import { useStoreActions, useStoreState } from 'Stores';
import useAsyncTask from 'Hooks/useAsyncTask';
import { Box } from '@material-ui/core';
import Loader from 'Components/Loader';
import UserListCard from 'Features/User/UserListCard';
import DashboardActionButtonsList from 'Features/Dashboard/DashboardActionButtonsList';
import config from 'config';
import Checkbox from 'Components/Inputs/Checkbox';
import useToastMessage from 'Hooks/useToastMessage';

export interface FollowersListProps {}

const FollowersList: FC<FollowersListProps> = (props) => {
	const classes = useStyles();

	const { getFollowersList, showSendMessageDialog, postUser } = useStoreActions(
		({ User: { getFollowersList, showSendMessageDialog, postUser } }) => ({
			getFollowersList,
			showSendMessageDialog,
			postUser,
		})
	);
	const { followersList = [], user, token } = useStoreState(({ User: { followersList }, Auth: { user, token } }) => ({
		followersList,
		user,
		token,
	}));

	const withToast = useToastMessage();

	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

	const [extraParams, setExtraParams] = useState<{ pageNum: number }>({ pageNum: 0 });

	const nextPage = () => setExtraParams({ ...extraParams, pageNum: extraParams.pageNum + 1 });

	const prevPage = () => {
		if (extraParams.pageNum > 0) setExtraParams({ ...extraParams, pageNum: extraParams.pageNum - 1 });
	};

	const handleClick = (flag: boolean, user: User) => {
		if (flag) {
			setSelectedUsers([...selectedUsers, user.id]);
			return;
		}
		setSelectedUsers(selectedUsers.filter((id) => user.id !== id));
	};

	const handleSendMessage = () => {
		showSendMessageDialog({ userIds: selectedUsers });
	};

	const handleSendMessageToAll = () => {
		showSendMessageDialog({ sendToAll: true, userIds: [] });
	};

	const getFollowersListTask = useAsyncTask(getFollowersList);

	useEffect(() => {
		if (user?.id) getFollowersListTask.run({ id: user.id, params: { filter: { limit: 20, skip: extraParams.pageNum * 20 } } });
		// eslint-disable-next-line
	}, [extraParams, user?.id]);

	const postUserTask = useAsyncTask(postUser);

	const advanceFollowCheck = () => {
		withToast(
			async () => {
				await postUserTask.run({ data: { ...user, advanceFollow: !user?.advanceFollow } });
			},
			{ successToastMessage: `Advance follow ${user?.advanceFollow ? 'disabled' : 'enabled'}` }
		);
	};

	const loadingAdvanceFollow = postUserTask.status === 'PROCESSING';

	return (
		<>
			<div className={classes.actionsContainer}>
				<Pagination previous={prevPage} next={nextPage} />
				<Box display={'flex'} alignItems={'center'}>
					<Box mt={'4px'} mr={2}>
						<Checkbox
							disabled={loadingAdvanceFollow}
							isChecked={user?.advanceFollow}
							label={loadingAdvanceFollow ? 'Loading . . .' : 'Advance Follow'}
							onCheck={advanceFollowCheck}
						/>
					</Box>
					<DashboardActionButtonsList
						config={[
							{
								action: 'download',
								label: 'Download CSV',
								onClick: () => {
									window.open(`${config.get('API_URL')}users/followers-csv?access_token=${token?.accessToken}`);
								},
							},
							{
								action: 'send-message',
								label: 'Send Message',
								onClick: handleSendMessage,
								disabled: selectedUsers.length < 1,
							},
							{
								action: 'send-to-all',
								label: 'Send to all',
								onClick: handleSendMessageToAll,
								disabled: followersList.length === 0,
							},
						]}
						align={'rtl'}
					/>
				</Box>
			</div>
			<Box mt={4} position={'relative'}>
				{getFollowersListTask.status === 'PROCESSING' ? (
					<Loader />
				) : (
					followersList?.map((u) => {
						return <UserListCard key={u.id} user={u} onClick={handleClick} initialCheckedState={selectedUsers.includes(u.id)} />;
					})
				)}
			</Box>
		</>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		actionsContainer: {
			marginTop: 20,
			display: 'flex',
			justifyContent: 'space-between',
		},
	});
});

export default FollowersList;
