import { TextFieldProps } from '@material-ui/core/TextField';
import React, { FC } from 'react';
import GoogleUtils, { TGooglePlaceSuggestCategories } from '../../Resources/google';
import AutoSuggest from './AutoSuggest';

export type GoogleLocationSuggestProps = TextFieldProps & {
	onResultClick: (result: any) => void;
	suggestionsTypes: TGooglePlaceSuggestCategories[];
};

const GoogleLocationSuggest: FC<GoogleLocationSuggestProps> = (props) => {
	return (
		<>
			<AutoSuggest<any>
				fetchSuggestions={async (input) => {
					return ((await GoogleUtils.placeSuggest(input, props.suggestionsTypes)) || []) as any[];
				}}
				autoCompleteProps={{
					getOptionLabel: (option: any) => option.description,
					getOptionSelected: (option: any) => option.description,
				}}
				placeholder={'Search on Google'}
				onResultClick={props.onResultClick}
			/>
		</>
	);
};

export default GoogleLocationSuggest;
