import { CircularProgressProps } from '@material-ui/core';
import React, { FC } from 'react';
import LayoutWithLoader from './LayoutWithLoader';

// eslint-disable-next-line
interface LoaderProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    color?: CircularProgressProps['color'];
}

const Loader: FC<LoaderProps> = (props) => {


    return (
        <LayoutWithLoader loading={true} />
    )
}



export default Loader;