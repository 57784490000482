import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import DashboardBody from './DashboardBody';
import NavBar from './NavBar';
import { useStoreActions } from 'Stores';

const DashboardLayout = () => {

    const classes = useStyles();

    const { fetchAppConstants, getTagsList } = useStoreActions(({
        App: { fetchAppConstants },
        Tag: { getTagsList }
    }) => ({ fetchAppConstants, getTagsList }));

    useEffect(() => {
        fetchAppConstants();
        getTagsList();
        // eslint-disable-next-line 
    }, []);

    return (
        <>
            <NavBar />
            <div className={classes.root}>
                <DashboardBody />
            </div>
        </>
    )
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 32,
    },

});

const useStyles = makeStyles(STYLES);

export default DashboardLayout;