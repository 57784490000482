import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, memo } from 'react';

export interface PaginationProps {
	next?: () => void;
	previous?: () => void;
	disableNext?: boolean;
	disablePrevious?: boolean;
}

const Pagination: FC<PaginationProps> = (props) => {
	const classes = useStyles();
	const { next, previous, disablePrevious, disableNext } = props;
	return (
		<div className={classes.root}>
			<Button size="small" onClick={previous} disabled={disablePrevious}>
				{`< Previous Page`}
			</Button>
			<Button size="small" onClick={next} disabled={disableNext}>
				{`Next Page >`}
			</Button>
		</div>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	const {
		palette: { grey },
	} = theme;
	return createStyles({
		root: {
			display: 'flex',
			'& > button': {
				color: grey['300'],
				marginRight: 8,
			},
		},
	});
});

export default memo(Pagination);
