import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typo from 'Components/Typo';
import PGTextInput from 'Features/ReactFormsInputs/PGTextInput';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import AuthModel from 'Models/Auth';
import React, { FC } from 'react';
import { attachField, ReactForm } from 'react-forms';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

// @ts-ignore
attachField('textInput', <PGTextInput />)

const validationSchema = Yup.object({
    email: Yup.string().required().email()
})

export interface ForgotPasswordProps { }

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
    const classes = useStyles();

    const forgotPasswordTask = useAsyncTask(AuthModel.forgotPassword);

    const withToast = useToastMessage();

    const handleSubmit = (data: { email: string }) => {
        const origin = window.location.origin;
        withToast(async () => {
            await forgotPasswordTask.run(data.email, `${origin}/reset-password`);
        }, { successToastMessage: `Reset password link is successfully sent to ${data.email}` })
    }

    return (
        <div>
            <Typo fontVariant="sharp" weight="medium" variant="h3" component="div" className={classes.loginHeading}>Forgot Password</Typo>
            <ReactForm formId="forgot-password-form"
                config={[
                    {
                        type: 'textInput',
                        valueKey: 'email',
                        fieldProps: {
                            placeholder: 'Email',
                            fullWidth: true,
                            variant: 'outlined'
                        }
                    }
                ]}
                actionConfig={{
                    submitButtonText: ('SUBMIT'),
                    submitButtonLayout: 'fullWidth',
                    submitButtonProps: {
                        size: 'large',
                        className: classes.submitButton
                    },
                    containerClassNames: classes.buttonContainer,

                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                formSettings={{
                    verticalSpacing: 20,
                    horizontalSpacing: 2
                }}
                isInProgress={forgotPasswordTask.status === 'PROCESSING'}
            />
            <Link to={'/'} >
                <Typo fontVariant="semibold" className={classes.registerLink} variant="subtitle1" weight="regular">
                    Login
            </Typo>
            </Link>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        loginHeading: {
            marginBottom: 20,
            textAlign: 'center',
            color: '#505448',
        },
        buttonContainer: {
            marginTop: 20,
        },
        submitButton: {
            backgroundColor: theme.palette.grey["700"],
            height: 32,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 14,
            fontFamily: 'NeuePlak-ExtendedSemiBold',
            '&:hover': {
                backgroundColor: theme.palette.grey["700"] + ' !important',
            }
        },
        registerLink: {
            textDecoration: 'none',
            color: theme.palette.grey["600"],
            marginTop: '16px',
            lineHeight: '22px',
            textAlign: 'center'
        },
    }))
})

export default ForgotPassword