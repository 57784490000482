import { Avatar, Box, Chip } from '@material-ui/core';
import AutoSuggest from 'Components/Inputs/AutoSuggest';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import _get from 'lodash/get';
import TwitterModel from 'Models/twitter';
import { TwitterAutocompleteResult } from 'Models/twitter/@types';
import React, { FC, useEffect, useState } from 'react';
import { IFieldProps } from 'react-forms';

export interface TwitterUserSearchProps extends IFieldProps {}

const TwitterUserSearch: FC<TwitterUserSearchProps> = (props) => {
	const { formikProps, fieldConfig } = props;

	const valueKey = fieldConfig?.valueKey;

	const [handles, setHandles] = useState<Set<string>>(new Set(_get(formikProps?.values, valueKey as string)));

	const onResultClick = (item: TwitterAutocompleteResult) => {
		setHandles(new Set(handles).add(item.handle));
	};

	useEffect(() => {
		formikProps?.setFieldValue(valueKey!, Array.from(handles));
		// eslint-disable-next-line
	}, [handles]);

	return (
		<>
			<PGInputLabel>Search twitter users</PGInputLabel>
			<AutoSuggest<TwitterAutocompleteResult>
				fetchSuggestions={async (input) => {
					return await TwitterModel.searchUsers(input);
				}}
				autoCompleteProps={{
					getOptionSelected: (user) => !!user.handle,
					renderOption: (option) => <Chip label={`${option.handle}`} avatar={<Avatar src={option.profileImage} />} />,
					getOptionLabel: (o) => o.handle,
				}}
				placeholder={'Search twitter users'}
				onResultClick={onResultClick}
				retainInputAfterSelect={false}
			/>
			<Box mt={3}>
				{Array.from(handles.keys()).map((h) => {
					return (
						<Chip
							key={h}
							label={`${h}`}
							onDelete={(e) => {
								const newSet = new Set(handles);
								newSet.delete(h);
								setHandles(newSet);
							}}
						/>
					);
				})}
			</Box>
		</>
	);
};
export default TwitterUserSearch;
