import { request } from "Resources/AxiosUtils";
import config from "config";

class AuthModel {

    static forgotPassword = (email: string, redirectUrl?: string) => request({ url: `/users/forgetPassword`, method: 'post', data: { email, redirectUrl } });

    static twitterAuth = (token: string) => request<{ redirectUrl: string }>({
        url: `${config.get('API_URL')}twitters/authorize-account?redirectUrl=${window.location.origin}/dashboard/users&access_token=${token}`,
    })

}

export default AuthModel;