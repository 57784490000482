import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import Loader from 'Components/Loader';
import EventPreviewBox from 'Features/Event/EventPreviewBox';
import { useEventDetails } from 'Features/Event/useEventDetails';
import { Fonts } from 'MuiTheme';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStoreActions } from 'Stores';
import useAsyncTask from 'Hooks/useAsyncTask';
export interface EventPreviewProps extends RouteComponentProps<{ id: string }> { }

const EventPreview: FC<EventPreviewProps> = (props) => {
    const { match, history } = props;
    const eventId = match.params['id'];
    const { eventDetails, loadingEvent } = useEventDetails(eventId);
    const { togglePublish } = useStoreActions(({ Events: { togglePublish } }) => ({ togglePublish }));
    const togglePublishTask = useAsyncTask(togglePublish);
    const classes = useStyles();

    const handlePublish = () => {
        togglePublishTask.run({ id: eventDetails.id, publish: true });
    }
    const goToEditing = () => {
        history?.push(`/dashboard/events/update/${eventDetails.id}`);
    }
    return (
        <Box>
            {
                loadingEvent ? <Loader /> :
                    <>{
                        eventDetails?.id ? <Box>
                            <div className={classes.actionsContainer}>
                                <Button variant='text' onClick={goToEditing} color='default' startIcon={<ArrowBack style={{ fontSize: 12, marginRight: -8, marginBottom: -4 }} />}>
                                    Continue Editing
                            </Button>
                                <Button disabled={false} className={classes.button} size={"large"} variant={'contained'} color={'secondary'} onClick={handlePublish} >
                                    PUBLISH
                            </Button>
                            </div>
                            <Box>
                                <Typography variant='h5' style={{ color: '#879be4' }}>{`Preview - ${eventDetails?.name}`}</Typography>
                            </Box>
                            {eventDetails?.id ? <EventPreviewBox event={eventDetails} /> : null}
                        </Box> : null
                    }
                    </>
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey }, typography: { h6 } } = theme
    return (createStyles({
        root: {
            height: '100%'
        },
        actionsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `2px Solid ${grey['500']}`,
            paddingBottom: 20
        },
        button: {
            marginRight: 10,
            ...h6,
            fontFamily: Fonts.Font1Bold
        }
    }))
})

export default EventPreview