import moment from 'moment-timezone';

const DateTimeUtils = {
    getTimeWithOffset(time: string, offset: string = "UTC +00:00", format?: string) {
        const timeDiff = moment().utcOffset(offset.split(' ')[1]).utcOffset();
        const result = (time ? moment.utc(time, format) : moment()).add(timeDiff, 'm').format(format);
        return result;
    },
    setTimeWithoutOffset(time: string, offset: string = "UTC +00:00", format?: string) {
        const timeDiff = moment().utcOffset(offset.split(' ')[1]).utcOffset();
        let result = moment.utc(time, format).subtract(timeDiff, 'm').format(format);
        return result
    },
    getOffsetFromTimezone(timezone: string = 'GMT') {
        const offset = moment.tz(timezone).utcOffset();
        const offsetString = offset > 0 ? moment.utc('00:00', 'HH:mm').add(offset, 'm').format('HH:mm') : moment.utc('00:00', 'HH:mm').subtract(offset, 'm').format('HH:mm')
        // reverse the sign
        return (offset < 0 ? '-' : '+') + offsetString
    }
}

export default DateTimeUtils;