import React, { FC, useState, useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { UserFilterType } from './UsersFilter';
import { Role } from 'Models/User/@types';
import Typo from 'Components/Typo';
import { useStoreState } from 'Stores';
import { Option } from 'Models/App/@types';
import utilities from 'Resources/Utils';

export interface UserRoleFilterProps {
    onChange: (type: UserFilterType, value: Role, toggle: boolean) => void
    clear?: boolean
}


const UserRoleFilter: FC<UserRoleFilterProps> = ({ onChange, clear }) => {
    const classes = useStyles();

    const [selected, setSelected] = useState<Role>();

    const { appConstants, summary } = useStoreState(({ App: { appConstants, summary } }) => ({ appConstants, summary }));

    useEffect(() => {
        if (clear) {
            setSelected(undefined);
        }
    }, [clear])

    const filterList = ([...(appConstants?.Roles || []), { name: 'User', value: 'USER' }] as Option<Role>[]).map(r => {
        if (!summary) return r;
        const { communityCount, curatorCount, total, adminCount = 0 } = summary.user
        switch (r.value) {
            case 'ADMIN':
                return { ...r, name: `${r.name} (${utilities.getTotalCountString(adminCount)})` }
            case 'COMMUNITY':
                return { ...r, name: `${r.name} (${utilities.getTotalCountString(communityCount)})` }
            case 'CURATOR':
                return { ...r, name: `${r.name} (${utilities.getTotalCountString(curatorCount)})` }
            case 'USER':
                return { ...r, name: `${r.name} (${utilities.getTotalCountString(total - (curatorCount + communityCount + adminCount))})` }
            default: return {} as Option<Role>;
        }
    })

    return (
        <Box className={classes.wrapper}>
            {
                filterList.map((r, i) => {
                    return (
                        <Box key={i} mr={1} className={selected === r.value ? classes.border : ''}>
                            <Button
                                style={{ borderStyle: selected === r.value ? '1px solid black' : 'none' }}
                                className={classes.button} variant='contained' color='default' onClick={() => { setSelected(selected === r.value ? undefined : r.value); onChange('role', r.value, selected === r.value); }}>
                                <Typo weight='bold' variant='overline'>{r.name}</Typo>
                            </Button></Box>

                    )
                })
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap'
        },
        button: {
            backgroundColor: '#e5e5e5'
        },
        border: {
            border: `1px solid black`,
            borderRadius: 4
        }
    }))
})

export default UserRoleFilter