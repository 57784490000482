import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import useToastMessage from 'Hooks/useToastMessage';
import { TEvent } from 'Models/Event/@types';
import moment from 'moment';
import React, { FC, useCallback, memo, useContext } from 'react';
import { useHistory } from 'react-router';
import RoleUtils from 'Resources/RoleUtils';
import { useStoreActions, useStoreState } from 'Stores';
import EntityCard from '../../Components/Card/EntityCard';
import config from 'config';
import _iEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { ToastContext } from 'Components/Toast/AppToast';
import { publishValidationSchema } from 'Screens/Events/EventForm';

export interface EventCardProps {
	event: TEvent;
	initialCheckedState?: boolean;
	onClick?: (flag: boolean, event: TEvent) => void;
	publishEvent?: (id: string, status: boolean) => void;

	afterDelete?: () => void;
	afterToggleFavourite?: (id: string, status: boolean) => void;
	afterToggleFeatured?: (id: string, status: boolean) => void;
	afterTogglePublish?: (id: string, status: boolean) => void;
}

const EventCard: FC<EventCardProps> = (props) => {
	const history = useHistory();

	const {
		event,
		initialCheckedState = false,
		onClick,
		afterDelete,
		afterToggleFavourite: afterFavourite,
		afterToggleFeatured,
		afterTogglePublish,
	} = props;
	const { primaryEventCategory, secondaryEventCategory } = event;
	const classes = useStyles({ primaryEventCategory, secondaryEventCategory });
	const { deleteEvent, togglePublish, toggleFavourite, toggleFeatured } = useStoreActions(
		({ Events: { deleteEvent, togglePublish, toggleFavourite, toggleFeatured } }) => ({
			deleteEvent,
			togglePublish,
			toggleFavourite,
			toggleFeatured,
		})
	);

	const { appConstants } = useStoreState(({ App: { appConstants } }) => ({ appConstants }));

	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

	const deleteEventTask = useAsyncTask(deleteEvent);
	const togglePublishTask = useAsyncTask(togglePublish);
	const toggleFavouriteTask = useAsyncTask(toggleFavourite);
	const toggleFeaturedTask = useAsyncTask(toggleFeatured);

	const withToast = useToastMessage();
	const { showToast } = useContext(ToastContext);

	const withConfirmationDialog = useConfirmationDialog();
	const handleDelete = () => {
		withConfirmationDialog(
			async () => {
				await deleteEventTask.run({ ids: [event.id] });
				afterDelete?.();
			},
			{ message: 'Are you sure you want to delete this event?', agreeText: 'Confirm' },
			{ successToastMessage: 'Event deleted successfully' }
		);
	};

	const handleTogglePublish = async () => {
		let hasError = false;
		if (!event.isPublished) {
			await publishValidationSchema.validate(event, { recursive: true, abortEarly: false }).catch((err) => {
				hasError = true;
				showToast('Complete the event form before publishing.', { variant: 'error' });
			});
		}
		if (hasError) return;
		withToast(
			async () => {
				await togglePublishTask
					.run({ id: event.id, publish: !event.isPublished })
					.then(() => afterTogglePublish?.(event.id, !event.isPublished));
			},
			{ successToastMessage: `Event ${event.isPublished ? 'unpublished' : 'published'} successfully` }
		);
	};

	const handleToggleFavourite = () => {
		withToast(
			async () => {
				await toggleFavouriteTask
					.run({ eventIds: [event.id], favourite: !event.isFavourited })
					.then(() => afterFavourite?.(event.id, !event.isFavourited));
			},
			{ successToastMessage: `Event ${event.isFavourited ? 'un-favourited' : 'favourited'} successfully` }
		);
	};

	const handleToggleFeatured = async () => {
		withToast(
			async () => {
				await toggleFeaturedTask
					.run({ data: { ...event, isFeatured: !event.isFeatured } })
					.then(() => afterToggleFeatured?.(event.id, !event.isFeatured));
			},
			{ successToastMessage: `Event ${event.isFeatured ? 'un-featured' : 'marked as featured'} successfully` }
		);
	};

	const isConfigHidden = useCallback(() => !RoleUtils.isAdmin(user) && event.curatorId !== user?.id, [user, event]);

	return (
		<EntityCard
			onClick={(flag: boolean) => {
				onClick?.(flag, event);
			}}
			title={event.name}
			subtitle={event.subtitle}
			initialCheckedState={initialCheckedState}
			description={`${config.get('CLIENT_URL')}events/${event.slug}`}
			isDescriptionUrl
			img={event.primaryImage}
			titleTopTextNode={
				<>
					<Typography
						style={{ color: primaryEventCategory?.color }}
						gutterBottom
						className={classes.category}
						variant={'subtitle1'}
						component={'span'}
					>
						{primaryEventCategory?.name || ''}
					</Typography>
					{primaryEventCategory?.name && secondaryEventCategory?.name && ' / '}
					<Typography
						style={{ color: secondaryEventCategory?.color }}
						gutterBottom
						className={classes.category}
						variant={'subtitle1'}
						component={'span'}
					>
						{secondaryEventCategory?.name || ''}
					</Typography>
				</>
			}
			actionsProps={{
				config: [
					{
						action: event.isFavourited ? 'favourited' : 'favourite',
						isIconButton: true,
						onClick: handleToggleFavourite,
						disabled: !event.isPublished || toggleFavouriteTask.status === 'PROCESSING',
						iconProps: { color: event.isFavourited ? 'primary' : undefined },
						hidden: true,
					},
					{
						action: event.isFeatured ? 'featured' : 'un-featured',
						label: event.isFeatured ? 'Featured' : 'Un-featured',
						noIcon: true,
						onClick: handleToggleFeatured,
						disabled: toggleFeaturedTask.status === 'PROCESSING',
						hidden: !RoleUtils.isAdmin(user),
					},
					{
						action: 'edit',
						label: 'Edit',
						noIcon: true,
						onClick: () => history.push(`/dashboard/events/update/${event.id}`),
						hidden: isConfigHidden(),
					},
					{ action: 'delete', label: 'Delete', noIcon: true, onClick: () => handleDelete(), hidden: isConfigHidden() },
					{
						action: 'status',
						label: event.isPublished ? 'Published' : 'Unpublished',
						noIcon: true,
						onClick: handleTogglePublish,
						disabled: togglePublishTask.status === 'PROCESSING',
						typographyProps: { color: event.isPublished ? 'secondary' : 'error' },
						hidden: isConfigHidden(),
					},
					{
						action: 'rsvp',
						label: 'Download RSVPs',
						noIcon: true,
						onClick: () =>
							window.open(
								`${config.get('API_URL')}events/${event.id}/reminderCsv?access_token=${localStorage.getItem('access_token')}`
							),
					},
					{
						action: 'duplicate',
						label: 'Duplicate Event',
						noIcon: true,
						onClick: () => history.push(`/dashboard/events/duplicate/${event.id}`),
					},
				],
				align: undefined,
			}}
			rightEndNode={
				<>
					<Typography variant={'caption'} color={'textSecondary'} component={'p'}>
						{moment(event.eventDates?.[0]?.startDate).format('YYYY-MM-DD, HH:mm')}
					</Typography>
					{!(event.frequency === 'oneTime' || event.frequency === 'custom') && event.repeatUntil ? (
						<Typography variant={'caption'} color={'textSecondary'} component={'p'}>
							This event is set to repeat on a {appConstants?.Event.frequency.find((f) => f.value === event.frequency)?.name || ''}{' '}
							basis until {moment(event.repeatUntil).format('YYYY-MM-DD')}
						</Typography>
					) : null}
					<Typography variant={'caption'} color={'textSecondary'} component={'p'}>
						{event.timeZone.name +
							'(' +
							(event.address.city ? event.address.city + '/' : '') +
							event.timeZone.offset.replace('UTC ', 'GMT') +
							')'}
					</Typography>
				</>
			}
		/>
	);
};

export default memo(EventCard, (a, b) => {
	const toCompare: (keyof EventCardProps)[] = ['event', 'initialCheckedState', 'publishEvent'];
	return _iEqual(_pick(a, toCompare), _pick(b, toCompare));
});

const useStyles = makeStyles<Theme, Pick<TEvent, 'primaryEventCategory' | 'secondaryEventCategory'>>((theme: Theme) =>
	createStyles({
		category: {
			textTransform: 'uppercase',
		},
	})
);
