import { TemplateData, CommunicationChannel } from "./@types";
import { request } from "Resources/AxiosUtils";

class Comm {
    static bulkMessage = (data: { userIds: string[]; templateData: TemplateData; channelTypes: CommunicationChannel[]; }) => request({
        url: `communicationServices/bulk-communication`,
        method: 'post',
        data
    })
}

export default Comm;