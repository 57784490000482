import { JSONType } from 'Typings/Global';
import { TEvent } from './@types';
import { parseAddress } from 'Models/Address/addressParser';
import { parseUser } from 'Models/User/userParser';

export const parseEvent = (obj: JSONType): TEvent => {
	const {
		type,
		userId,
		user,
		_primaryImage,
		_galleryImages,
		address,
		tagIds = [],
		tags = [],
		isFavourited,
		featured,
		isPrivate,
		tweetParams = {},
		...rest
	} = obj;

	return {
		...rest,
		eventType: type,
		curatorId: userId,
		primaryImage: _primaryImage,
		galleryImages: _galleryImages,
		curator: user ? parseUser(user) : undefined,

		primaryEventCategoryId: tagIds[0],
		primaryEventCategory: tags[0],

		secondaryEventCategory: tags[1],
		secondaryEventCategoryId: tagIds[1],
		address: parseAddress(address),
		isFavourited: !!isFavourited,
		isFeatured: !!featured,
		isPrivate: !!isPrivate,
		tweetParams: {
			isSensitive: !!tweetParams.possibly_sensitive,
		},
	} as TEvent;
};

export const parseEventFormData = (event: Partial<TEvent>): JSONType => {
	let formData: JSONType = {};
	const {
		id,
		address,
		curatorId,
		name,
		eventDates,
		description,
		location,
		placeId,
		eventType,
		ticketUrl,
		priceCategory,
		frequency,
		repeatUntil,
		timeZone,
		primaryImage,
		galleryImages,
		primaryEventCategoryId,
		secondaryEventCategoryId,
		isFeatured,
		platformName = '',
		platformUrl = '',
		subtitle,
		rsvpUrl,
		tweetParams,
		twitterHandles,
		isPrivate,
	} = event;
	formData = {
		...(id ? { id } : {}),
		name,
		eventDates: eventDates ? (frequency === 'custom' ? eventDates : [eventDates[0]]) : [],
		description,
		location,
		placeId,
		address: parseAddress(address),
		type: eventType,
		ticketUrl,
		priceCategory,
		frequency,
		repeatUntil,
		userId: curatorId,
		timeZone,
		tagIds: [primaryEventCategoryId, secondaryEventCategoryId].filter((x) => x),
		_primaryImage: primaryImage,
		_galleryImages: galleryImages,
		featured: isFeatured,
		platformName,
		platformUrl,
		subtitle,
		rsvpUrl,
		tweetParams: {
			possibly_sensitive: !!tweetParams?.isSensitive,
		},
		twitterHandles,
		isPrivate,
	};
	return formData;
};
