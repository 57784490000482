import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { Fonts } from 'MuiTheme';
import React from 'react';
import { MLFormContent, RowSchema } from 'react-forms';

export interface FormSectionProps<T> {
	heading: string
	config: Array<RowSchema>
	formId?: string
	formikProps?: FormikProps<Partial<T>>;
}

const FormSection = <T extends any>(props: FormSectionProps<T>) => {
	const { heading, config, formId = "", formikProps } = props
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography className={classes.heading}>{heading}</Typography>
			<MLFormContent schema={config} formikProps={formikProps} formId={formId} settings={{ verticalSpacing: 16, columnHorizontalPadding: 12 }} />
		</div>
	)
}

const useStyles = makeStyles<Theme>((theme) => {

	const { typography: { subtitle1 }, palette: { grey } } = theme;

	return (createStyles({
		root: {
			borderBottom: `2px Solid ${grey['500']}`,
			marginBottom: 30,
			paddingBottom: 50,
			'& > div': {
				marginLeft: -12
			}
		},
		heading: {
			...subtitle1,
			fontFamily: Fonts.Font1Bold,
			color: '#849ce6',
			margin: '10px 0'
		}
	}))
})

export default FormSection