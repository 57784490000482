import React, { FC } from 'react';
import EntityCard from '../../Components/Card/EntityCard'
import { Playlist } from 'Models/Playlist/@types'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useStoreActions, useStoreState } from 'Stores';
import { useHistory } from 'react-router';
import useToastMessage from 'Hooks/useToastMessage';
import RoleUtils from 'Resources/RoleUtils';

export interface PlayListCardProps {
    playlist: Playlist;
    initialCheckedState?: boolean;
    onClick?: (flag: boolean) => void;

    afterDelete?: () => void
    afterToggleFavourite?: (id: string, status: boolean) => void;
    afterToggleFeatured?: (id: string, status: boolean) => void;
    afterTogglePublish?: (id: string, status: boolean) => void;
}

const PlayListCard: FC<PlayListCardProps> = (props) => {

    const history = useHistory();
    const { playlist, initialCheckedState = false, onClick, afterToggleFavourite, afterToggleFeatured, afterTogglePublish, afterDelete } = props;
    const { category } = playlist
    const classes = useStyles({ category })

    const { deletePlaylist, togglePublish, toggleFavourite, toggleFeatured } = useStoreActions(({
        PlayList: {
            deletePlaylist, togglePublish, toggleFavourite, toggleFeatured
        },
    }) => ({ deletePlaylist, togglePublish, toggleFavourite, toggleFeatured }))

    const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

    const deletePlaylistTask = useAsyncTask(deletePlaylist);
    const togglePublishTask = useAsyncTask(togglePublish);
    const toggleFavouriteTask = useAsyncTask(toggleFavourite);
    const toggleFeaturedTask = useAsyncTask(toggleFeatured);
    const withConfirmationDialog = useConfirmationDialog();

    const withToast = useToastMessage();

    const handleDelete = () => {
        withConfirmationDialog(async () => {
            await deletePlaylistTask.run({ ids: [playlist.id] }).then(() => afterDelete?.());
        },
            { message: 'Are you sure you want to delete this playlist?', agreeText: 'Confirm' })
    }

    const handleTogglePublish = () => {
        withToast(async () => {
            await togglePublishTask.run({ id: playlist.id, publish: !playlist.isPublished }).then(() => {
                afterTogglePublish?.(playlist.id, !playlist.isPublished);
            })
        });
    }

    const handleToggleFavourite = () => {
        withToast(async () => {
            await toggleFavouriteTask.run({ playlistIds: [playlist.id], favourite: !playlist.isFavourited }).then(() => {
                afterToggleFavourite?.(playlist.id, !playlist.isFavourited);
            })
        }, { successToastMessage: `Playlist ${playlist.isFavourited ? 'un-favourited' : 'favourited'} successfully` });
    }

    const handleToggleFeatured = () => {
        withToast(async () => {
            await toggleFeaturedTask.run({ data: { id: playlist.id, isFeatured: !playlist.isFeatured } }).then(() => {
                afterToggleFeatured?.(playlist.id, !playlist.isFeatured);
            })
        }, { successToastMessage: `Playlist ${playlist.isFeatured ? 'un-featured' : 'marked as featured'} successfully` });
    }

    return (
        <EntityCard
            onClick={onClick}
            title={playlist.name}
            initialCheckedState={initialCheckedState}
            // description={playList.description}
            img={playlist.image}
            titleTopTextNode={category ? <Typography gutterBottom className={classes.category} variant={'subtitle1'} >{category.name}</Typography> : undefined}
            actionsProps={{
                config: [
                    {
                        action: playlist.isFavourited ? 'favourited' : 'favourite',
                        isIconButton: true,
                        onClick: handleToggleFavourite,
                        disabled: (!playlist.isPublished || toggleFavouriteTask.status === 'PROCESSING'),
                        iconProps: { color: playlist.isFavourited ? 'primary' : undefined },
                        hidden: true
                    },
                    {
                        action: playlist.isFeatured ? 'featured' : 'un-featured',
                        label: playlist.isFeatured ? "Featured" : "Un-featured",
                        noIcon: true,
                        onClick: handleToggleFeatured,
                        disabled: toggleFeaturedTask.status === 'PROCESSING',
                        hidden: !RoleUtils.isAdmin(user)
                    },
                    { action: 'view', label: 'View', noIcon: true, onClick: () => history.push(`/dashboard/playlists/${playlist.id}`) },
                    { action: 'edit', label: 'Edit', noIcon: true, onClick: () => history.push(`/dashboard/playlists/update/${playlist.id}`) },
                    { action: 'delete', label: 'Delete', noIcon: true, onClick: handleDelete },
                    {
                        action: 'status',
                        label: playlist.isPublished ? "Published" : 'Unpublished',
                        noIcon: true,
                        onClick: handleTogglePublish,
                        disabled: togglePublishTask.status === 'PROCESSING',
                        typographyProps: { color: playlist.isPublished ? 'secondary' : 'error' }
                    }
                ],
                align: undefined
            }} />
    )
}
const useStyles = makeStyles<Theme, Pick<Playlist, 'category'>>((theme: Theme) => createStyles(
    {
        category: {
            textTransform: 'uppercase',
            color: ({ category }) => category?.color
        }
    }
))


export default PlayListCard