import { Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import useAsyncTask from 'Hooks/useAsyncTask';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'Stores';
import LoginForm from './LoginForm';
import { usePermission } from './usePermission';

export interface LoginProps {
    width?: number
    styles?: CSSProperties
    transparent?: boolean
}

export const Login: FC<LoginProps> = (props) => {
    const classes = useStyles();
    const [error, setError] = useState<string>('');

    const { isPermitted } = usePermission(['ADMIN', 'COMMUNITY', 'CURATOR']);

    let styles = { ...props.styles, width: props.width };
    if (props.transparent)
        styles = { ...styles, backgroundColor: 'transparent' };

    const login = useStoreActions(actions => actions.Auth.login);

    const loginTask = useAsyncTask(login);

    useEffect(() => {
        setError((loginTask.status === 'ERROR') ? "Please check username or password and try again." : '');
    }, [loginTask.status, loginTask.message]);

    useEffect(() => {
        if (!isPermitted && loginTask.status === 'SUCCESS') {
            setError('Permission is allowed only for Admin, Curator and Community.');
        }
    }, [isPermitted, loginTask.status]);

    return (
        <div className={clsx(classes.root)} style={styles}>
            {
                error && (
                    <Typography color="error" display="block" gutterBottom variant="body2">{error}</Typography>
                )
            }
            <LoginForm
                onSubmit={loginTask.run}
                isSubmitting={loginTask.status === 'PROCESSING'}
            />
            <Link to={'forgot-password'} >
                <Typo fontVariant="semibold" className={classes.registerLink} variant="subtitle1" weight="regular">
                    Forgot Password ?
            </Typo></Link>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        width: '100%',
        padding: 20
    },
    heading: {
        marginBottom: 20
    },
    registerLink: {
        textDecoration: 'none',
        color: theme.palette.grey["600"],
        marginTop: '16px',
        lineHeight: '22px',
        textAlign: 'center'
    },

}));


export default Login;