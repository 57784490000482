import { createStyles, makeStyles, Theme, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import PGInputLabel from '../../Features/ReactFormsInputs/PGInputLabel';
import { usePGInputStyle } from '../../Features/ReactFormsInputs/useInputStyles';


export interface SelectInputProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
	options: { label: string, value: string | number }[]
	label: string;
	errorText?: string;
	error?: boolean;
	fullWidth?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {
	const { label, options, fullWidth = false, error, errorText, ...rest } = props;
	const classes = useStyles()
	const inputClass = usePGInputStyle();
	return (
		<Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'} >
			<PGInputLabel htmlFor={`select-${label}`} >{label}</PGInputLabel>
			<Typography variant={'caption'} color={error ? 'error' : undefined} >{errorText || ''}</Typography>
			<select id={`select-${label}`} className={clsx(classes.select, inputClass.root, { [classes.fullWidth]: fullWidth })} {...rest} >
				{
					options.map((o, i) => {
						return (
							<option key={i} value={o.value} >{o.label}</option>
						)
					})
				}
			</select>
		</Box>
	)
}
const useStyles = makeStyles<Theme>((theme) => {
	return (createStyles({
		fullWidth: {
			width: '100%'
		}
	}))
})


export default SelectInput