import { Fab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import clsx from 'clsx';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import { usePGInputStyle } from 'Features/ReactFormsInputs/useInputStyles';
import { Moment } from 'moment';
import React, { FC } from 'react';
import Datetime, { DatetimepickerProps as DateTimePickerProps } from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DateTimeUtils from 'Resources/DateTimeUtils';

export interface TimeInputProps extends DateTimePickerProps {
    label?: string;
    offset?: string;
    format?: string;
}

const TimeInput: FC<TimeInputProps> = (props) => {
    const classes = useStyles();
    const inputClass = usePGInputStyle();
    const { label, offset, value, onChange, format = 'HH:mm', ...rest } = props;

    const handleChange = (value: string | Moment) => {
        props.onChange?.(DateTimeUtils.setTimeWithoutOffset(value as string, offset, format))
    }

    return (
        <>
            {label ? <PGInputLabel>{label}</PGInputLabel> : null}
            <div className={classes.root} >
                <Datetime
                    dateFormat={false}
                    timeFormat={'HH:mm'}
                    className={classes.container}
                    inputProps={{
                        className: clsx(classes.input, inputClass.root),
                    }}
                    utc
                    value={DateTimeUtils.getTimeWithOffset(value as string, offset, format)}
                    onChange={handleChange}
                    input
                    {...rest}
                />
                <Fab size={'small'} >
                    <ScheduleRoundedIcon fontSize={'small'} />
                </Fab>
            </div>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex',
            width: '100%',
            '& button': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            }
        },
        container: {
            flex: 1
        },
        input: {
            width: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    }))
})

export default TimeInput