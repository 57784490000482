import React, { FC } from 'react'
import { Box, Avatar, Typography, Button } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles';
import { TEvent } from 'Models/Event/@types';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import Location from '@material-ui/icons/LocationOnOutlined';
import Clock from '@material-ui/icons/QueryBuilderOutlined';
import moment from 'moment'
import Ticket from '@material-ui/icons/ConfirmationNumberOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
export interface EventPreviewBoxProps {
    event: TEvent
}

const EventPreviewBox: FC<EventPreviewBoxProps> = (props) => {
    const classes = useStyles();
    const { event } = props;
    console.log(event);
    let address = '';
    if (event.address?.fullAddress)
        address = address + event.address.fullAddress + '\n'
    if (event.ticketUrl)
        address = address + event.ticketUrl
    const config: { text?: string, icon?: JSX.Element }[] = [
        {
            text: event.eventDates.map(i => moment(i.startDate).format('YYYY-MM-DD')).join('\n'), //event.eventDates.length ? moment(event.eventDates[0].startDate).format('YYYY-MM-DD') : '',
            icon: <DateRangeIcon className={classes.icon} fontSize='large' />
        },
        {
            icon: <Location className={classes.icon} fontSize='large' />,
            text: address
        },
        {
            icon: <Clock className={classes.icon} fontSize='large' />,
            text: event.eventDates.map(i => moment(i.startDate).format('hh-mm-ss')).join('\n')
        },
        {
            icon: <Ticket className={classes.icon} fontSize='large' />,
            text: event.priceCategory
        }
    ]
    const bottomConfig: { text?: string, icon?: JSX.Element }[] = [{
        text: 'FOR YOU',
        icon: <HomeOutlinedIcon fontSize='small' className={classes.iconSmall} />
    }, {
        text: 'SEARCH',
        icon: <SearchOutlinedIcon fontSize='small' className={classes.iconSmall} />
    }, {
        text: 'SAVED',
        icon: <FavoriteBorderOutlinedIcon fontSize='small' className={classes.iconSmall} />
    }]
    const category = event.primaryEventCategory?.value;
    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                <Box className={classes.container}>
                    <Box position='relative'>
                        <Avatar src={event.primaryImage.url} className={classes.image} />
                        {category ? <Box className={classes.eventType}><Typography variant='h4' color='secondary'>{category}</Typography></Box> : null}
                    </Box>
                    <Box px={1} pt={2}>
                        <Typography variant='body1'>{event.externalUrl}</Typography>
                        <Typography variant='h5'>{event.name}</Typography>
                        <Typography variant='body1'>{event.description.slice(0, 200)}</Typography>
                        {/* <ReadMore text={event.description} variant='body1' /> */}
                        {
                            config.map((it, i) => {
                                if (!it.text)
                                    return <Box key={i} />
                                return (<Box key={i} className={classes.eventItems} >
                                    {it.icon}
                                    <Typography style={{ marginLeft: 16 }} variant='body1'>{it.text}</Typography>
                                </Box>)
                            })
                        }
                        <Button fullWidth variant='contained' className={classes.button}>
                            <Typography variant='h6'>Buy Tickets</Typography>
                        </Button>
                    </Box>

                </Box>
                <Box className={classes.bottomPanel} marginTop={'4px'}>
                    {
                        bottomConfig.map((iit, i) => {
                            return (
                                <Box key={i} display='flex' flexDirection='column' alignItems='center'>
                                    {iit.icon}
                                    <Typography className={classes.text} variant='caption'>{iit.text}</Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey } } = theme;
    return (createStyles({
        root: {
            width: 350,
            height: 700,
            margin: '30px auto',
            backgroundColor: '#ddd',
            borderRadius: 40,
            padding: '55px 8px',
        },
        wrapper: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        container: {
            width: '100%',
            backgroundColor: '#fff',
            flex: 1,
            overflowY: 'scroll'
        },
        bottomPanel: {
            height: 60,
            width: '100%',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
        },
        image: {
            borderRadius: 0,
            width: '100%',
            height: 'auto'
        },
        eventType: {
            padding: '4px 24px',
            backgroundColor: '#fff',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        eventItems: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '16px 24px',
            borderBottom: `1px Solid ${grey['500']}`,
            overflow: 'hidden',
            wordBreak: 'break-all'
        },
        icon: {
            color: grey['600'],
            fontSize: 45
        },
        iconSmall: {
            color: grey['600'],
        },
        button: {
            backgroundColor: '#e9fe52',
            margin: '16px 0px',
        },
        text: {
            color: grey['600']
        }
    }))
})

export default EventPreviewBox