import { TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import { usePGInputStyle } from 'Features/ReactFormsInputs/useInputStyles';
import React, { FC } from 'react';

export interface TextAreaProps extends TextareaAutosizeProps {
    label: string;
    fullWidth?: boolean;
}

const TextArea: FC<TextAreaProps> = (props) => {
    const classes = useStyles();
    const inputClasses = usePGInputStyle();
    const { label, fullWidth = false, ...rest } = props;
    return (
        <>
            {
                label ?
                    <PGInputLabel>{label}</PGInputLabel>
                    :
                    null
            }
            <TextareaAutosize
                className={clsx(inputClasses.root, { [classes.fullWidth]: fullWidth })}
                {...rest}
            />
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        fullWidth: {
            width: '100%'
        }
    }))
})

export default TextArea