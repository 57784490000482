import { Role } from "Models/User/@types";
import { useStoreState } from "Stores";

export const usePermission = (roles: Role[] = []) => {

    const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

    const isPermitted = (): boolean => checkPermit(roles);

    const checkPermit = (roles: Role[]): boolean => {
        let access = false;

        for (let role of roles) {
            if (user?.roles.includes(role)) {
                access = true;
                break;
            }
        }
        return access;
    }

    return {
        isPermitted: isPermitted(),
        checkPermit
    }

}