import { createMuiTheme, fade } from "@material-ui/core";

const Font1Regular = 'NeuePlak-Regular';
const Font1SemiBold = 'NeuePlak-ExtendedSemibold';
const Font1Bold = 'NeuePlak-ExtendedBold';
const Font1Black = 'NeuePlak-ExtendedBlack';

export const Fonts = { Font1SemiBold, Font1Regular, Font1Bold, Font1Black }

const MuiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#675df7',
            light: fade('#675df7', 0.3),
            contrastText: "#fff",
        },
        secondary: {
            main: '#25dbb2',
            light: '#25dbb2',
            contrastText: "#fff"
        },
        grey: {
            "100": '#2A2A2A',
            "200": "#6B738E",
            "300": "#AEB4C5",
            "400": "#D0D4E1",
            "500": "#ededed",
            "600": "#797979",
            "700": "#4c5667",
            "800": "#36404a",
            "900": "#4a4a4a"
        },
        text: {
            primary: "#000000",
            secondary: "#808285",
        },
        background: {
            default: "#ebeff2",
            paper: "#fff"
        },
        common: {
            black: '#000',
            white: '#fff'
        }
    },
    typography: {
        fontFamily: [Font1SemiBold, Font1Regular, Font1Bold, Font1Black].join(', ') + 'Helvetica, Arial, sans-serif',
        h1: {
            fontSize: 36
        },
        h2: {
            fontSize: 32
        },
        h3: {
            fontSize: 28
        },
        h4: {
            fontSize: 24,
            fontWeight: 'bold'
        },
        h5: {
            fontSize: 22,
            fontWeight: 500,
            fontFamily: Font1SemiBold
        },
        h6: {
            fontSize: 18
        },
        subtitle1: {
            fontSize: 16,
        },
        subtitle2: {
            fontSize: 14,
        },
        body1: {
            fontSize: 16,
            fontFamily: Font1Regular
        },
        body2: {
            fontSize: 14,
            fontFamily: Font1Regular
        },
        button: {
            fontSize: 18,
            fontFamily: Font1Bold,
            textTransform: 'unset',
        },
        caption: {
            fontSize: 13,
            fontFamily: Font1Bold,
            fontWeight: 700
        },
        overline: {
            fontSize: 13,
            textTransform: 'unset',
            lineHeight: 1.33,
            fontFamily: Font1Regular
        },
        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'unset',
                height: 42,
            },
            outlined: {
                borderWidth: '2px !important',
                textTransform: 'unset',
            },
            outlinedSecondary: {
                borderWidth: '2px !important',
                textTransform: 'unset',
            },
            contained: {
                primary: {
                    transition: 'none !important',
                    textTransform: 'unset',
                },
                boxShadow: 'none',
                transition: 'none !important',
                textTransform: 'unset',
            },
            containedPrimary: {
                transition: 'none !important',
            }
        },
        MuiFab: {
            root: {
                height: 46,
                width: 46,
                boxShadow: '0px 1px 3px #00000029;'
            },
            colorInherit: {
                backgroundColor: '#fff',
                color: 'black'
            },
            sizeSmall: {
                height: 34,
                width: 34,
                borderRadius: 4,
                backgroundColor: '#4c5667',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#4c5667',
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                display: 'block',
                width: '100%'
            }
        },
        MuiFormHelperText: {
            root: {
                marginLeft: 0,
                marginRight: 0,
                color: '#505050',
                fontSize: 13,
                marginTop: 8,
                fontWeight: 400,
                fontFamily: 'NeuePlak-ExtendedSemiBold'
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
                color: '#505050',
                fontSize: 13,
                marginTop: 8,
                fontWeight: 400,
                fontFamily: 'NeuePlak-ExtendedSemiBold'
            },
        }
    }
});

export default MuiTheme;