import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import ForgotPassword from 'Features/Authentication/ForgotPassword';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../../Features/Authentication/Login';
import ResetPassword from 'Features/Authentication/ResetPassword';


const Landing: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.Container}>
                <div className={classes.logoContainer}>
                    <img src="https://playground-api.mithyalabs.com/static/assets/images/logo_p.png" alt="logo" />
                </div>
                <div className={classes.loginContainer}>
                    <Switch>
                        <Route exact path={'/'} component={Login} />
                        <Route path='/forgot-password' exact component={ForgotPassword} />
                        <Route path='/reset-password' exact component={ResetPassword} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}


const useStyles = makeStyles<Theme>((theme) => createStyles(
    {
        root: {
            position: 'fixed',
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.background["default"],
            height: '100%',
            overflow: 'auto',
            '& a': {
                textDecoration: 'none',
            }
        },
        Container: {
            width: 420,
            border: '1px solid rgba(54, 64, 74, 0.1)',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 71.8,
            padding: 20,
            backgroundColor: theme.palette.background["paper"],
        },
        logoContainer: {
            padding: '0px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loginContainer: {
            padding: 15,
            width: '100%'
        }
    })
)

export default Landing;