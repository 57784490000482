import { Action, Thunk, thunk } from "easy-peasy";
import AppModel from "Models/App";
import { AppConstants, Summary } from "Models/App/@types";
import AxiosUtils from "Resources/AxiosUtils";
import { TRootStore } from "Stores";

type ThunkAction<T = void, R = any> = Thunk<TAppModelState, T, any, TRootStore, R>;
type StaticAction<T = void> = Action<TAppModelState, T>;


export interface TAppModelState {
    appConstants?: AppConstants;
    fetchAppConstants: ThunkAction;
    getAppSummary: ThunkAction<void, Promise<Summary>>;
    summary?: Summary;
}

const AppModelState: TAppModelState = {
    fetchAppConstants: thunk(async (actions, args, { getState }) => {
        const appConstants = await AppModel.fetchAppConstants().catch(AxiosUtils.throwError);
        const state = getState();
        state.appConstants = appConstants;
    }),

    getAppSummary: thunk(async (actions, args, { getState }) => {
        const state = getState();
        const summary = await AppModel.fetchSummary().catch(AxiosUtils.throwError);
        state.summary = summary;
        return summary
    })

}

export default AppModelState