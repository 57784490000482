import { useStoreState, useStoreActions } from "Stores"
import useAsyncTask from "Hooks/useAsyncTask";
import { useEffect } from "react";

export const usePlaylistDetails = (playlistId?: string) => {
    const { playlistDetails } = useStoreState(({ PlayList: { playlistDetails } }) => ({ playlistDetails }));
    const { fetchSinglePlaylist } = useStoreActions(({ PlayList: { fetchSinglePlaylist } }) => ({ fetchSinglePlaylist }));

    const fetchTask = useAsyncTask(fetchSinglePlaylist);

    useEffect(() => {
        if (playlistDetails && playlistDetails.id === playlistId) return;
        if (playlistId) fetchTask.run({ id: playlistId, params: { filter: { include: [{ relation: 'events', scope: { order: 'eventDates.startDate ASC' } }, 'user'] } } });
        // eslint-disable-next-line
    }, [playlistId]);

    return {
        playlistDetails,
        loadingPlaylist: fetchTask.status === 'PROCESSING'
    }

}