import SelectInput, { SelectInputProps } from 'Components/Inputs/SelectInput';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import utilities from 'Resources/Utils';

export interface PGSelectInputProps extends IFieldProps {
    fieldProps: SelectInputProps;
}

const PGSelectInput: FC<PGSelectInputProps> = (props) => {

    const { fieldProps, formikProps = {} as FormikProps<any>, fieldConfig } = props;
    const fieldError = utilities.getFieldError((fieldProps.name || ''), formikProps);
    const updatedProps: SelectInputProps = {
        ...fieldProps,
        error: !!fieldError,
        errorText: fieldError || fieldProps.errorText || '',
        onChange: formikProps.handleChange,
        value: formikProps.values?.[fieldConfig?.valueKey || ''] || ''
    }
    return (
        <SelectInput {...updatedProps} />
    )
}


export default PGSelectInput