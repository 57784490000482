import React, { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Fonts } from 'MuiTheme';

export type FormActionButton = 'preview' | 'saveDraft' | 'cancel' | 'publish' | 'unpublish' | 'create' | 'update' | 'download';
export interface FormActionButtonsProps {
	excludedButtons?: FormActionButton[];
	onClick?: (action: FormActionButton) => void;
	loading?: boolean;
}

const BUTTON_CONFIG: { label: string; button: FormActionButton }[] = [
	{
		label: 'Preview',
		button: 'preview',
	},
	{
		label: 'Save',
		button: 'saveDraft',
	},
	{
		label: 'Cancel',
		button: 'cancel',
	},
	{
		label: 'Publish',
		button: 'publish',
	},
	{
		label: 'Unpublish',
		button: 'unpublish',
	},
	{
		label: 'Create',
		button: 'create',
	},
	{
		label: 'Update',
		button: 'update',
	},
	{
		label: 'Download RSVPs',
		button: 'download',
	},
];

const FormActionButtons: FC<FormActionButtonsProps> = (props) => {
	const classes = useStyles();
	const { excludedButtons = [], onClick, loading = false } = props;
	return (
		<Box className={classes.root}>
			{BUTTON_CONFIG.map((b) => {
				if (excludedButtons.includes(b.button)) return null;
				const isSolidButton = b.button === 'publish' || b.button === 'create' || b.button === 'update' || b.button === 'unpublish';
				return (
					<Button
						disabled={loading}
						key={b.button}
						className={classes.button}
						size={'large'}
						variant={isSolidButton ? 'contained' : 'outlined'}
						color={'secondary'}
						onClick={(e) => onClick?.(b.button)}
					>
						{b.label}
					</Button>
				);
			})}
		</Box>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	const {
		typography: { h6 },
	} = theme;
	return createStyles({
		root: {
			display: 'flex',
		},
		button: {
			marginRight: 10,
			...h6,
			fontFamily: Fonts.Font1Bold,
		},
	});
});

export default FormActionButtons;
