import AxiosUtils, { request } from "Resources/AxiosUtils";
import { TToken } from "Models/Auth/@types";
import type { TFilter, TParams } from "Models/App/@types";
import type { User, Role } from "./@types";
import { SearchResponse } from "Typings/Global";
import { parseUser, parseUserFormData } from "./userParser";

class UserModel {

    static register() {
        throw new Error("Method not implemented.");
    }

    static search = async (input: string, filter?: any): Promise<SearchResponse<User>> => {
        if (!filter) filter = {};
        filter = { user: { include: 'roles' }, ...filter };
        const users = await request<SearchResponse<User>>({
            url: '/users/search',
            params: {
                term: input,
                filter
            }
        }).catch(AxiosUtils.throwError);
        return { ...users, results: users.results.map((u => ({ ...u, hit: parseUser(u.hit) }))) };
    }

    static login = async (credentials: { email: string, password: string }) => {
        const data = await request<any>({
            url: '/users/login',
            method: "post",
            data: { ...credentials, email: credentials.email.toLowerCase() }
        });
        const Token: TToken = {
            accessToken: data.id,
            userId: data.userId,
            ttl: data.ttl,
            created: data.created
        };

        return Token;
    }

    static logOut = async () => {
        let resp;
        try {
            resp = await request({
                url: '/users/logout',
                method: "post"
            });
        } catch (error) {
            throw error
        }
        return resp;
    }

    static getSingleUser = async (id: string, params?: TParams) => {
        const res = await request({
            url: `users/${id}`,
            params
        }).catch(AxiosUtils.throwError);
        return res;
    }

    static fetchMe = async (filter?: TFilter) => {
        const user = await request<User>({
            url: '/users/me',
            params: {
                filter: {
                    include: 'roles'
                }
            }
        });
        return parseUser(user);
    }

    static deleteUsers = async (args: { ids: string[] }) => {
        await request({
            url: 'users/trash',
            method: 'DELETE',
            params: args
        }).catch(AxiosUtils.throwError);
        return args;
    }

    static changeRole = (ids: string[], role: Role, add: boolean = true) => request({ url: `users/changeRoles`, method: 'patch', params: { role, userIds: ids, add } })

    static postUser = async (data: Partial<User>) => {
        if (data.id) {
            return await UserModel.updateUser({ ...data, id: data.id });
        }
        const res = await request({
            url: `/users`,
            method: 'post',
            data: { ...parseUserFormData(data) }
        }).catch(AxiosUtils.throwError);
        return { ...data, ...parseUser(res) } as User;
    }

    static updateUser = async (data: Partial<User> & Pick<User, 'id'>) => {
        const res = await request({
            url: `/users/${data.id}`,
            method: 'patch',
            data: { ...parseUserFormData(data) }
        }).catch(AxiosUtils.throwError);
        return { ...data, ...parseUser(res) } as User;
    }

    static updateUsersRole = (users: User[], ids: string[], role: Role, action: 'add' | 'remove') => {
        return [...(users || [])].map(u => ids.includes(u.id) ? ({ ...u, roles: action === 'add' ? (u.roles.includes(role) ? u.roles : u.roles.concat(role)) : (u.roles.includes(role) ? u.roles.filter(r => r !== role) : u.roles) }) : u) as User[];
    }

    static updateBlockStatus = (id: string, blockStatus: boolean) => request({ url: `users/${id}/update-block-status`, data: { block: blockStatus }, method: 'post' });

    static getFollowers = (id: string, params?: TParams) => request<User[]>({ url: `users/${id}/followers`, params })

}

export const getName = ({ firstName, fullName, lastName, name, username }: { firstName?: string, lastName?: string, name?: string, fullName?: string, username?: string }) => {
    return name || fullName || `${firstName || ''} ${lastName || ''}` || username || '';
}

export default UserModel;