import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { FormikValues } from 'formik';
import _ from 'lodash';

axios.defaults.headers.post['Content-Type'] = 'application/json';
// const IMAGE_KIT_URL = 'https://mithya.imagekit.io/stayuncle/';
const cookies = localStorage;

let utilities = {

    toggleItemFromList: (list: any[] = [], item: any, key: string = 'id', comparisonFunction?: (currentItem: any, item: any) => boolean) => {
        let updatedList: any[] = [...list];
        let index = list.findIndex(i => comparisonFunction ? comparisonFunction(i, item) : i[key] === item[key]);
        index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
        return updatedList;
    },

    updateItemList: <T extends Record<string, any>>(list: Array<T>, item: T, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT', key: keyof T = 'id'): typeof list => {
        list = list || [];
        let newList = list.slice();
        let itemIndex;
        if (action === 'UPDATE') {
            itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
            if (itemIndex !== -1)
                newList.splice(itemIndex, 1, item);
            return newList;
        } else if (action === 'ADD') {
            newList.unshift(item);
            return newList;
        } else if (action === 'DELETE') {
            return newList.filter(listItem => item[key] !== listItem[key]);
        }
        else if (action === 'PUT') {
            itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
            if (itemIndex !== -1)
                newList = newList.map((oldItem) => item.id === oldItem.id ? ({ ...oldItem, ...item }) : oldItem);
            // newList.splice(itemIndex, 1, { ...list[itemIndex], ...item });
            else {
                newList.push(item);
            }
            return newList;
        }
        return newList;
    },

    getFieldError: (fieldName: string, formikProps: FormikValues) => {
        const fieldError = _.get(formikProps.errors, fieldName);
        const isTouched = _.get(formikProps.touched, fieldName);
        if (!isTouched && formikProps.submitCount < 1)
            return '';
        return fieldError;
    },



    saveUser: (accessToken: string = '', userId: string = '') => {
        cookies.set('access_token', accessToken, { path: '/' });
        cookies.set('userId', userId, { path: '/' });
    },
    clearCookies: () => {
        cookies.remove('access_token');
        cookies.remove('userId');
    },

    isAuthenticated: () => {
        return Boolean(cookies.get('access_token'));
    },

    getUserId: () => {
        return cookies.get('userId');
    },
    getAccessToken: () => {
        return cookies.get('access_token');
    },

    request: async<T>(config: AxiosRequestConfig, log = true): Promise<T> => {
        if (!axios.defaults.baseURL) {
            throw new Error('Error: Base Url is not provided');
        }
        const resp = await axios.request<T>(config);
        return resp.data;
    },

    jsonParser: (data: any, keys: Array<string>) => {
        let updatedObject: any
        if (Array.isArray(data)) {
            updatedObject = [...data];
            updatedObject.forEach((objItem: any) => {
                for (let i = 0; i < keys.length; i++) {
                    try {
                        if (typeof objItem[keys[i]] === 'string') objItem[keys[i]] = JSON.parse(objItem[keys[i]])
                        else continue;
                    } catch (e) { objItem[keys[i]] = {} }
                }
            });
        }
        else {
            updatedObject = { ...data };
            for (let i = 0; i < keys.length; i++) {
                try {
                    if (typeof data[keys[i]] === 'string') updatedObject[keys[i]] = JSON.parse(data[keys[i]]);
                    else continue;
                } catch (e) { updatedObject[keys[i]] = {} }
            }
        }
        return updatedObject;
    },

    validateEmail: (email: string) => {
        // eslint-disable-next-line
        let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email)
            return false;

        if (email.length > 254)
            return false;

        var valid = tester.test(email);
        if (!valid)
            return false;

        // Further checking of some things regex can't handle
        var parts = email.split("@");
        if (parts[0].length > 64)
            return false;

        var domainParts = parts[1].split(".");
        if (domainParts.some(function (part) { return part.length > 63; }))
            return false;

        return true
    },

    validateUrl: (uri: string): boolean => {
        // eslint-disable-next-line
        let tester = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        if (!uri) return false;
        return tester.test(uri);
    },

    sequentialPromises: <T = any>(items: T[], asyncFunc: (item: T) => any, onEachItem: any) => {
        if (!Array.isArray(items) || typeof asyncFunc !== 'function')
            return Promise.reject('No item or method provided');
        return items.reduce((previous, current) => (
            previous.then((accumulator: any) => (
                asyncFunc(current).then((result: any) => {
                    if (typeof onEachItem === 'function')
                        onEachItem(current, result);
                    return accumulator.concat(result);
                })
            ))
        ), Promise.resolve([]));
    },

    bytesToSize: (bytes: number) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    },
    getDateInISO(date: string) {
        return new Date(date).toISOString();
    },
    getTimeWithOffset(time: string, eventOffset: string = "UTC +00:00") {
        //const offset = Number(eventOffset.split(' ')[1].replace(':', "."));
        const offset = moment().utcOffset(eventOffset.split(' ')[1]).utcOffset();
        const tempTime = moment(time, 'HH:mm');
        return tempTime.add(offset, 'm').format('HH:mm');
    },
    setTimeWithoutOffset(time: string, eventOffset: string = "UTC +00:00") {

        const offset = moment().utcOffset(eventOffset.split(' ')[1]).utcOffset();
        let newTime = moment(time, 'HH:mm');
        return newTime.subtract(offset, 'm').format("HH:mm");
    },

    getTotalCountString(total: number = 0): string {
        if (total > 1000) {
            return `${(total / 1000).toFixed(1)}k`
        }
        else return String(total)
    }

}



export default utilities;