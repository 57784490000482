import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Loader from 'Components/Loader';
import { usePlaylistDetails } from 'Features/PlayList/usePlaylistDetails';
import { getName } from 'Models/User';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Typography, Box } from '@material-ui/core';
import EventCard from 'Features/Event/EventCard';

export interface PlaylistDetailsProps extends RouteComponentProps<{ id: string }> { }

const PlaylistDetails: FC<PlaylistDetailsProps> = ({ match }) => {
    const classes = useStyles();

    const { loadingPlaylist, playlistDetails } = usePlaylistDetails(match.params.id);

    return (
        (loadingPlaylist || !playlistDetails) ? <Loader /> :
            (
                <div className={classes.root} >
                    <div className={classes.pictureContainer} >
                        {playlistDetails.image ? <img src={playlistDetails.image.url} alt={playlistDetails.name} /> : null}
                        <div className={classes.curatorImage} >
                            {playlistDetails.user?.profileImages?.[0]?.url ? <img src={playlistDetails.user?.profileImages?.[0]?.url} alt={getName(playlistDetails.user)} /> : null}
                        </div>
                    </div>
                    <Typography color={'textSecondary'} variant={'h4'} >{playlistDetails.name}</Typography>
                    <Typography>{playlistDetails.shortDescription}</Typography>
                    <Box mt={6} >
                        {
                            playlistDetails.events?.map((e) => {
                                return (
                                    <EventCard event={e} key={e.id} />
                                )
                            })
                        }
                    </Box>
                </div>
            )
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { background } } = theme;
    return (createStyles({
        root: {
            marginTop: 36
        },
        pictureContainer: {
            height: 350,
            width: '100%',
            position: 'relative',
            marginBottom: 8,
            '& > img': {
                width: '100%',
                height: 350,
                objectFit: 'cover'
            }
        },
        curatorImage: {
            width: 150,
            height: 150,
            borderRadius: '100%',
            '& > img': {
                width: 150,
                height: 150,
                borderRadius: '100%',
                objectFit: 'cover',
            },
            border: '2px solid #eab907',
            position: 'absolute',
            bottom: '-75px',
            right: '10%',
            backgroundColor: background['default']
        }
    }))
})

export default PlaylistDetails