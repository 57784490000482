import { InputLabel, InputLabelProps, Typography } from '@material-ui/core';
import React, { FC } from 'react';

export interface PGInputLabelProps extends InputLabelProps {

}

const PGInputLabel: FC<PGInputLabelProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <InputLabel {...rest} style={{ marginBottom: 8 }} >
            <Typography variant={'caption'} color={'textPrimary'} >
                {children}
            </Typography>
        </InputLabel>
    )
}

export default PGInputLabel