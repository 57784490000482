import { Button, IconButton, SvgIconProps, Typography, TypographyProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import LaunchIcon from '@material-ui/icons/Launch'; // placeholder for status
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import React, { FC } from 'react';

export type Action =
	| 'filter'
	| 'add'
	| 'favourite'
	| 'favourited'
	| 'delete'
	| 'edit'
	| 'status'
	| 'view'
	| 'curator'
	| 'community'
	| 'featured'
	| 'un-featured'
	| 'rsvp'
	| 'block'
	| 'un-block'
	| 'duplicate'
	| 'send-message'
	| 'send-to-all'
	| 'download';

export interface DashboardActionButtonsListProps {
	config: {
		action?: Action;
		onClick?: () => void;
		label?: string;
		noIcon?: boolean;
		isIconButton?: boolean;
		iconProps?: SvgIconProps;
		disabled?: boolean;
		typographyProps?: TypographyProps;
		hidden?: boolean;
	}[];
	align?: 'ltr' | 'rtl';
}

const DashboardActionButtonsList: FC<DashboardActionButtonsListProps> = (props) => {
	const { config = [], align } = props;
	const classes = useStyles({ align });
	return (
		<div className={classes.root}>
			{config.map((item, i) => {
				const Icon = getIcon(item.action);
				const label = item.label || getLabel(item.action);
				if (item.hidden) return null;
				return (
					<div key={item.action}>
						{item.isIconButton && Icon ? (
							<IconButton disabled={item.disabled} size={'small'} onClick={item.onClick}>
								<Icon {...(item.iconProps || {})} fontSize={'small'} />
							</IconButton>
						) : (
							<Button
								disableRipple
								disabled={item.disabled}
								startIcon={!item.noIcon && Icon && <Icon />}
								size={'small'}
								onClick={item.onClick}
							>
								<Typography {...(item.typographyProps || {})} variant={'caption'}>
									{label}
								</Typography>
							</Button>
						)}
					</div>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles<Theme, Pick<DashboardActionButtonsListProps, 'align'>>((theme) => {
	return createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			'& > button': {
				marginRight: ({ align }) => (align === 'ltr' ? 24 : 0),
				marginLeft: ({ align }) => (align === 'rtl' ? 24 : 0),
			},
		},
	});
});

const getIcon = (action?: Action) => {
	if (!action) return;
	switch (action) {
		case 'add':
			return AddCircleRoundedIcon;
		case 'delete':
			return DeleteOutlineRoundedIcon;
		case 'favourite':
			return FavoriteBorderRoundedIcon;
		case 'favourited':
			return FavoriteRoundedIcon;
		case 'filter':
			return FilterListRoundedIcon;
		case 'edit':
			return CreateIcon;
		case 'status':
			return LaunchIcon;
		case 'view':
			return LaunchIcon;
		case 'curator':
			return PersonAddRoundedIcon;
		case 'community':
			return SupervisorAccountRoundedIcon;
		case 'featured':
			return StarBorderRoundedIcon;
		case 'un-featured':
			return StarRoundedIcon;
	}
};

const getLabel = (action?: Action) => {
	if (!action) return;
	switch (action) {
		case 'add':
			return 'Add';
		case 'delete':
			return 'Delete';
		case 'favourite':
			return 'Favourite';
		case 'filter':
			return 'Filter';
		case 'edit':
			return 'Edit';
		case 'view':
			return 'View';
	}
};

export default DashboardActionButtonsList;
