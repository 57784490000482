import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import { TextInputProps } from 'Components/Inputs/TextInput';
import EventSuggestInput from 'Features/Event/EventSuggestInput';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import { FormikProps } from 'formik';
import { TEvent } from 'Models/Event/@types';
import { Playlist } from 'Models/Playlist/@types';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';

export interface PlaylistEventAutocompleteProps extends IFieldProps {
    fieldProps?: TextInputProps
}

const PlaylistEventAutocomplete: FC<PlaylistEventAutocompleteProps> = (props) => {

    const { formikProps = {} as FormikProps<Partial<Playlist>>, fieldProps = {} } = props;

    const helperText = fieldProps.helperText || '';

    const selectedEvents: TEvent[] = formikProps.values['events'] || [];

    const handleClick = (event: TEvent) => {

        let eventIds = [...(formikProps.values.eventIds || [])];
        let events = [...(formikProps.values.events || [])];

        if (eventIds.includes(event.id)) return;

        eventIds = eventIds.concat(event.id);
        events = events.concat(event);

        formikProps.setFieldValue('eventIds', eventIds);
        formikProps.setFieldValue('events', events);
    }

    const handleDelete = (event: TEvent) => {
        let eventIds = [...(formikProps.values.eventIds || [])];
        let events = [...(formikProps.values.events || [])];

        if (!eventIds.includes(event.id)) return;

        eventIds = eventIds.filter(eId => eId !== event.id);
        events = events.filter(e => e.id !== event.id);

        formikProps.setFieldValue('eventIds', eventIds);
        formikProps.setFieldValue('events', events);
    }


    return (
        <Box>
            <PGInputLabel>{'Search Events'}</PGInputLabel>

            <EventSuggestInput
                label={'Search Events'}
                onResultClick={handleClick}
            />
            {helperText ? <Typography variant={'caption'} >{helperText}</Typography> : null}
            <Box display={'flex'} flexWrap={'wrap'} >
                {
                    selectedEvents.length
                        ?
                        selectedEvents.map(e => {
                            return (
                                <Box mt={1.5} mr={1.5} key={e.id} >
                                    <Chip
                                        avatar={<Avatar src={e.primaryImage?.url} />}
                                        label={e.name || ''}
                                        onDelete={() => handleDelete(e)}
                                    />
                                </Box>
                            )
                        })
                        : null
                }
            </Box>

        </Box>
    )
}

export default PlaylistEventAutocomplete