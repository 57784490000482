import { FormSectionProps } from 'Components/Forms/FormSection';
import { SelectInputProps } from 'Components/Inputs/SelectInput';
import { TextInputProps } from 'Components/Inputs/TextInput';
import DateTimePickerGroup, { DateTimePickerGroupProps } from 'Features/ReactFormsInputs/DateTimePickerGroup';
import PGDateInput from 'Features/ReactFormsInputs/PGDateInput';
import PGDropZoneInput from 'Features/ReactFormsInputs/PGDropZoneInput';
import PGMainImageInput from 'Features/ReactFormsInputs/PGMainImageInput';
import PGSelectInput from 'Features/ReactFormsInputs/PGSelectInput';
import PGTextAreaInput from 'Features/ReactFormsInputs/PGTextAreaInput';
import PGTextInput from 'Features/ReactFormsInputs/PGTextInput';
import { AppConstants } from 'Models/App/@types';
import { EventType, TEvent } from 'Models/Event/@types';
import { Tag } from 'Models/Tags/@types';
import moment from 'moment';
import React from 'react';
import 'react-datetime/css/react-datetime.css';
import { attachField, IReactFormProps } from 'react-forms';
import { TFieldConditions } from 'react-forms/dist/lib/ml-form-builder/lib/ConditionalOperation';
import EventCuratorAutoComplete from './EventCuratorAutoComplete';
import EventCustomRecurringDateTimeInput, { EventCustomRecurringDateTimeInputProps } from './EventCustomRecurringDateTimeInput';
import EventFormTimeZoneInput from './EventFormTimeZoneInput';
import EventLocationSuggest from './EventLocationSuggest';
import RichTextEditor from 'Features/ReactFormsInputs/PGRTEInput';
import PGCheckbox from 'Features/ReactFormsInputs/PGCheckbox';
import TwitterUserSearch from 'Features/twitter/TwitterUserSearch';

const Dummy = () => <div />;

// @ts-ignore
attachField('textInput', <PGTextInput />);
// @ts-ignore
attachField('drop-file', <PGDropZoneInput />);
// @ts-ignore
attachField('file-picker', <PGMainImageInput />);
// @ts-ignore
attachField('select-field', <PGSelectInput />);
// @ts-ignore
attachField('dummy', <Dummy />);
// @ts-ignore
attachField('textarea', <PGTextAreaInput />);
// @ts-ignore
attachField('google-autocomplete', <EventLocationSuggest />);
// @ts-ignore
attachField('date-time-field', <DateTimePickerGroup />);
// @ts-ignore
attachField('custom-event-date', <EventCustomRecurringDateTimeInput />);

attachField('event-timezone-picker', <EventFormTimeZoneInput />);

attachField('rte-input', <RichTextEditor />);

// @ts-ignore
attachField('date-field', <PGDateInput />);

attachField('curator', <EventCuratorAutoComplete />);

attachField('checkbox', <PGCheckbox />);
attachField('twitterUserSearch', <TwitterUserSearch />);

const getEventFormSchema = (args: {
	appConstants: AppConstants;
	tags: Tag[];
	currentChars: number;
	offset?: string;
	tz?: string;
}): FormSectionProps<TEvent>[] => {
	return [
		{
			heading: 'Event Name',
			formId: 'eventName',
			config: getEventNameSchema(args.currentChars),
		},
		{
			heading: 'Images',
			formId: 'images',
			config: getEventImageSectionSchema(),
		},
		{
			heading: 'Details',
			formId: 'details',
			config: getEventDetailsSectionSchema(args.appConstants, args.tags),
		},
		{
			heading: 'Profile',
			formId: 'profile',
			config: getProfileConfig(),
		},
		{
			heading: 'Date & Time',
			formId: 'date-n-time',
			config: getDateConfig(args.appConstants, args.offset, args.tz),
		},
		{
			heading: 'Twitter',
			formId: 'twitter',
			config: getTwitterConfig(),
		},
	];
};

const getEventNameSchema = (currentChars: number): IReactFormProps['config'] => {
	return [
		{
			type: 'textInput',
			valueKey: 'name',
			fieldProps: {
				label: 'Event Name',
				fullWidth: true,
				helperText: currentChars > 80 ? '' : `${80 - currentChars} characters remaining`,
				charLimit: 80,
			} as TextInputProps,
		},
		{
			type: 'textInput',
			valueKey: 'subtitle',
			fieldProps: {
				label: 'Subtitle',
				placeholder: 'performance by madonna, discussion with bill clinton, fundraise for ALS...',
				fullWidth: true,
			} as TextInputProps,
		},
	];
};

const getEventImageSectionSchema = (): IReactFormProps['config'] => {
	return [
		{
			type: 'file-picker',
			valueKey: 'primaryImage',
		},
		{
			type: 'drop-file',
			valueKey: 'galleryImages',
			fieldProps: {
				label: 'Gallery Images',
			},
		},
	];
};

const PHYSICAL_EVENT_CONDITION: TFieldConditions = {
	hidden: true,
	values: [{ compareValue: 'offline' as EventType, key: 'eventType', operator: '===' }],
};
const VIRTUAL_EVENT_CONDITION: TFieldConditions = {
	hidden: true,
	values: [{ compareValue: 'offline' as EventType, key: 'eventType', operator: '!==' }],
};

const getEventDetailsSectionSchema = (appConstants: AppConstants, tags: Tag[]): IReactFormProps['config'] => {
	return [
		{
			type: 'checkbox',
			valueKey: 'isPrivate',
			fieldProps: {
				label: 'Check if the event is private',
				fullWidth: true,
			},
		},
		[
			{
				type: 'select-field',
				valueKey: 'eventType',
				fieldProps: {
					label: 'Event Type',
					options: [{ label: 'Select event type', value: '' }, ...appConstants.Event.type.map((x) => ({ label: x.name, value: x.value }))],
					fullWidth: true,
				} as SelectInputProps,
			},
		],
		[
			{
				type: 'select-field',
				valueKey: 'primaryEventCategoryId',
				fieldProps: {
					label: 'Primary Category',
					options: [{ label: 'Select category', value: '' }, ...tags.map((t) => ({ label: t.name, value: t.id }))],
					fullWidth: true,
				} as SelectInputProps,
			},
			{
				type: 'select-field',
				valueKey: 'secondaryEventCategoryId',
				fieldProps: {
					label: 'Secondary Category',
					options: [{ label: 'Select category', value: '' }, ...tags.map((t) => ({ label: t.name, value: t.id }))],
					fullWidth: true,
				} as SelectInputProps,
			},
		],
		[
			{
				type: 'textInput',
				valueKey: 'platformName',
				fieldProps: {
					label: 'Platform Hosting the Event',
					options: [
						{ label: 'Instagram', value: 'instagram' },
						{ label: 'Zoom', value: 'zoom' },
						{ label: 'Twitch', value: 'twitch' },
						{ label: 'YouTube', value: 'youtube' },
						{ label: 'Facebook', value: 'facebook' },
					].map((o) => o.label),
					helperText: 'Select a hosting platform or add one.',
				} as TextInputProps,
				condition: VIRTUAL_EVENT_CONDITION,
			},
			{
				type: 'textInput',
				valueKey: 'platformUrl',
				fieldProps: {
					label: 'Streaming link',
					placeholder: 'Should contain at least one dot(.) in the middle of the url.',
					subLabel: 'This is where the event will be streaming. users will be texted this link when you go live.',
				},
				condition: VIRTUAL_EVENT_CONDITION,
			},
		],
		{
			type: 'textInput',
			valueKey: 'rsvpUrl',
			fieldProps: {
				label: 'RSVP URL',
				subLabel:
					'Only enter this if Playground is NOT handling registration for your event. This is the link where users can register for your event.',
			},
		},
		{
			type: 'rte-input',
			valueKey: 'description',
			fieldProps: {
				label: 'Description',
				rowsMin: 4,
				fullWidth: true,
			},
		},
		{
			type: 'google-autocomplete',
			valueKey: '',
			fieldProps: {
				label: 'Search on Google',
				fullWidth: true,
			} as TextInputProps,
			condition: PHYSICAL_EVENT_CONDITION,
		},
		[
			// {
			//     type: 'textInput',
			//     valueKey: 'ticketUrl',
			//     fieldProps: {
			//         label: 'Ticketing',
			//         helperText: 'Should contain at least one dot(.) in the middle of the url.'
			//     }
			// },
		],
		[
			{
				type: 'textInput',
				valueKey: 'address.fullAddress',
				fieldProps: {
					name: 'address.fullAddress',
					label: 'Address',
				},
				condition: PHYSICAL_EVENT_CONDITION,
			},
		],
		[
			{
				type: 'textInput',
				valueKey: 'address.city',
				fieldProps: {
					label: 'City',
				},
				condition: PHYSICAL_EVENT_CONDITION,
			},
			{
				type: 'textInput',
				valueKey: 'address.state',
				fieldProps: {
					label: 'State',
				},
				condition: PHYSICAL_EVENT_CONDITION,
			},
			{
				type: 'textInput',
				valueKey: 'address.country',
				fieldProps: {
					label: 'Country',
				},
				condition: PHYSICAL_EVENT_CONDITION,
			},
		],
		// {
		//     type: 'select-field',
		//     valueKey: '',
		//     fieldProps: {
		//         label: 'Event Playlist',
		//         options: ['Select Category', 'Black Lives Matter', 'House of Yes', 'The Anti Racist Reading List', 'Oxford Virtual Film Festival', 'Museum of the Moving Image', "Nupol's  Guide to Active Justice", "Tiny Cupboard", "Art Fairs w/ Jessica Levy", 'Underground Comedy', 'Coa - Your Mental Fitness Community', 'Burning Man - The Multiverse', 'Abc', 'Empty', "Spiritually Sassy w/ Sah D'Simone"].map((x) => ({ label: x, value: (x === 'Select Category' ? '' : x.toLowerCase().replaceAll(' ', '')) })),
		//         fullWidth: true
		//     } as SelectInputProps,
		// }
	];
};

const getProfileConfig = (): IReactFormProps['config'] => {
	return [
		{
			type: 'curator',
			valueKey: '',
			fieldProps: {
				helperText: 'Become a featured curator when you post 20 or more events a week',
			},
		},
	];
};

const getDateConfig = (appConstants: AppConstants, offset?: string, tz?: string): IReactFormProps['config'] => {
	return [
		{
			type: 'date-time-field',
			valueKey: 'eventDates[0]',
			fieldProps: {
				offset,
				timezone: tz,
				dateTimePickerProps: {
					dateInputProps: {
						isValidDate: (date) => date.isAfter(moment().subtract(1, 'day')),
					},
				},
			} as DateTimePickerGroupProps['fieldProps'],
		},
		{
			type: 'event-timezone-picker',
			valueKey: 'timeZone',
		},
		{
			type: 'select-field',
			valueKey: 'frequency',
			fieldProps: {
				label: 'Event Frequency',
				options: [{ label: 'Select type', value: '' }, ...appConstants.Event.frequency.map((f) => ({ label: f.name, value: f.value }))],
				fullWidth: true,
			},
		},
		{
			type: 'date-field',
			valueKey: 'repeatUntil',
			fieldProps: {
				label: 'Repeat Until',
			},
			condition: {
				hidden: true,
				logicOpn: 'OR',
				values: ['daily', 'weekly', 'monthly', 'annually'].map((f) => ({ compareValue: f, key: 'frequency', operator: '===' })),
			},
		},
		{
			type: 'custom-event-date',
			valueKey: '',
			fieldProps: {
				offset,
				timezone: tz,
				dateTimePickerProps: {
					dateInputProps: {
						isValidDate: (date: any) => date.isAfter(moment().subtract(1, 'day')),
					},
				},
			} as EventCustomRecurringDateTimeInputProps['fieldProps'],
			condition: {
				hidden: true,
				values: [{ compareValue: 'custom', key: 'frequency', operator: '===' }],
			},
		},
	];
};
// eslint-disable-next-line
const getPriceConfig = (appConstants: AppConstants): IReactFormProps['config'] => {
	return [
		{
			type: 'select-field',
			valueKey: 'priceCategory',
			fieldProps: {
				label: 'Cost Range',
				options: appConstants.Event.priceCategory.map((p) => ({ label: p.name, value: p.value })),
				fullWidth: true,
			},
		},
	];
};

const getTwitterConfig = (): IReactFormProps['config'] => {
	return [
		{
			type: 'checkbox',
			valueKey: 'tweetParams.isSensitive',
			fieldProps: {
				label: 'Check if the tweet will have sensitive content.',
			},
		},
		{
			type: 'twitterUserSearch',
			valueKey: 'twitterHandles',
		},
	];
};

export { getEventNameSchema, getEventImageSectionSchema, getEventFormSchema };
