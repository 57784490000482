import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import usePasswordField from 'Components/Inputs/usePasswordField';


export type TFormData = {
    password: string
    repeatPassword: string
}


export const useResetPasswordFormConfig = () => {
    const classes = useStyles();
    const actionConfig: IFormActionProps = {
        submitButtonText: 'RESET',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };

    const passwordField = usePasswordField('password', 'Password');
    const repeatPasswordField = usePasswordField('confirmPassword', 'Confirm Password');
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        passwordField,
        repeatPasswordField
    ];
    const validationSchema = Yup.object({
        password: Yup.string().required('Password is Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Passwords do not match')
    })

    return {
        actionConfig,
        inputConfig,
        validationSchema,
    }
}


const useStyles = makeStyles<Theme>((theme) => createStyles({
    buttonContainer: {
        marginTop: 40
    },
    adornmentIcon: {
        cursor: 'pointer'
    }
}))