import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import { TextInputProps } from 'Components/Inputs/TextInput';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import UserSuggestInput from 'Features/User/UserSuggestInput';
import { FormikProps } from 'formik';
import { getName } from 'Models/User';
import { User } from 'Models/User/@types';
import React, { FC, useEffect } from 'react';
import { IFieldProps } from 'react-forms';
import RoleUtils from 'Resources/RoleUtils';
import { useStoreState } from 'Stores';
import { TEvent } from 'Models/Event/@types';
import utilities from 'Resources/Utils';

export interface EventCuratorAutoCompleteProps extends IFieldProps {
    fieldProps?: TextInputProps
}

const EventCuratorAutoComplete: FC<EventCuratorAutoCompleteProps> = (props) => {

    const { formikProps = {} as FormikProps<Partial<TEvent>>, fieldProps = {} } = props;

    const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

    const helperText = fieldProps.helperText || '';

    const handleClick = (user: User) => {
        formikProps.setFieldValue('curatorId', user.id);
        formikProps.setFieldValue('curator', user);
    }

    const selectedCurator: User | undefined = formikProps.values['curator'];

    const handleDelete = () => {
        if (!selectedCurator) return;
        formikProps.setFieldValue('curatorId', '');
        formikProps.setFieldValue('curator', null);
    }

    useEffect(() => {
        if (user?.id) {
            if (!RoleUtils.isRegularUser(user) && !formikProps.values.curatorId) {
                formikProps.setFieldValue('curatorId', user.id);
                formikProps.setFieldValue('curator', user);
            }
        }
        // eslint-disable-next-line
    }, [user]);

    const isAdmin = user ? RoleUtils.isAdmin(user) : false;

    const errorText = formikProps ? utilities.getFieldError('curatorId', formikProps) : '';

    return (
        <Box>
            <PGInputLabel>{isAdmin ? 'Search Communities or People' : 'User'}</PGInputLabel>
            {(errorText) ? <Typography variant={'caption'} color={errorText ? 'error' : undefined} >{errorText}</Typography> : null}
            {
                isAdmin
                    ?
                    <>
                        <UserSuggestInput
                            filter={{ where: { roles: ['CURATOR', 'COMMUNITY', 'ADMIN'] } }}
                            label={'Search Communities or People'}
                            onResultClick={handleClick}
                        />
                        {helperText ? <Typography variant={'caption'} >{helperText}</Typography> : null}
                    </>
                    : null
            }

            {
                selectedCurator
                    ?
                    <Box mt={1.5} >
                        <Chip
                            avatar={<Avatar src={selectedCurator.profileImages?.[0]?.url} />}
                            label={getName(selectedCurator)}
                            onDelete={isAdmin ? handleDelete : undefined}
                        />
                    </Box>
                    : null
            }

        </Box>
    )
}


export default EventCuratorAutoComplete