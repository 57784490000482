import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import FollowersList from './FollowersList';

export interface FollowersProps {}

const Followers: FC<FollowersProps> = (props) => {
	const BASE_URL = '/dashboard/members';

	return (
		<Switch>
			<Route exact path={`${BASE_URL}`} component={FollowersList} />
		</Switch>
	);
};
export default Followers;
