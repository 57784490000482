import { useState } from "react"

export const usePagination = () => {
    const [pageNum, setPageNum] = useState<number>(0);

    const prevPageClick = () => { if (pageNum > 0) setPageNum(pageNum - 1) }

    const nextPageClick = () => setPageNum(pageNum + 1);

    return {
        pageNum,
        setPageNum,
        prevPageClick,
        nextPageClick
    }

}