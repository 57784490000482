import { InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PGTextInput from 'Features/ReactFormsInputs/PGTextInput';
import React, { useState } from 'react';
import { attachField, FormConfig } from 'react-forms';
import { TextInputProps } from './TextInput';

// @ts-ignore
attachField('password', <PGTextInput />);


export default (valueKey: string, label: string) => {
    const [passType, setPassType] = useState<'text' | 'password'>('password')
    const toggleType = () => {
        setPassType(type => type === 'password' ? 'text' : 'password');
    }

    return {
        type: 'password',
        valueKey: valueKey,
        fieldProps: {
            label,
            fullWidth: true,
            endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}>{passType === 'text' ? <VisibilityOffIcon /> : <VisibilityIcon />}</InputAdornment>,
            type: passType
        } as TextInputProps
    } as FormConfig
}