import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox from 'Components/Inputs/Checkbox';
import DashboardActionButtonsList, { DashboardActionButtonsListProps } from 'Features/Dashboard/DashboardActionButtonsList';
import { Picture } from 'Models/Picture/@types';
import React, { FC, useState } from 'react';

export interface EntityCardProps {
    titleTopTextNode?: JSX.Element
    initialCheckedState: boolean
    title: string;
    subtitle?: string;
    img?: Picture
    description?: string
    isDescriptionUrl?: boolean;
    onClick?: (flag: boolean) => void;
    actionsProps?: DashboardActionButtonsListProps
    rightEndNode?: JSX.Element
}

const EntityCard: FC<EntityCardProps> = (props) => {
    const classes = useStyles();

    const { description, initialCheckedState = false, title, img, onClick, actionsProps, titleTopTextNode = <></>, rightEndNode, isDescriptionUrl, subtitle } = props;

    const [checked, setChecked] = useState(initialCheckedState);

    const handleClick = () => {
        setChecked(!checked);
        onClick?.(!checked);
    }

    return (
        <div className={classes.root} onClick={handleClick} >
            <Checkbox
                isChecked={checked}
            />
            <div className={classes.img} >{img?.url ? <img src={img?.url} alt={'name'} /> : null}</div>
            <div className={classes.textContainer} >
                {/* <Typography gutterBottom className={classes.titleTopText} variant={'subtitle1'} >{titleTopText}</Typography> */}
                {titleTopTextNode}
                <Typography gutterBottom variant={'h3'} >{title}</Typography>
                {subtitle ? <Typography gutterBottom variant={'body1'} >{subtitle}</Typography> : null}
                <Typography component={isDescriptionUrl ? 'a' : 'p'} target={'_blank'} href={isDescriptionUrl ? description : ''} gutterBottom variant={'overline'} >{description}</Typography>
            </div>
            <div className={classes.timeAndActionsContainer} >
                {/* {
                    (time && timezone) && <><Typography variant={'caption'} color={'textSecondary'} component={'p'} >{time}</Typography><Typography variant={'caption'} color={'textSecondary'} component={'p'}>{timezone}</Typography></>
                } */}{rightEndNode}

                {/* Stop propagation won't select the event checkbox when clicking on any action button */}
                <div className={classes.actionsContainer} onClick={e => e.stopPropagation()} >
                    {actionsProps ? <DashboardActionButtonsList {...actionsProps} /> : null}
                </div>
            </div>
        </div >
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey } } = theme;
    return (createStyles({
        root: {
            minHeight: 152 + 80,
            padding: '40px 25px 40px 0px',
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px Solid #979797',
            cursor: 'pointer'
        },
        img: {
            marginLeft: 24,
            marginRight: 36,
            width: 152,
            height: 152,
            backgroundColor: grey['500'],
            '& > img': {
                width: 152,
                height: 152,
                objectFit: 'cover'
            }
        },
        textContainer: {
            flex: 1
        },
        titleTopText: {
            color: '#F137D8',
            textTransform: 'uppercase'
        },
        timeAndActionsContainer: {
            textAlign: 'right'
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& button': {
                padding: 4,
                height: 'auto',
                minWidth: 'unset',
                marginLeft: 8
            }
        }
    }))
})

export default EntityCard