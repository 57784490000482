import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteProps as _AutocompleteProps } from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import AxiosUtils from 'Resources/AxiosUtils';

type AutocompleteProps<T> = _AutocompleteProps<T, undefined, undefined, undefined>;

export type AutoSuggestProps<T> = TextFieldProps & {
	onResultClick?: (result: T) => void;
	autoCompleteProps: {
		getOptionLabel?: AutocompleteProps<T>['getOptionLabel'];
		getOptionSelected: AutocompleteProps<T>['getOptionSelected'];
		renderOption?: AutocompleteProps<T>['renderOption'];
	};
	fetchSuggestions: (input: string) => Promise<T[]>;
	retainInputAfterSelect?: boolean;
};

const AutoSuggest = <T extends any>(props: AutoSuggestProps<T>) => {
	const { onResultClick, autoCompleteProps, fetchSuggestions, retainInputAfterSelect = true, ...textFieldProps } = props;

	const [result, setResult] = useState<T[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [input, setInput] = useState('');

	const clearInput = () => {
		if (open) setOpen(false);
		setInput('');
		setResult([]);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!open) setOpen(true);
		setInput(e.target.value);
		// anchorEl || setAnchorEl(e.currentTarget);
	};

	const getSuggestions = useCallback(
		(() => {
			let timeout: NodeJS.Timeout;
			return async (term: string) => {
				if (!term) {
					setResult([]);
					setInput('');
				}
				setInput(term);
				if (timeout) {
					clearTimeout(timeout);
				}
				timeout = setTimeout(async () => {
					const res = await fetchSuggestions(term).catch(AxiosUtils.throwError);
					setResult(res);
				}, 300);
			};
		})(),
		[]
	);

	useEffect(() => {
		if (input) {
			getSuggestions(input);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input]);

	const handleResultClick = (item: any) => {
		// Will set the input with the select option
		setInput(retainInputAfterSelect ? autoCompleteProps.getOptionLabel?.(item) || '' : '');

		typeof props.onResultClick === 'function' && props.onResultClick(item);

		setOpen(false);
	};

	const classes = useStyles();
	return (
		<Autocomplete<T, false, false, true>
			classes={{ popper: classes.popper }}
			filterOptions={(x) => x}
			options={result}
			includeInputInList
			fullWidth
			autoComplete
			open={open && input.length > 0}
			forcePopupIcon={false}
			multiple={false}
			freeSolo={true}
			onChange={(e, v) => {
				handleResultClick(v);
			}}
			onClick={handleResultClick}
			{...autoCompleteProps}
			renderInput={(params) => (
				<TextField
					className={classes.root}
					{...params}
					inputProps={{
						...params.inputProps,
						onChange: handleInputChange,
						value: input || '',
					}}
					InputProps={{
						disableUnderline: true,
						...params.InputProps,
						endAdornment: input.length ? (
							<InputAdornment position={'end'}>
								<IconButton onClick={clearInput}>
									<Clear fontSize="small" className={classes.endIcon} />
								</IconButton>
							</InputAdornment>
						) : null,
						startAdornment: (
							<InputAdornment position={'start'} className={classes.adornment}>
								<IconButton disableRipple disableFocusRipple disableTouchRipple>
									<SearchIcon fontSize="small" />
								</IconButton>
							</InputAdornment>
						),
					}}
					variant="filled"
					{...textFieldProps}
				/>
			)}
			renderOption={autoCompleteProps.renderOption}
			getOptionLabel={autoCompleteProps.getOptionLabel}
		/>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		root: {
			'& > div': {
				padding: '2px 8px !important',
			},
		},
		popper: {
			zIndex: theme.zIndex.modal + 1,
		},
		endIcon: {
			cursor: 'pointer',
		},
		adornment: {
			marginTop: '0px !important',
		},
	});
});

export default AutoSuggest;
