import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AppToastProvider from 'Components/Toast/AppToast';
import AppDialogProvider from 'Components/Dialogs/AppDialogProvider';
import RootLayout from 'Screens/RootLayout';
import { BrowserRouter } from 'react-router-dom';
import MuiTheme from 'MuiTheme';
import MomentUtils from '@date-io/moment';

const App = () => {
    return (
        <BrowserRouter>
            <MuiThemeProvider theme={MuiTheme} >
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <AppToastProvider>
                        <AppDialogProvider>
                            <RootLayout />
                        </AppDialogProvider>
                    </AppToastProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </BrowserRouter>
    )
}

export default App;
