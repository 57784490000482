import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TToken } from 'Models/Auth/@types';
import AxiosUtils from 'Resources/AxiosUtils';
import UserModel from 'Models/User';
import type { User } from 'Models/User/@types';
import { TRootStore } from 'Stores';
export const COOKIES_ACCESS_TOKEN = 'access_token';
export const COOKIES_USER_ID = 'user_id';

type ThunkAction<T = void, R = any> = Thunk<TAuthState, T, any, TRootStore, R>;
type StaticAction<T = void> = Action<TAuthState, T>;


export interface TAuthState {
    token: TToken | null
    user?: User
    setToken: Action<TAuthState, Partial<TToken> | null>
    setUser: Action<TAuthState, User>
    setAuthInfo: Thunk<TAuthState, Partial<TToken>>
    removeAuthInfo: Action<TAuthState>
    loadAuthInfo: Thunk<TAuthState, {} | undefined>
    login: Thunk<TAuthState, { email: string, password: string }>
    logout: Thunk<TAuthState, undefined>;

    fetchPostLoginData: ThunkAction;
}

const AuthState: TAuthState = {
    token: null,
    setToken: action((state, token) => {
        if (!token || !token.accessToken || !token.userId) {
            state.token = null;
            return;
        }
        state.token = {
            accessToken: token.accessToken || '',
            userId: token.userId || '',
            ttl: token.ttl || 0,
            created: token.created || ''
        };
    }),
    setUser: action((state, user) => {
        state.user = user;
    }),
    setAuthInfo: thunk(async (actions, token) => {
        if (!token || !token.accessToken || !token.userId) {
            return;
        }
        actions.setToken(token);
        AxiosUtils.setAuthHeader(token.accessToken);
        const user = await UserModel.fetchMe().catch(err => { throw err });
        await actions.fetchPostLoginData();
        actions.setUser(user);
        localStorage.setItem(COOKIES_ACCESS_TOKEN, token.accessToken);
        localStorage.setItem(COOKIES_USER_ID, token.userId);
    }),
    removeAuthInfo: action(state => {
        AxiosUtils.setAuthHeader(undefined);
        localStorage.removeItem(COOKIES_ACCESS_TOKEN);
        localStorage.removeItem(COOKIES_USER_ID);
        state.token = null;
        state.user = undefined;
    }),
    loadAuthInfo: thunk(async (actions, args) => {
        const token = localStorage.getItem(COOKIES_ACCESS_TOKEN);
        const userId = localStorage.getItem(COOKIES_USER_ID);
        if (!token || !userId) {
            actions.removeAuthInfo();
            return;
        }
        return await actions.setAuthInfo({ accessToken: token, userId });
    }),
    login: thunk(async (actions, credentials: { email: string, password: string }) => {
        const token = await UserModel.login(credentials);
        await actions.setAuthInfo(token);
        return token;
    }),
    logout: thunk(async (actions, args) => {
        await UserModel.logOut().catch(err => { });
        actions.removeAuthInfo();

    }),

    fetchPostLoginData: thunk(async (actions, args, { getStoreActions }) => {
        await Promise.all([
            getStoreActions().App.getAppSummary()
        ])
    })
}

export default AuthState
