import { Box, Button } from '@material-ui/core';
import Typo from 'Components/Typo';
import React, { FC, useEffect, useState } from 'react';
import { useStoreState } from 'Stores';
import { filterItemStyles } from './DateFilters';
import { TFilterType } from './EventFilter';

export interface PriceFiltersProps {
    onChange: (type: TFilterType, value: any, toggle: boolean) => void
    clear?: boolean
}

const PriceFilters: FC<PriceFiltersProps> = (props) => {
    const classes = filterItemStyles();
    const { onChange } = props;
    const [selected, setSelected] = useState<number>()
    const { appConstants } = useStoreState(({ App: { appConstants } }) => ({ appConstants }));
    useEffect(() => {
        if (props.clear) {
            setSelected(-1);
        }
    }, [props.clear])
    return (
        <Box className={classes.wrapper}>
            {
                appConstants?.Event.priceCategory.map((it, i) => {
                    return (
                        <Box key={i} mr={1} className={selected === it.value ? classes.border : ''}>
                            <Button
                                style={{ borderStyle: selected === it.value ? '1px solid black' : 'none' }}
                                className={classes.button} variant='contained' color='default' onClick={() => { setSelected(selected === it.value ? -1 : it.value); onChange('price', it.value, selected === it.value); }}>
                                <Typo weight='bold' variant='overline'>{it.name}</Typo>
                            </Button></Box>

                    )
                })
            }
        </Box>
    )
}

export default PriceFilters