
import { Action, thunk, Thunk, action } from 'easy-peasy';
import { TParams } from 'Models/App/@types';
import UserModel from "Models/User";
import type { Role, User } from 'Models/User/@types';
import AxiosUtils from 'Resources/AxiosUtils';
import { parseUser } from 'Models/User/userParser';
import { SendMessageDialogProps } from 'Features/sendMessage/SendMessageDialog';
import { TRootStore } from 'Stores';

type ThunkAction<T = void, R = any> = Thunk<TUserState, T, any, TRootStore, R>;
type StaticAction<T = void> = Action<TUserState, T>;

export interface TUserState {
	user?: User;
	register: ThunkAction<TUserState>;

	usersList: User[];
	getUsersList: ThunkAction<{ params?: TParams }>;

	followersList: User[];
	getFollowersList: ThunkAction<{ id: string, params?: TParams }>;

	changeRoles: ThunkAction<{ ids: string[], role: Role, add?: boolean }>;

	postUser: ThunkAction<{ data: Partial<User> }>;

	toggleFeatured: ThunkAction<{ data: Partial<User> & Pick<User, 'id' | 'isFeatured'> }>;

	toggleBlocked: ThunkAction<{ data: Partial<User> & Pick<User, 'id' | 'isBlocked'> }>;

	sendMessageDialogProps: SendMessageDialogProps;
	showSendMessageDialog: StaticAction<Omit<SendMessageDialogProps, 'open'>>
	hideSendMessageDialog: StaticAction;
}

const UserState: TUserState = {

	sendMessageDialogProps: { userIds: [], open: false },
	showSendMessageDialog: action((state, args) => {
		state.sendMessageDialogProps = { ...args, open: true }
	}),
	hideSendMessageDialog: action((state) => {
		state.sendMessageDialogProps = { open: false, userIds: [], sendToAll: false }
	}),

	register: thunk(async (action, state) => {
		UserModel.register()
	}),

	usersList: [],
	getUsersList: thunk(async (actions, args, { getState }) => {
		const userSearchResults = await UserModel.search('', args.params?.filter).catch(AxiosUtils.throwError);
		const state = getState();
		state.usersList = userSearchResults.results.map((u) => u.hit);
	}),

	followersList: [],
	getFollowersList: thunk(async (actions, args, { getState }) => {
		const list = await UserModel.getFollowers(args.id, args.params).catch(err => { throw err });
		const state = getState();
		state.followersList = list.map(parseUser);
	}),

	changeRoles: thunk(async (actions, { ids, role, add = true }, { getState }) => {
		await UserModel.changeRole(ids, role, add).catch(AxiosUtils.throwError);
		const state = getState();
		state.usersList = UserModel.updateUsersRole(state.usersList, ids, role, add ? 'add' : 'remove');
	}),

	postUser: thunk(async (actions, args, { getState, getStoreState }) => {
		const state = getState();
		await UserModel.postUser(args.data).catch(AxiosUtils.throwError);
		state.usersList = state.usersList.map((u) => u.id === args.data.id ? { ...u, isFeatured: args.data.isFeatured } : u)
		const store = getStoreState();
		if (store.Auth.user && store.Auth.user.id === args.data.id) {
			store.Auth.user = { ...store.Auth.user, ...args.data }
		}
	}),

	toggleFeatured: thunk(async (actions, args) => await actions.postUser({ data: args.data })),

	toggleBlocked: thunk(async (actions, args, { getState }) => {
		const state = getState();
		await UserModel.updateBlockStatus(args.data.id, !args.data.isBlocked).catch(AxiosUtils.throwError);
		state.usersList = state.usersList.map((u) => u.id === args.data.id ? { ...u, isBlocked: !args.data.isBlocked } : u)
	})

}

export default UserState;