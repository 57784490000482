import SendMessageDialog from 'Features/sendMessage/SendMessageDialog';
import React, { FC } from 'react';
import { useStoreState } from 'Stores';

export interface DialogProviderProps {}

const DialogProvider: FC<DialogProviderProps> = (props) => {
	const { sendMessageDialogProps } = useStoreState(({ User: { sendMessageDialogProps } }) => ({ sendMessageDialogProps }));

	return <>{sendMessageDialogProps.open ? <SendMessageDialog {...sendMessageDialogProps} /> : null}</>;
};

export default DialogProvider;
