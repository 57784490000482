import { AppBar, Button, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import useAsyncTask from 'Hooks/useAsyncTask';
import { getName } from 'Models/User';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';
import playgroundLogo from '../../assets/images/Playground_logo.png';

const NavBar = () => {

    const classes = useStyles()
    const { logout } = useStoreActions(state => state.Auth);
    const { user } = useStoreState(({ Auth: { user } }) => ({ user }));
    const logoutTask = useAsyncTask(logout)

    const LOGOUT = () => {
        logoutTask.run()
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <div>
                    <div className={classes.leftContainer}>
                        <div className={classes.logoContainer} >
                            <Link to="/dashboard/events" className={classes.logoLink}>
                                <img className={classes.logo} src={playgroundLogo} alt='playground logo' />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={classes.rightContainer}>
                    <a target={'__blank'} href='https://playgroundapp.com/privacy' color={'inherit'} >
                        <Typo color={'inherit'} weight="bold" variant='body1' fontVariant="bold">Privacy</Typo>
                    </a>
                    {user ? <Typo weight="bold" variant='body1' fontVariant="bold" >{getName(user)}</Typo> : null}
                    <Button className={classes.logoutBtn} onClick={LOGOUT}><Typo weight="bold" variant='body1' fontVariant="bold">Logout</Typo></Button>
                </div>
            </AppBar>
            <div className={classes.linksRoot}>
                <div className={classes.linkAreaLeftContainer}>
                    <Link to='/' className={clsx(classes.text, classes.leftLinks)} >
                        <Typo fontVariant='black' weight='bold' variant='h4' className={classes.link}> THE APP </Typo>
                    </Link>
                    <Link to='/' className={clsx(classes.text, classes.leftLinks)}>
                        <Typo fontVariant='black' weight='bold' variant='h4' className={classes.link}> ADD YOUR EVENT </Typo>
                    </Link>
                </div>
                <div className={classes.linkAreaRightContainer}>
                    <div className={classes.privacyContainer}>
                        <Link to='/' className={classes.text}>
                            <Typo fontVariant='black' weight='bold' variant='h4' className={classes.link}> PRIVACY </Typo>
                        </Link>
                    </div>
                </div>


            </div>

        </div>
    )
}

const STYLES = (theme: Theme) => createStyles({
    appBar: {
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
    leftContainer: {
        margin: "0 -10px",
        padding: "0 15px"
    },
    logoContainer: {
        padding: "0 10px"
    },
    logoLink: {
        padding: "0 15px",
        lineHeight: '60px'
    },
    link: {
        fontWeight: 800,
        letterSpacing: 2,
        display: 'inline',
        textDecoration:'none'
    },
    rightContainer: {
        display: 'flex',
        padding: '0 10px',
        marginRight: 20,
        alignItems: 'center',
        color: '#fff',
        '& > div': {
            margin: '0px 6px 0px 16px'
        },
        '& a div': {
            color: '#fff'
        }
    },
    logo: {
        verticalAlign: "middle",
        lineHeight: 60,
        display: 'inline',
        height: 36,
    },
    logoMusic: {
        width: 100,
    },
    logoutBtn: {
        color: "white",
        cursor: 'pointer',
        letterSpacing: 1,
        lineHeight: '21px',
    },
    linksRoot: {
        display: 'none',
        padding: 32,
        // margin: '0 -10px',
    },
    linkAreaLeftContainer: {
        flex: 1,
        display: 'inline-block',
    },
    linkAreaRightContainer: {
        padding: '0 10px',
        display: 'inline-block',
    },
    text: {
        color: theme.palette.text.primary,
        textDecoration: "none"
    },
    leftLinks: {
        margin: '0 75px 0 0',
    },
    privacyContainer: {
    }
});

const useStyles = makeStyles(STYLES);

export default NavBar;