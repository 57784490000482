import UserModel from "Models/User";
import qs from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import AxiosUtils from "Resources/AxiosUtils";
import useAsyncTask from "../../Hooks/useAsyncTask";
import { useStoreActions } from "../../Stores";
import { TFormData } from "./useResetPasswordFormConfig";
import config from "config";
import useToastMessage from "Hooks/useToastMessage";


function useResetPassword(): ResetPasswordViewModelState {
    const location = useLocation()
    const query = qs.parse(location.search)
    const history = useHistory();
    const { login } = useStoreActions(({ Auth: { login } }) => ({ login }));

    const withToast = useToastMessage();

    const handleSubmit = async (data: TFormData) => {
        if (!query.token || !query.email) return;
        withToast(async () => {
            AxiosUtils.setAuthHeader(query.token as string);
            const user = await UserModel.fetchMe();
            await UserModel.updateUser({ password: data.password, id: user.id });
            await login({ email: query.email as string, password: data.password })
            history.push(config.get('POST_LOGIN_REDIRECT'));
        }, { successToastMessage: "Password reset successfully" })

    }

    const submitHandler = useAsyncTask(handleSubmit)


    const state: ResetPasswordViewModelState = {
        submitting: submitHandler.status === 'PROCESSING',
        onSubmit: submitHandler.run,
        query
    }

    return state
}


export interface ResetPasswordViewModelState {
    submitting: boolean
    onSubmit: (data: TFormData) => Promise<void>
    query?: any
}

export default useResetPassword;