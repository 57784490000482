import { Box, Button, Typography, Theme, makeStyles, createStyles } from "@material-ui/core";
import { DialogContext } from "Components/Dialogs/AppDialogProvider";
// import Loader from "Components/Loader";
import React, { useContext, useState } from "react";
import { IReactFormProps, MLFormContent } from "react-forms";
import useToastMessage from "./useToastMessage";
import { Formik } from "formik";


const DEFAULT_MESSAGE = 'Confirm action?'



export default (args?: { confirmationFormConfig?: IReactFormProps['config'], initialFormData?: Record<string, any> }) => {
    const { showDialog, hideDialog } = useContext(DialogContext);

    const withConfirmationDialog = (action: (data: any) => any, config: WithConfirmationDialogConfig = {}, toastConfig?: WithConfirmationDialogToastConfig) => {
        const { message } = config;
        showDialog(<div />, {
            headerProps: {
                isCloseButton: false
            },
            isActionCloseButton: false,
            PaperProps: {
                style: {
                    width: 400,
                },
            },
            title: message || DEFAULT_MESSAGE,
            actionsChildren: <ActionButton
                agree={action}
                hideDialog={hideDialog}
                config={config}
                toastConfig={toastConfig}
                confirmationFormConfig={args?.confirmationFormConfig}
                initialFormData={args?.initialFormData}
            />
        });
    }


    return withConfirmationDialog
}




const ActionButton: React.FC<ActionButtonProps> = (props) => {
    const {
        agree,
        config,
        toastConfig,
        hideDialog,
        confirmationFormConfig,
        initialFormData
    } = props;

    const withToast = useToastMessage();
    const [loading, setLoading] = useState(false);
    const isAsync = agree.constructor.name === 'AsyncFunction';
    const classes = useStyles()

    const accept = async (data: any) => {
        if (isAsync) {
            setLoading(true)
            try {
                if (toastConfig) {
                    await withToast(async () => await agree(data), toastConfig);
                } else {
                    await agree(data);
                }
            } catch (error) {

            }
            setLoading(false)
            hideDialog();
        } else {
            if (toastConfig) {
                withToast(() => agree(data), toastConfig);
            }
            else {
                agree(data);
            }
            hideDialog();
        }
    }

    return (
        <Formik
            initialValues={{ ...initialFormData }}
            onSubmit={accept}
        >
            {
                formikProps => {
                    return (
                        <Box width={'100%'} >
                            {confirmationFormConfig ?
                                <Box mb={3} ml={2} >
                                    <MLFormContent
                                        formId={'confirmation-form'}
                                        schema={confirmationFormConfig}
                                        formikProps={formikProps}
                                    />
                                </Box>
                                : null
                            }
                            <Box mr={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} >
                                <Box mr={1.5} >
                                    <Button color={'primary'} size={'small'} disabled={loading} className={classes.actionButton} onClick={formikProps.submitForm} type={'submit'} >
                                        {loading! ? /* <Loader size={'sm'} />  */ "loading..." : <Typography variant={'subtitle2'} color={'inherit'} >{config.agreeText || 'Yes'}</Typography>}
                                    </Button>
                                </Box>
                                <Button disableElevation size={'small'} className={classes.actionButton} disabled={loading} onClick={hideDialog}>{
                                    <Typography color={'inherit'} variant={'subtitle2'} >{config.cancelText || 'Cancel'}</Typography>
                                }</Button>
                            </Box>
                        </Box>
                    )
                }
            }
        </Formik>
    )
}



export interface WithConfirmationDialogConfig {
    message?: string
    agreeText?: string
    cancelText?: string
}

export interface WithConfirmationDialogToastConfig {
    successToastMessage?: string
    errorToastMessage?: string
}

type ActionButtonProps = {
    agree: <T = Record<string, any>>(data?: T) => void | Promise<void>,
    config: WithConfirmationDialogConfig,
    toastConfig?: WithConfirmationDialogToastConfig,
    hideDialog: () => void;
    confirmationFormConfig?: IReactFormProps['config'];
    initialFormData?: Record<string, any>;
}

const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
    // cancelButton: { backgroundColor: '#ededed' },
    actionButton: {
        backgroundColor: theme.palette.grey["700"],
        color: "#fff",
        padding: '6px 12px',
        '&:hover': {
            backgroundColor: theme.palette.grey["700"],
        }
    }
}))