import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const usePGInputStyle = makeStyles<Theme>((theme) => {
    const { palette: { grey }, typography: { subtitle2 } } = theme;
    return (createStyles({
        root: {
            height: 38,
            backgroundColor: grey['500'],
            border: `1px Solid ${grey['500']}`,
            borderRadius: 4,
            padding: '7px 12px',
            outline: 'none',
            ...subtitle2,
            color: '#565656',
            maxWidth: '100%'
        }
    }))
})