import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import { usePlaylistDetails } from 'Features/PlayList/usePlaylistDetails';
import { RouteComponentProps } from 'react-router';
import { useStoreState, useStoreActions } from 'Stores';
import { getPlaylistFormSchema } from 'Features/PlayList/playlistFormConfig';
import FormActionButtons, { FormActionButton } from 'Components/Buttons/FormActionButtons';
import FormSection from 'Components/Forms/FormSection';
import { Playlist } from 'Models/Playlist/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import Loader from 'Components/Loader';
import useToastMessage from 'Hooks/useToastMessage';
import AxiosUtils from 'Resources/AxiosUtils';

export interface PlaylistFormProps extends RouteComponentProps<{ id?: string }> { }

const PlaylistForm: FC<PlaylistFormProps> = ({ match, history }) => {
    const classes = useStyles();

    const playlistId = match.params.id;

    const { tagList, user } = useStoreState(({ Tag: { tagList }, Auth: { user } }) => ({ tagList, user }));
    const { postPlaylist } = useStoreActions(({ PlayList: { postPlaylist } }) => ({ postPlaylist }));
    const postPlaylistTask = useAsyncTask(postPlaylist);
    const { loadingPlaylist, playlistDetails = {} } = usePlaylistDetails(playlistId);
    const withToast = useToastMessage();

    const handleActionButtonClick = async (type: FormActionButton, formikProps: FormikProps<Partial<Playlist>>) => {
        if (!user) return;
        switch (type) {
            case 'cancel':
                history.push('/dashboard/playlists');
                break;
            case 'create':
            case 'update':
                withToast(async () => {
                    await postPlaylistTask.run({ playlist: { ...(formikProps.values || {}), userId: user.id } }).catch(AxiosUtils.throwError);
                    history.push('/dashboard/playlists');
                })
                break;
        }
    }

    const submitting = postPlaylistTask.status === 'PROCESSING';

    return (
        <div>
            {
                loadingPlaylist ? <Loader />
                    :
                    <Formik<Partial<Playlist>>
                        initialValues={playlistDetails}
                        enableReinitialize
                        onSubmit={() => { }}
                    >
                        {
                            formikProps => {
                                return (
                                    <div className={classes.root} >
                                        <Box className={classes.topActionButtonContainer} >
                                            <FormActionButtons
                                                loading={submitting}
                                                onClick={b => handleActionButtonClick(b, formikProps)}
                                                excludedButtons={['preview', 'publish', 'saveDraft', playlistId ? 'create' : 'update', 'unpublish', 'download']}
                                            />
                                        </Box>
                                        <div className={classes.formContainer} >
                                            {

                                                getPlaylistFormSchema(tagList?.list || []).map((formSectionProps, i) => {
                                                    return (
                                                        <div key={formSectionProps.formId || i} >
                                                            <FormSection formikProps={formikProps} {...formSectionProps} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Box className={classes.bottomActionButtonContainer} >
                                            <FormActionButtons
                                                loading={submitting}
                                                onClick={b => handleActionButtonClick(b, formikProps)}
                                                excludedButtons={['preview', 'publish', 'saveDraft', playlistId ? 'create' : 'update', 'unpublish', 'download']}
                                            />
                                        </Box>
                                    </div>
                                )
                            }
                        }
                    </Formik>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey } } = theme;
    return (createStyles({
        root: {
            paddingBottom: 50
        },
        formContainer: {
            width: '66.66%',
        },
        topActionButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            borderBottom: `2px Solid ${grey['500']}`,
            paddingBottom: 20
        },
        bottomActionButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 20,
        }
    }))
})

export default PlaylistForm