
import { thunk, Thunk } from 'easy-peasy';
import TagModel from 'Models/Tags';
import { Tag } from 'Models/Tags/@types';
import AxiosUtils from 'Resources/AxiosUtils';

type ThunkAction<T = void> = Thunk<TagState, T>;

export interface TagState {
    tagList?: { total: number, list: Tag[] };
    getTagsList: ThunkAction
}

const TagState: TagState = {
    getTagsList: thunk(async (action, args, { getState }) => {
        const state = getState();
        const res = await TagModel.getTagsList().catch(AxiosUtils.throwError);
        state.tagList = { total: res.total, list: res.results.map(x => x.hit) };
    })
}

export default TagState;