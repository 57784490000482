import { JSONType } from "Typings/Global";
import { Playlist } from "./@types";
import { parseEvent } from "Models/Event/eventParsers";
import { parseUser } from "Models/User/userParser";

export const parseToPlayListType = (obj: JSONType): Playlist => {

	const { _image, tags = [], tagIds = [], shortDescription, isFavourited, featured, events = [], user, ...rest } = obj;

	return {
		...rest,
		shortDescription,
		categoryId: tagIds[0],
		category: tags[0],
		image: _image,
		isFavourited: !!isFavourited,
		isFeatured: !!featured,
		events: events.map(parseEvent),
		user: user && parseUser(user)
	} as Playlist
}

export const parsePlaylistFormData = (playlist: Partial<Playlist>): JSONType => {
	let formData: JSONType = {}
	const {
		id, name, shortDescription, description, categoryId, image, isFeatured, eventIds = [], userId
	} = playlist;

	formData = {
		...(id ? { id } : {}),
		name, description,
		shortDescription,
		tagIds: [categoryId].filter(_ => _),
		_image: image,
		featured: isFeatured,
		eventIds,
		userId
	}

	return formData;
}