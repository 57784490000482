import { Box, Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import loadImage from 'blueimp-load-image';
import clsx from 'clsx';
import _ from 'lodash';
import PictureModel from 'Models/Picture';
import { Picture, TFile } from 'Models/Picture/@types';
import React, { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IFieldProps } from 'react-forms';
import PGInputLabel from './PGInputLabel';

export interface PGDropZoneInputProps extends IFieldProps {
	fieldProps?: {
		name?: string;
		multiple?: boolean;
		label?: string;
	};
}

const PGDropZoneInput: FC<PGDropZoneInputProps> = (props) => {
	const classes = useStyles();

	const { formikProps, fieldProps = {}, fieldConfig } = props;

	const { multiple = true, label } = fieldProps;

	const valueKey = fieldConfig?.valueKey || fieldProps?.name || '';

	const initialValue = multiple ? formikProps?.values[valueKey] || [] : formikProps?.values[valueKey] ? [formikProps?.values[valueKey]] : [];

	const [images, setImages] = useState<TFile[]>(initialValue);
	const [formImages, setFormImages] = useState<Picture[]>(initialValue);

	const onDrop = React.useCallback(
		(acceptedFiles) => {
			let allFiles: any[] = [];
			_.each(acceptedFiles, (file: any) => {
				let reader = new FileReader();
				reader.onload = (event) => {
					let fileInfo = {
						name: file.name,
						type: file.type,
						size: Math.round(file.size / 1000) + ' kB',
						base64: reader.result,
						file: file,
					};
					allFiles.push(fileInfo);
					if (allFiles.length === (acceptedFiles && acceptedFiles.length)) {
						setImages((i) => i.concat(allFiles));
						uploadFiles(allFiles);
					}
				};
				reader.readAsDataURL(file);
			});
		},
		// eslint-disable-next-line
		[images.length]
	);

	const uploadFiles = (files: any[]) => {
		if (_.isEmpty(files)) return;

		const filePromises = files.map((file, index) => {
			return new Promise((resolve, reject) => {
				loadImage(
					file.base64,
					(img: any, data: any) => {
						resolve(file);
					},
					{ meta: undefined }
				);
			});
		});

		Promise.all(filePromises).then((newFiles) => {
			PictureModel.uploadSequential(newFiles, (current: any, response: any) => {
				formikProps?.setFieldValue(valueKey, multiple ? formImages.concat(response) : response);
				setFormImages((i) => i.concat(response));
			});
		});
	};

	useEffect(() => {
		formikProps?.setFieldValue(valueKey, multiple ? formImages : formImages[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formImages.length]);

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, image: Picture, index: number) => {
		e.stopPropagation();
		setFormImages((i) => i.filter((_, i) => i !== index));
		setImages((i) => i.filter((_, x) => x !== index));
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div>
			{label ? <PGInputLabel>{label}</PGInputLabel> : null}
			{
				<Box display="flex" justifyContent="flex-start">
					<Box
						{...getRootProps()}
						border="0px dashed grey"
						width="800px"
						minHeight={'215px'}
						display="flex"
						alignItems="center"
						justifyContent="center"
						className={classes.dropZone}
					>
						<input {...getInputProps()} accept="image/*" multiple={multiple} />
						{images.length < 1 ? (
							<div className={clsx([isDragActive ? classes.dropZoneActive : classes.dropZoneInactive, classes.textContainer])}>
								<Typography variant={'caption'}>Drop file/s here to upload</Typography>
							</div>
						) : (
							<Grid container spacing={3} className={classes.gallery}>
								{images.map((img, index) => {
									const isLoading = index > formImages.length - 1;
									return (
										<Grid item md={3} xl={3} lg={3} className={classes.imgListItem}>
											{isLoading ? <div className={clsx(classes.loader, 'breathe-loader')} /> : null}
											<>
												{isLoading ? (
													<img src={(images[index]?.base64 || '') as string} alt={img.name} />
												) : (
													<img
														src={formImages[index]?.thumbnail || formImages[index]?.url || ''}
														alt={formImages[index]?.imagePath}
													/>
												)}
												<Button
													disabled={isLoading}
													onClick={(e) => handleDelete(e, formImages[index], index)}
													variant={'text'}
													size={'small'}
												>
													<Typography variant={'caption'} component={'p'}>
														Remove file
													</Typography>
												</Button>
											</>
										</Grid>
									);
								})}
							</Grid>
						)}
					</Box>
				</Box>
			}
		</div>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		imageCard: {
			background: theme.palette.common.white,
			boxShadow: theme.shadows[1],
			borderRadius: 4,
			padding: '0 5px',
			maxHeight: 200,
		},
		imageItem: {
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
			padding: 0,
			margin: 0,
			maxHeight: 152,
		},
		textContainer: {
			'& > h2': {
				marginBottom: 16,
			},
		},
		dropZone: {
			backgroundColor: '#ededed',
			borderRadius: 4,
			padding: 32,
		},
		dropZoneActive: {
			color: 'lightgrey',
			textAlign: 'center',
		},
		dropZoneInactive: {
			color: 'textPrimary',
			textAlign: 'center',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
		gallery: {
			alignItems: 'flex-start',
		},
		imgListItem: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			'& img': {
				borderRadius: 20,
				width: '100%',
				height: 'auto',
				maxHeight: '150px',
			},
			'& p': {
				color: '#0381bb',
			},
		},
		loader: {
			position: 'absolute',
			left: '20%',
			right: '20%',
			top: 'calc(50% - 32px)',
			height: 24,
			borderRadius: 12,
			backgroundColor: '#fff',
		},
	});
});

export default PGDropZoneInput;
