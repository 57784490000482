import { Action, action, thunk, Thunk } from "easy-peasy";
import { TParams } from "Models/App/@types";
import EventModel from "Models/Event";
import { TEvent } from "Models/Event/@types";
import { parseEvent } from "Models/Event/eventParsers";
import AxiosUtils from "Resources/AxiosUtils";
import { Injections, TRootStore } from "Stores";
import { JSONType } from "Typings/Global";
import utilities from "Resources/Utils";

type ThunkAction<T = void, R = void> = Thunk<TEventState, T, any, TRootStore, Promise<R>>;
type StaticAction<T = void> = Action<TEventState, T>;

export interface TEventState {
	eventList: TEvent[]
	eventDetails?: TEvent
	fetchEventList: ThunkAction<{ params?: TParams }, TEvent[]>
	setEventList: StaticAction<{ eventList: TEvent[] }>

	deleteEvent: ThunkAction<{ ids: string[] }, JSONType>

	fetchSingleEvent: Thunk<TEventState, string, Injections>
	setSingleEvent: StaticAction<TEvent>;

	postEvent: ThunkAction<{ event: Partial<TEvent>; togglePublish?: boolean }>;

	togglePublish: ThunkAction<{ id: string, publish: boolean }>;

	toggleFavourite: ThunkAction<{ eventIds: string[], favourite: boolean }>;

	toggleFeatured: ThunkAction<{ data: Partial<TEvent> & { id: string } }>;

	eventUpdated: StaticAction<{ event: TEvent }>;
	afterToggleFavourite: StaticAction<{ eventIds: string[], favourite: boolean }>;
	afterTogglePublish: StaticAction<{ id: string, publish: boolean }>;
	afterToggleFeatured: StaticAction<{ id: string, featured: boolean }>;

}

const EventState: TEventState = {
	eventList: [],

	// Made to be listened by other stores.
	eventUpdated: action((state, { event }) => {
		state.eventList = utilities.updateItemList([...state.eventList], event, 'PUT');
	}),
	afterToggleFeatured: action(() => { }),
	afterToggleFavourite: action(() => { }),
	afterTogglePublish: action(() => { }),

	fetchEventList: thunk(async (actions, { params }) => {
		const finalParams = params ? { filter: { ...params.filter, include: 'tags' } } : { filter: { include: 'tags' } };
		const res = await EventModel.getEvents(finalParams)
		const events: TEvent[] = [...res.map((event: Event) => parseEvent(event))]
		actions.setEventList({ eventList: events })
		return events
	}),
	setEventList: action((state, { eventList }) => {
		state.eventList = eventList;
		state.eventDetails = undefined;
	}),
	deleteEvent: thunk(async (actions, { ids }, { getState }) => {
		const state = getState();
		const res = await EventModel.deleteEvents(ids);
		state.eventList = state.eventList.filter(e => !ids.includes(e.id));
		return res
	}),

	fetchSingleEvent: thunk(async (actions, params, { injections, getState }) => {
		const res = await injections.EventModel.getSingleEvent(params);
		const state = getState();
		state.eventDetails = res;
	}),
	setSingleEvent: action((state, payload) => {
		state.eventDetails = payload;
	}),

	postEvent: thunk(async (actions, params, { getState }) => {
		if (params.event.frequency === 'oneTime') {
			params.event.eventDates = params.event.eventDates ? [params.event.eventDates[0]] : [];
            delete params.event.repeatUntil;
		}
		else if (params.event.frequency === 'custom') {
            delete params.event.repeatUntil;
		}
		const res = await EventModel.postEvent(params.event, params.togglePublish).catch(AxiosUtils.throwError);
		const state = getState();
		actions.eventUpdated({ event: res })
		state.eventDetails = res;
	}),

	togglePublish: thunk(async (actions, args, { getState }) => {
		await EventModel.togglePublishEvent(args.id, args.publish).catch(AxiosUtils.throwError);
		const state = getState();
		if (state.eventDetails?.id && state.eventDetails?.id === args.id) {
			state.eventDetails.isPublished = args.publish;
		}
		state.eventList = [...(state.eventList || [])].map(e => e.id === args.id ? ({ ...e, isPublished: args.publish }) : e);
		actions.afterTogglePublish(args);
	}),


	toggleFavourite: thunk(async (actions, args, { getState }) => {
		const state = getState();
		if (args.favourite) await EventModel.addToFavourites(args.eventIds).catch(AxiosUtils.throwError);
		else await EventModel.removeFromFavourites(args.eventIds).catch(AxiosUtils.throwError);
		state.eventList = EventModel.updateEventsFlag('isFavourited', state.eventList, args.eventIds, args.favourite);
		actions.afterToggleFavourite(args);
	}),

	toggleFeatured: thunk(async (actions, args) => await actions.postEvent({ event: args.data }))

}

export default EventState