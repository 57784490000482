import { TParams, TFilter } from "Models/App/@types";
import AxiosUtils, { request } from "Resources/AxiosUtils";
import { JSONType, SearchResponse } from "Typings/Global";
import { TEvent } from "./@types";
import { parseEventFormData, parseEvent } from "./eventParsers";



class EventModel {

    static postEvent = async (data: Partial<TEvent>, togglePublish?: boolean): Promise<TEvent> => {

        if (data.id) {
            if (togglePublish) {
                await EventModel.togglePublishEvent(data.id, !data.isPublished).catch(AxiosUtils.throwError);
            }
            return await EventModel.updateEvent({ ...data, id: data.id }).catch(AxiosUtils.throwError);
        }
        var res = await request({
            url: '/events/new',
            method: 'POST',
            data: { ...parseEventFormData(data) }
        }).then().catch(AxiosUtils.throwError)
        if (togglePublish)
            await EventModel.togglePublishEvent(res.id, true);

        return { ...data, ...parseEvent(res) } as TEvent;
    }

    static updateEvent = async (data: (Partial<TEvent> & { id: string })): Promise<TEvent> => {
        const res = await request({
            url: `/events/${data.id}`,
            method: 'patch',
            data: { ...parseEventFormData(data) }
        }).catch(AxiosUtils.throwError);
        return { ...data, ...parseEvent(res) } as TEvent;
    }

    static getEvents = async (params?: TParams) => {
        const res = await request<JSONType>({
            url: '/Events',
            method: 'GET',
            params
        })
        return res
    }
    static deleteEvents = async (ids: string[]) => {

        const res = await request<JSONType>({
            url: '/Events/trash',
            method: 'DELETE',
            data: { ids: ids }
        })
        return res
    }
    static getSingleEvent = async (id: string) => {
        const res = await request<JSONType>({
            url: `/Events/${id}`,
            params: { filter: { include: ['user', 'tags'] } }
        }).catch(AxiosUtils.throwError);
        return parseEvent(res);
    }

    static togglePublishEvent = (id: string, publish: boolean) => request<JSONType>({ url: `/events/${id}/publish`, method: 'post', params: { publish } });

    static searchEvents = (term: string, filter?: TFilter) => request<SearchResponse<TEvent>>({ url: '/events/search', params: { term, filter } });

    static addToFavourites = (eventIds: string[]) => request({ url: `/events/addToFavourites`, method: 'post', params: { eventIds } });

    static removeFromFavourites = (eventIds: string[]) => request({ url: `/events/removeFromFavourites`, method: 'delete', params: { eventIds } });

    static updateEventsFlag = (key: string, events: TEvent[] = [], eventIds: string[] = [], flag: boolean = true) => [...(events || [])].map(e => eventIds.includes(e.id) ? ({ ...e, [key]: flag }) : e);

}

export default EventModel;