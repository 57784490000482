import { useEffect, useState } from 'react';
import Config, { TConfig } from './config';
import AxiosUtils from './Resources/AxiosUtils';
import { useStoreActions } from './Stores';

const VALIDATE_CONFIG_PROPERTIES: (keyof TConfig)[] = ['BASE_URL', 'API_URL'];

const validateConfig = () => {
    VALIDATE_CONFIG_PROPERTIES.forEach(key => {
        const val = Config.get(key);
        if (!val)
            throw new Error(`App config must define ${key}`);
    });

}

const useBoot = () => {
    const [isCompleted, setIsCompleted] = useState(false);
    const { loadAuthInfo, } = useStoreActions(({
        Auth: { loadAuthInfo },
    }) => ({ loadAuthInfo, }));


    useEffect(() => {

        AxiosUtils.setBaseAPI_URL(Config.get('API_URL'));

        validateConfig();

        /** Override console.log as per environment file */
        if (Config.get('CONSOLE_LOGGING') === false) {
            console.log = () => { }
        }

        Promise.all([
            loadAuthInfo({})
        ]).then((res) => {
            setIsCompleted(true);
        }).catch(() => {
            setIsCompleted(true);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isCompleted, loadAuthInfo }

}

export default useBoot;