import { Box, Collapse } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchBar from 'Components/Inputs/SearchBar';
import Loader from 'Components/Loader';
import Pagination from 'Components/Pagination';
import DashboardActionButtonsList from 'Features/Dashboard/DashboardActionButtonsList';
import UserListCard from 'Features/User/UserListCard';
import UsersFilter from 'Features/User/UsersFilter';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useSearch } from 'Hooks/useSearch';
import { TFilter } from 'Models/App/@types';
import UserModel from 'Models/User';
import { Role, User } from 'Models/User/@types';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStoreActions, useStoreState } from 'Stores';
import useToastMessage from 'Hooks/useToastMessage';
import _ from 'lodash';
import utilities from 'Resources/Utils';

export interface UsersListProps extends RouteComponentProps { }

const UsersList: FC<UsersListProps> = ({ history }) => {
    const classes = useStyles();

    const { handleChange, results: searchResults, searchTerm, setSearchResults } = useSearch(UserModel.search);

    const { getUsersList, changeRoles } = useStoreActions(({ User: { getUsersList, changeRoles } }) => ({ getUsersList, changeRoles }));
    const { usersList } = useStoreState(({ User: { usersList } }) => ({ usersList }));

    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const withToast = useToastMessage();

    const getUsersListTask = useAsyncTask(getUsersList);
    const changeRolesTask = useAsyncTask(changeRoles);

    const [extraParams, setExtraParams] = useState<{ pageNum: number, filter?: TFilter }>({ pageNum: 0 });

    const nextPage = () => setExtraParams({ ...extraParams, pageNum: extraParams.pageNum + 1 })

    const [showFilter, setShowFilter] = useState(false);

    const prevPage = () => { if (extraParams.pageNum > 0) setExtraParams({ ...extraParams, pageNum: extraParams.pageNum - 1 }) }

    useEffect(() => {
        getUsersListTask.run({ params: { filter: { limit: 20, skip: extraParams.pageNum * 20, sort: [{ created: { order: 'DESC' } }], ...extraParams.filter } } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraParams]);

    const handleFilterChange = (filter: TFilter) => {
        setExtraParams({ pageNum: 0, filter });
    }

    const handleClick = (flag: boolean, user: User) => {
        if (flag) {
            setSelectedUsers([...selectedUsers, user.id])
            return
        }
        setSelectedUsers(selectedUsers.filter(id => user.id !== id))
    }

    const handleChangeRoles = (role: Role) => {
        if (selectedUsers.length)
            withToast(async () => {
                await changeRolesTask.run({ ids: selectedUsers, role: role }).then(() => {
                    updateRoleChangeOfSearchResult(selectedUsers, role, 'add');
                });
            }, { successToastMessage: `User made ${_.capitalize(role)}` })
    }

    const updateRoleChangeOfSearchResult = (ids: string[], role: Role, action: 'add' | 'remove') => {
        setSearchResults(UserModel.updateUsersRole(searchResults, ids, role, action));
    }

    const updateToggleFeatureOfSearchResult = (user: User) => {
        setSearchResults(utilities.updateItemList(searchResults, user, 'UPDATE'));
    }

    return (
        <>
            <div className={classes.actionsContainer} >
                <DashboardActionButtonsList config={
                    [
                        { action: 'filter', onClick: () => setShowFilter(!showFilter) },
                    ]
                } align={'ltr'} />
                <SearchBar placeholder={'Search Users ...'} onChange={e => handleChange(e.target.value)} value={searchTerm} />
            </div>
            <div className={classes.actionsContainer} >
                <Pagination previous={prevPage} next={nextPage} />
                <DashboardActionButtonsList
                    config={[
                        { action: 'curator', label: 'Mark as Curator', onClick: () => handleChangeRoles('CURATOR'), disabled: changeRolesTask.status === 'PROCESSING' },
                        { action: 'community', label: 'Mark as Community', onClick: () => handleChangeRoles('COMMUNITY'), disabled: changeRolesTask.status === 'PROCESSING' }
                    ]}
                    align={'rtl'}
                />
            </div>
            <Collapse timeout={500} in={showFilter} >
                <UsersFilter handleFilterChange={handleFilterChange} />
            </Collapse>
            <Box mt={4} position={'relative'} >
                {
                    getUsersListTask.status === 'PROCESSING' ? <Loader /> :
                        (searchTerm ? searchResults : usersList)?.map((u) => {
                            return (
                                <UserListCard
                                    key={u.id}
                                    user={u}
                                    onClick={handleClick}
                                    initialCheckedState={selectedUsers.includes(u.id)}
                                    onRoleChange={updateRoleChangeOfSearchResult}
                                    onUserUpdate={updateToggleFeatureOfSearchResult}
                                />
                            )
                        })
                }
            </Box>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        actionsContainer: {
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-between'
        }
    }))
})

export default UsersList