import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons, { FormActionButton } from 'Components/Buttons/FormActionButtons';
import FormSection from 'Components/Forms/FormSection';
import Loader from 'Components/Loader';
import { getEventFormSchema } from 'Features/Event/EventForm/eventFormConfig';
import { useEventDetails } from 'Features/Event/useEventDetails';
import { Formik, FormikProps, yupToFormErrors } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import { TEvent } from 'Models/Event/@types';
import React, { FC, memo, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import AxiosUtils from 'Resources/AxiosUtils';
import { useStoreActions, useStoreState } from 'Stores';
import * as Yup from 'yup';
import config from 'config';
import { Picture } from 'Models/Picture/@types';
import { ToastContext } from 'Components/Toast/AppToast';
import { Address } from 'Models/Address/@types';
import { DeepPartial } from 'Typings/Global';

const saveToDraftValidationSchema = Yup.object<{ name: string }>({
	name: Yup.string().required('Name is required'),
});

export const publishValidationSchema = Yup.object<DeepPartial<TEvent>>({
	name: Yup.string().required('Name is required'),
	subtitle: Yup.string().required('Subtitle is required'),
	primaryEventCategoryId: Yup.string().required('Event primary category is required'),
	primaryImage: Yup.object<Picture>().required('Primary image is required'),
	curatorId: Yup.string().required('This field is required'),
	platformUrl: Yup.string().when('eventType', {
		is: 'online',
		then: Yup.string().required('Event URL is required').url('Enter  valid URL, e.g. https://google.com'),
	}),
	rsvpUrl: Yup.string().url('RSVP URL is required').url('Enter  valid URL, e.g. https://google.com'),
	platformName: Yup.string().when('eventType', {
		is: 'online',
		then: Yup.string().required('Platform name is required'),
	}),
	eventType: Yup.string().oneOf(['online', 'offline']).required('Please choose an event type'),
	description: Yup.string().required('Description is required'),
	address: Yup.object<Partial<Address>>().when('eventType', {
		is: 'offline',
		then: Yup.object<Partial<Address>>({
			fullAddress: Yup.string().required('Address is required'),
			city: Yup.string().required('City is required'),
			state: Yup.string().required('State is required'),
			country: Yup.string().required('Country is required'),
		}),
	}),
	timeZone: Yup.object().required('Timezone is required'),
	frequency: Yup.string()
		.oneOf(['oneTime', 'daily', 'weekly', 'monthly', 'annually', 'custom'], 'Event frequency if invalid')
		.required('Event frequency is required'),
});

export interface EventFormProps extends RouteComponentProps<{ id?: string; duplicateId?: string }> {}

const EventForm: FC<EventFormProps> = ({ history, match }) => {
	const { appConstants, tagList } = useStoreState(({ App: { appConstants }, Tag: { tagList } }) => ({ appConstants, tagList }));
	const { postEvent } = useStoreActions(({ Events: { postEvent } }) => ({ postEvent }));

	const withToast = useToastMessage();
	const { showToast } = useContext(ToastContext);

	const isDuplicating = match.params.duplicateId;
	const eventId = match.params.id || match.params.duplicateId;

	const { eventDetails, loadingEvent } = useEventDetails(eventId);

	const classes = useStyles();

	const postEventTask = useAsyncTask(postEvent);

	const handleSubmit = (formData: Partial<TEvent>, togglePublish?: boolean) => {
		withToast(async () => {
			await postEventTask.run({ event: formData, togglePublish }).catch(AxiosUtils.throwError);
			history.push('/dashboard/events');
		});
	};

	const onSave = (formData: Partial<TEvent>) => {
		withToast(async () => {
			await postEventTask.run({ event: formData }).catch(AxiosUtils.throwError);
			history.push('/dashboard/events');
		});
	};

	const handleActionButtonClick = async (type: FormActionButton, formikProps: FormikProps<Partial<TEvent>>) => {
		let errors = {};
		if (type === 'publish' || type === 'saveDraft') {
			await formikProps.submitForm();
		}

		switch (type) {
			case 'cancel':
				history.push('/dashboard/events');
				break;
			case 'preview':
				history.push(`/dashboard/events/preview/${eventDetails.id}`);
				break;
			case 'publish':
			case 'unpublish':
				if (type === 'publish')
					publishValidationSchema.validate(formikProps.values, { recursive: true, abortEarly: false }).then(
						() => {
							handleSubmit(formikProps.values, true);
						},
						(err) => {
							errors = yupToFormErrors(err);
							formikProps.setErrors(errors);
							showToast('Form contains one or more errors, please fix them before submit', { variant: 'error' });
						}
					);
				break;
			case 'saveDraft':
				saveToDraftValidationSchema.validate(formikProps.values, { recursive: true, abortEarly: false }).then(
					() => {
						onSave(formikProps.values);
					},
					(err) => {
						errors = yupToFormErrors(err);
						formikProps.setErrors(errors);
						showToast('Form contains one or more errors, please fix them before submit', { variant: 'error' });
					}
				);
				break;
			case 'download':
				window.open(`${config.get('API_URL')}events/${eventId}/reminderCsv`);
		}
	};

	const submitting = postEventTask.status === 'PROCESSING';

	return (
		<div>
			{loadingEvent ? (
				<Loader />
			) : (
				<Formik<Partial<TEvent>>
					initialValues={isDuplicating ? { ...eventDetails, id: '', isPublished: false } : eventDetails}
					enableReinitialize
					onSubmit={() => {}}
				>
					{(formikProps) => {
						return (
							<div className={classes.root}>
								<Box className={classes.topActionButtonContainer}>
									<FormActionButtons
										loading={submitting}
										onClick={(b) => handleActionButtonClick(b, formikProps)}
										excludedButtons={['create', 'update', formikProps.values.isPublished ? 'publish' : 'unpublish', 'download']}
									/>
								</Box>
								<div className={classes.formContainer}>
									{appConstants
										? getEventFormSchema({
												appConstants,
												tags: tagList?.list || [],
												currentChars: formikProps.values?.name?.length || 0,
												offset: formikProps.values.timeZone?.offset,
												tz: formikProps.values.timeZone?.name,
										  }).map((formSectionProps, i) => {
												return (
													<div key={formSectionProps.formId || i}>
														<FormSection formikProps={formikProps} {...formSectionProps} />
													</div>
												);
										  })
										: null}
								</div>
								<Box className={classes.bottomActionButtonContainer}>
									<FormActionButtons
										loading={submitting}
										onClick={(b) => handleActionButtonClick(b, formikProps)}
										excludedButtons={['create', 'update', formikProps.values.isPublished ? 'publish' : 'unpublish', 'download']}
									/>
								</Box>
							</div>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	const {
		palette: { grey },
	} = theme;
	return createStyles({
		root: {
			paddingBottom: 50,
		},
		formContainer: {
			width: '66.66%',
		},
		topActionButtonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			borderBottom: `2px Solid ${grey['500']}`,
			paddingBottom: 20,
		},
		bottomActionButtonContainer: {
			display: 'flex',
			justifyContent: 'center',
			paddingTop: 20,
		},
	});
});

export default memo(EventForm);
