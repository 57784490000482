import TextArea, { TextAreaProps } from 'Components/Inputs/TextArea';
import { FormikValues } from 'formik';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';

export interface PGTextAreaInputProps extends IFieldProps {
    fieldProps?: TextAreaProps
}

const PGTextAreaInput: FC<PGTextAreaInputProps> = (props) => {
    const { fieldProps = {} as TextAreaProps, formikProps = {} as FormikValues, fieldConfig } = props;
    const updatedProps = {
        ...fieldProps,
        onChange: formikProps.handleChange,
        onBlur: formikProps.handleBlur,
        value: formikProps.values?.[fieldConfig?.valueKey || ''] || ''
    }
    return (
        <TextArea {...updatedProps} />
    )
}


export default PGTextAreaInput