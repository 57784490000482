import AutoSuggest from 'Components/Inputs/AutoSuggest';
import UserModel, { getName } from 'Models/User';
import { User } from 'Models/User/@types';
import React, { FC } from 'react';
import { TFilter } from 'Models/App/@types';

export interface UserSuggestInputProps {
    onResultClick?: (u: User) => void;
    filter?: TFilter;
    label?: string;
}

const UserSuggestInput: FC<UserSuggestInputProps> = ({ filter, onResultClick, label = 'Search users' }) => {

    return (
        <AutoSuggest<User>
            fetchSuggestions={async input => {
                const res = await UserModel.search(input, filter);
                return res.results.map(x => x.hit);
            }}
            autoCompleteProps={{
                getOptionLabel: (option) => getName(option),
                getOptionSelected: (user) => !!getName(user),
            }}
            placeholder={label}
            onResultClick={onResultClick}
            retainInputAfterSelect={false}
        />
    )
}


export default UserSuggestInput