import { Box, Button } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import DateInput from 'Components/Inputs/DateInput';
import Typo from 'Components/Typo';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { TFilterType } from './EventFilter';
export interface DateFiltersProps {
    onChange: (type: TFilterType, value: any, toggle: boolean) => void
    clear?: boolean
}

const DateFilters: FC<DateFiltersProps> = (props) => {
    const classes = filterItemStyles();
    const [customDate, setCustomDate] = useState<string>()
    const [selectedDate, setSelectedDate] = useState<'today' | 'tomorrow' | 'custom' | ''>('');
    const onChange = (type: 'today' | 'tomorrow' | 'custom', value: string = '') => {
        setSelectedDate(type === selectedDate ? '' : type);
        let date = moment().toISOString();
        if (type === 'tomorrow')
            date = moment().add('day', 1).toISOString();
        if (type === 'custom') {
            date = value;
            setCustomDate(value || undefined);
        }
        props.onChange('date', date, type === selectedDate)

    }
    useEffect(() => {
        if (props.clear) {
            setSelectedDate('');
        }
    }, [props.clear])

    return (
        <Box className={classes.wrapper}>
            <Box mr={1} className={selectedDate === 'today' ? classes.border : ''}>  <Button className={classes.button} variant='contained' color='default' onClick={() => onChange('today')}>
                <Typo weight='bold' variant='overline'>Today</Typo></Button></Box>
            <Box mr={1} className={selectedDate === 'tomorrow' ? classes.border : ''}>  <Button className={classes.button} variant='contained' color='default' onClick={() => onChange('tomorrow')}>
                <Typo weight='bold' variant='overline'>Tomorrow</Typo></Button></Box>
            <Box className={selectedDate === 'custom' ? classes.border : ''}>
                <DateInput
                    label={''}
                    onChange={v => onChange('custom', v ? moment.utc(v).format() : undefined)}
                    value={customDate ? moment(customDate) : undefined}
                    utc
                />
            </Box>
        </Box>
    )
}

export const filterItemStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap'
        },
        button: {
            backgroundColor: '#e5e5e5'
        },
        border: {
            border: `1px solid black`,
            borderRadius: 4
        }
    }))
})

export default DateFilters