import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GoogleLocationSuggest from 'Components/Inputs/GoogleLocationSuggest';
import { Address } from 'Models/Address/@types';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import GoogleUtils from 'Resources/google';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';

export interface EventLocationSuggestProps extends IFieldProps {}

const EventLocationSuggest: FC<EventLocationSuggestProps> = (props) => {
	const classes = useStyles();
	const { formikProps, fieldConfig } = props;
	const handleResultClick = async (result: any) => {
		let res = await GoogleUtils.placeDetails(result.place_id);
		const placeDetails = GoogleUtils.transformAddress(res);
		formikProps?.setFieldValue('address', {
			address1: placeDetails?.address1,
			city: placeDetails?.city,
			country: placeDetails?.country,
			fullAddress: placeDetails?.full_address,
			locality: placeDetails?.locality,
			placeId: placeDetails?.placeid,
			state: placeDetails?.state,
			zipCode: placeDetails?.zipcode,
			url: res.url,
		} as Address);
		formikProps?.setFieldValue('placeId', placeDetails?.placeid);
		formikProps?.setFieldValue('location', { lat: res.geometry?.location?.lat?.(), lng: res.geometry?.location?.lng?.() });
	};

	// @ts-ignore
	const label = fieldConfig?.fieldProps?.label;

	return (
		<>
			{label ? <PGInputLabel>{label}</PGInputLabel> : null}
			<GoogleLocationSuggest onResultClick={handleResultClick} suggestionsTypes={['establishment']} className={classes.root} />
		</>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	const {
		palette: { grey },
	} = theme;
	return createStyles({
		root: {
			backgroundColor: grey['500'],
		},
	});
});

export default EventLocationSuggest;
