import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchBar from 'Components/Inputs/SearchBar';
import Loader from 'Components/Loader';
import Pagination from 'Components/Pagination';
import { usePagination } from 'Components/Pagination/usePagination';
import DashboardActionButtonsList from 'Features/Dashboard/DashboardActionButtonsList';
import PlayListCard from 'Features/PlayList/PlayListCard';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { useSearch } from 'Hooks/useSearch';
import useToastMessage from 'Hooks/useToastMessage';
import PlayListModel from 'Models/Playlist';
import { Playlist } from 'Models/Playlist/@types';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import AxiosUtils from 'Resources/AxiosUtils';
import { useStoreActions, useStoreState } from 'Stores';
import RoleUtils from 'Resources/RoleUtils';
import { parseToPlayListType } from 'Models/Playlist/PlayListParser';

export interface PlaylistsListProps extends RouteComponentProps { }

const PlaylistsList: FC<PlaylistsListProps> = ({ history }) => {
    const classes = useStyles();

    const [selectedPlaylists, setSelectedPlaylists] = useState<string[]>([]);

    const { playList, user } = useStoreState(({ PlayList: { playList }, Auth: { user } }) => ({ playList, user }))
    const { fetchPlayList, deletePlaylist, toggleFavourite } = useStoreActions(({ PlayList: { fetchPlayList, deletePlaylist, toggleFavourite } }) => ({ fetchPlayList, deletePlaylist, toggleFavourite }))

    const deletePlaylistTask = useAsyncTask(deletePlaylist);
    const toggleFavouriteTask = useAsyncTask(toggleFavourite);

    const { handleChange, results: searchResults, searchTerm, setSearchResults } = useSearch<Playlist>(PlayListModel.searchPlaylist, { ...(RoleUtils.isAdmin(user) ? {} : { where: { userId: user?.id } }) }, parseToPlayListType);

    const fetchPlayListTask = useAsyncTask(fetchPlayList)
    const NUM_PLAYLISTS_TO_SHOW = 20

    const { nextPageClick, pageNum, prevPageClick } = usePagination();

    const withToast = useToastMessage();

    const withConfirmationDialog = useConfirmationDialog();

    const handleDelete = () => {
        if (selectedPlaylists.length)
            withConfirmationDialog(async () => {
                await deletePlaylistTask.run({ ids: selectedPlaylists }).then(() => deleteFromSearchResults(selectedPlaylists))
            },
                { message: `These ${selectedPlaylists.length} playlists will be deleted. Are you sure you want to continue?`, agreeText: 'Confirm' },
                { successToastMessage: "Playlists deleted successfully" });
    }

    const handleClick = (flag: boolean, playlist: Playlist) => {
        if (flag) {
            setSelectedPlaylists([...selectedPlaylists, playlist.id])
            return
        }
        setSelectedPlaylists(selectedPlaylists.filter(id => playlist.id !== id))
    }

    useEffect(() => {

        fetchPlayListTask.run({
            params: { filter: { limit: NUM_PLAYLISTS_TO_SHOW, skip: pageNum * NUM_PLAYLISTS_TO_SHOW, order: 'created DESC', ...(RoleUtils.isAdmin(user) ? {} : { where: { userId: user?.id } }) } }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum])

    const handleFavourite = () => {
        let publishedNonFavouritedPlaylistIds = (searchTerm ? searchResults : playList).filter(e => selectedPlaylists.includes(e.id)).filter(p => p.isPublished).filter(p => !p.isFavourited).map(p => p.id) || [];
        if (publishedNonFavouritedPlaylistIds.length) {
            withToast(async () => {
                await toggleFavouriteTask.run({ playlistIds: publishedNonFavouritedPlaylistIds, favourite: true }).catch(AxiosUtils.throwError);
                updateSearchResults(publishedNonFavouritedPlaylistIds, 'isFavourited', true);
            }, { successToastMessage: 'Playlists favourited successfully' })
        }
    }

    const updateSearchResults = (ids: string[], key: keyof Playlist, flag: boolean = true) => setSearchResults(PlayListModel.updatePlaylistFlag(key, searchResults, ids, flag));

    const deleteFromSearchResults = (ids: string[]) => setSearchResults(searchResults.filter(e => !ids.includes(e.id)));

    return (
        <>
            <div className={classes.actionsContainer} >
                <DashboardActionButtonsList config={
                    [
                        { action: 'filter' },
                        {
                            action: 'add',
                            label: 'Add Playlist',
                            onClick: () => history.push('/dashboard/playlists/create')
                        }
                    ]
                } align={'ltr'} />
                <SearchBar placeholder={'Search Playlist ...'} onChange={e => handleChange(e.target.value)} value={searchTerm} />
            </div>
            <div className={classes.actionsContainer} >
                <Pagination previous={prevPageClick} next={nextPageClick} />
                <DashboardActionButtonsList
                    config={[
                        { action: 'favourite', label: 'Favourites', onClick: handleFavourite, hidden: true },
                        { action: 'delete', label: 'Delete', onClick: handleDelete }
                    ]}
                    align={'rtl'}
                />
            </div>
            <Box mt={4} position={'relative'} >
                {
                    fetchPlayListTask.status === 'PROCESSING' ? <Loader /> :
                        (searchTerm ? searchResults : playList).map((playlist, index) => {
                            return (
                                <PlayListCard
                                    key={playlist.id}
                                    initialCheckedState={selectedPlaylists.includes(playlist.id)}
                                    playlist={playlist}
                                    onClick={flag => handleClick(flag, playlist)}
                                    afterDelete={() => deleteFromSearchResults([playlist.id])}
                                    afterToggleFavourite={(id, f) => updateSearchResults([id], 'isFavourited', f)}
                                    afterToggleFeatured={(id, f) => updateSearchResults([id], 'isFeatured', f)}
                                    afterTogglePublish={(id, f) => updateSearchResults([id], 'isPublished', f)}
                                />
                            )
                        })
                }
            </Box>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        actionsContainer: {
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-between'
        }
    }))
})

export default PlaylistsList