import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import Comm from 'Models/Comms';
import React, { FC } from 'react';
import { MLFormContent } from 'react-forms';
import { useStoreActions } from 'Stores';
import * as Yup from 'yup';

export interface SendMessageDialogProps {
	open: boolean;
	userIds: string[];
	sendToAll?: boolean;
}

const SendMessageDialog: FC<SendMessageDialogProps> = (props) => {
	const { open, userIds = [], sendToAll = false } = props;
	const { hideSendMessageDialog } = useStoreActions(({ User: { hideSendMessageDialog } }) => ({ hideSendMessageDialog }));

	const multipleUsers = userIds.length > 1 || sendToAll;

	const bulkMessageTask = useAsyncTask(Comm.bulkMessage);

	const withToast = useToastMessage();

	const handleClose = () => hideSendMessageDialog();

	const handleSubmit = (data: any) => {
		withToast(
			async () => {
				await bulkMessageTask.run(data).catch((err) => {
					throw err;
				});
				handleClose();
			},
			{ successToastMessage: `Message will be sent to ${sendToAll ? 'all' : userIds.length} user${multipleUsers ? 's' : ''}` }
		);
	};

	const sending = bulkMessageTask.status === 'PROCESSING';

	const classes = useStyles();
	return (
		<Formik
			validationSchema={Yup.object({ templateData: Yup.object({ text: Yup.string().required('Message is required') }) })}
			initialValues={{ userIds, templateData: { text: '', subject: '', message: '' }, sendToAll, channelTypes: ['sms'] }}
			onSubmit={handleSubmit}
		>
			{(formikProps) => {
				return (
					<Dialog classes={{ paper: classes.paper }} open={open} onClose={handleClose}>
						<DialogTitle>
							Send message to {sendToAll ? 'all' : userIds.length} user{multipleUsers ? 's' : ''}
						</DialogTitle>
						<DialogContent>
							<MLFormContent
								formikProps={formikProps}
								formId={'message'}
								schema={[
									{
										type: 'text',
										valueKey: 'templateData.text',
										fieldProps: {
											label: 'Text Message',
											multiline: true,
											variant: 'outlined',
											fullWidth: true,
										},
									},
								]}
							/>
						</DialogContent>
						<DialogActions>
							<Button disabled={sending} onClick={formikProps.submitForm} color={'primary'}>
								{sending ? 'SENDING...' : 'SEND'}
							</Button>
							<Button disabled={sending} color={'primary'} onClick={handleClose}>
								CANCEL
							</Button>
						</DialogActions>
					</Dialog>
				);
			}}
		</Formik>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		paper: {
			width: 480,
		},
		formContainer: {
			padding: 32,
			paddingTop: 40,
			width: 520,
		},
	});
});

export default SendMessageDialog;
