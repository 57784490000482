import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import LoaderImage from 'assets/images/loading_img.gif';

export interface LayoutWithLoaderProps {
    loading?: boolean;
}

const LayoutWithLoader: FC<LayoutWithLoaderProps> = ({ loading, children }) => {
    const classes = useStyles();
    return (
        loading ?
            <Box className={classes.root} >
                <img src={LoaderImage} height={128} width={128} alt={'loader'} />
            </Box>
            :
            <>{children}</>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0, right: 0, top: 0, bottom: 0,
            backgroundColor: '#fff',
            zIndex: 1600
        }
    }))
})

export default LayoutWithLoader