import React, { FC } from 'react';
import { Switch, Route } from 'react-router';
import UsersList from './UsersList';

export interface UsersProps { }

const Users: FC<UsersProps> = (props) => {

    const BASE_URL = '/dashboard/users'

    return (
        <Switch>
            <Route exact path={`${BASE_URL}`} component={UsersList} />
        </Switch>
    )
}

export default Users