import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useResetPasswordFormConfig } from './useResetPasswordFormConfig';
import Typo from 'Components/Typo';
import { ReactForm } from 'react-forms';
import useResetPassword from './ResetPasswordViewModel';
import { Link } from 'react-router-dom';

export interface ResetPasswordProps { }

const ResetPassword: FC<ResetPasswordProps> = () => {
    const classes = useStyles();

    const { actionConfig, inputConfig, validationSchema } = useResetPasswordFormConfig();

    const { onSubmit, submitting, query } = useResetPassword();

    return (
        <Box minHeight='100%' width='100%' className={classes.root}> {!query.token ? (
            <Box p={5} mx="auto" width="200px"><Typo color="error">Invalid link</Typo></Box>
        ) : (
                <Box className={classes.container}>
                    <Typo fontVariant="sharp" weight="medium" variant="h3" component="div" className={classes.loginHeading}>Reset password</Typo>
                    <ReactForm
                        formId='reset-password'
                        config={inputConfig}
                        actionConfig={actionConfig}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        formSettings={{
                            verticalSpacing: 20,
                        }}
                        isInProgress={submitting}

                    />
                    <Link to={'/'} >
                        <Typo fontVariant="semibold" className={classes.registerLink} variant="subtitle1" weight="regular">Login</Typo>
                    </Link>
                </Box>
            )
        }
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        loginHeading: {
            color: '#505448',
            textAlign: 'center'
        },
        registerLink: {
            textDecoration: 'none',
            color: theme.palette.grey["600"],
            marginTop: '16px',
            lineHeight: '22px',
            textAlign: 'center'
        },
    }))
})

export default ResetPassword