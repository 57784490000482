import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';

export interface CheckboxProps {
	isChecked?: boolean;
	onCheck?: () => void;
	label?: string;
	disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
	const { onCheck, label, disabled } = props;
	const classes = useStyles(props);
	return (
		<div className={classes.root} onClick={onCheck}>
			<div className={classes.checkbox} />
			{label ? (
				<PGInputLabel disabled={disabled} className={classes.label}>
					{label}
				</PGInputLabel>
			) : null}
		</div>
	);
};

const useStyles = makeStyles<Theme, CheckboxProps>((theme) => {
	return createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
		},
		checkbox: {
			height: 24,
			width: 24,
			border: '2px solid #4c5667',
			transition: '.2s ease-in-out',
			backgroundColor: ({ isChecked }) => (isChecked ? '#4c5667' : 'transparent'),
		},
		label: {
			marginLeft: 8,
			marginBottom: '4px !important',
			cursor: 'inherit',
		},
	});
});

export default Checkbox;
