import React, { FC, memo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import _isEqual from 'lodash/isEqual';

export interface SearchBarProps {
	placeholder?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	value?: string;
}

const SearchBar: FC<SearchBarProps> = (props) => {
	const { placeholder = 'Search', onChange, value } = props;
	const classes = useStyles();
	return (
		<div>
			<TextField
				variant="filled"
				placeholder={placeholder}
				onChange={onChange}
				value={value || ''}
				InputProps={{
					className: classes.search,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		search: {
			borderRadius: 5,
			height: 40,
			width: 370,
			'& input': {
				padding: '7px 12px',
			},
			'& input::placeholder': {
				color: '#757575',
			},
		},
	});
});

export default memo(SearchBar, ({ onChange, ...a }, { onChange: onChange1, ...b }) => _isEqual(a, b));
