import { Action, action, ActionOn, actionOn, thunk, Thunk } from "easy-peasy";
import { TParams } from "Models/App/@types";
import PlayListModel from "Models/Playlist";
import { Playlist } from "Models/Playlist/@types";
import { parseToPlayListType } from "Models/Playlist/PlayListParser";
import AxiosUtils from "Resources/AxiosUtils";
import utilities from "Resources/Utils";
import { TRootStore } from "Stores";
import EventModel from "Models/Event";

type ThunkAction<T = void, R = void> = Thunk<PlaylistState, T, any, TRootStore, Promise<R>>;
type StaticAction<T = void> = Action<PlaylistState, T>;
type ActionOnAction = ActionOn<PlaylistState, TRootStore>;

export interface PlaylistState {
	playList: Playlist[]
	fetchPlayList: ThunkAction<{ params?: TParams }, Playlist[]>
	setPlayList: StaticAction<{ playList: Playlist[] }>;

	deletePlaylist: ThunkAction<{ ids: string[] }>;

	playlistDetails?: Playlist;
	fetchSinglePlaylist: ThunkAction<{ id: string, params?: TParams }>;

	postPlaylist: ThunkAction<{ playlist: Partial<Playlist> }>;
	togglePublish: ThunkAction<{ id: string, publish: boolean }>;

	toggleFavourite: ThunkAction<{ playlistIds: string[], favourite: boolean }>;

	toggleFeatured: ThunkAction<{ data: Partial<Playlist> & Pick<Playlist, 'id' | 'isFeatured'> }>;

	onEventToggleLike: ActionOnAction;
	onEventToggleFeature: ActionOnAction;
	oonEventTogglePublish: ActionOnAction;

}

const PlayListState: PlaylistState = {
	playList: [],
	fetchPlayList: thunk(async (actions, { params }) => {
		const finalParams = params ? { filter: { ...params.filter, include: 'tags' } } : { filter: { include: 'tags' } };
		const res = await PlayListModel.getPlaylists(finalParams)
		const playlists: Playlist[] = [...res.map((playlist: Playlist) => parseToPlayListType(playlist))]
		actions.setPlayList({ playList: playlists })
		return playlists
	}),

	deletePlaylist: thunk(async (actions, { ids }, { getState }) => {
		const state = getState();
		PlayListModel.deletePlaylists(ids);
		state.playList = state.playList.filter(e => !ids.includes(e.id));
	}),

	setPlayList: action((state, { playList }) => {
		state.playList = playList;
		state.playlistDetails = undefined
	}),

	fetchSinglePlaylist: thunk(async (action, args, { getState }) => {
		const state = getState();
		const playlist = await PlayListModel.getSinglePlaylist(args.id, args.params).catch(AxiosUtils.throwError);
		state.playlistDetails = playlist;
	}),

	postPlaylist: thunk(async (actions, args, { getState }) => {
		const state = getState();
		const playlist = await PlayListModel.postPlaylist(args.playlist).catch(AxiosUtils.throwError);
		state.playlistDetails = playlist;
		state.playList = utilities.updateItemList([...state.playList], playlist, 'PUT');
	}),

	togglePublish: thunk(async (actions, args, { getState }) => {
		await PlayListModel.togglePublishPlaylist(args.id, args.publish).catch(AxiosUtils.throwError);
		const state = getState();
		if (state.playlistDetails?.id && state.playlistDetails?.id === args.id) {
			state.playlistDetails.isPublished = args.publish;
		}
		state.playList = PlayListModel.updatePlaylistFlag('isFeatured', [...state.playList], [args.id], args.publish);
	}),

	toggleFavourite: thunk(async (actions, args, { getState }) => {
		const state = getState();
		if (args.favourite) await PlayListModel.addToFavourites(args.playlistIds).catch(AxiosUtils.throwError);
		else await PlayListModel.removeFromFavourites(args.playlistIds).catch(AxiosUtils.throwError);
		state.playList = PlayListModel.updatePlaylistFlag('isFavourited', [...state.playList], args.playlistIds, args.favourite);
	}),

	toggleFeatured: thunk(async (actions, args) => await actions.postPlaylist({ playlist: args.data })),

	onEventToggleLike: actionOn(
		(actions, storeActions) => storeActions.Events.afterToggleFavourite,
		(state, target) => {
			if (state.playlistDetails?.events)
				state.playlistDetails.events = EventModel.updateEventsFlag('isFavourited', state.playlistDetails.events, target.payload.eventIds, target.payload.favourite)
		}
	),

	onEventToggleFeature: actionOn(
		(actions, stateActions) => stateActions.Events.eventUpdated,
		(state, target) => {
			if (state.playlistDetails?.events)
				state.playlistDetails.events = utilities.updateItemList([...(state.playlistDetails.events || [])], target.payload.event, 'PUT');
		}
	),


	oonEventTogglePublish: actionOn(
		(actions, stateActions) => stateActions.Events.afterTogglePublish,
		(state, target) => {
			if (state.playlistDetails?.events) {
				state.playlistDetails.events = EventModel.updateEventsFlag('isPublished', state.playlistDetails.events, [target.payload.id], target.payload.publish)
			}
		}
	)

}

export default PlayListState