import { Box, Typography, Fab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import useAsyncTask from 'Hooks/useAsyncTask';
import PictureModel from 'Models/Picture';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IFieldProps } from 'react-forms';
import { TFile, Picture } from 'Models/Picture/@types';
import DeleteIcon from '@material-ui/icons/Delete';
import PGInputLabel from './PGInputLabel';
import utilities from 'Resources/Utils';

export interface PGMainImageInputProps extends IFieldProps {
    fieldProps?: {
        name?: string;
    }
}

const PGMainImageInput: FC<PGMainImageInputProps> = (props) => {
    const classes = useStyles();
    const { formikProps, fieldProps, fieldConfig } = props;
    const valueKey = fieldConfig?.valueKey || fieldProps?.name || '';
    const image: Picture = formikProps?.values[valueKey];
    const uploadTask = useAsyncTask(PictureModel.upload);

    const [i, si] = useState<TFile | undefined>(image as any);

    const onDrop = (acceptedFiles: any[]) => {
        let reader = new FileReader();
        const file = acceptedFiles[0];
        reader.onload = event => {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: reader.result,
                file: file,
            };
            upload(fileInfo);
            si(fileInfo);
        }
        reader.readAsDataURL(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const upload = async (file: TFile) => {
        const res = await uploadTask.run(file, file.name, 'event');
        formikProps?.setFieldValue(valueKey, res);
    }

    const handleRemovePic = (e: any) => {
        e.stopPropagation();
        si(undefined);
        formikProps?.setFieldValue(valueKey, null);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const errorText = formikProps ? utilities.getFieldError('primaryImage', formikProps) : '';

    return (
        <Box className={classes.root} >
            {/* <Box width={800} >
                <Typography variant={'caption'} component={'label'}>Choose main image</Typography>
                <div
                    className={clsx(classes.input,
                        { [classes.activeDropzone]: isDragActive })}
                    {...getRootProps()}
                >
                    <button style={{ marginRight: 8 }} ><Typography className={classes.nativeButton} component={'span'} >Choose file</Typography></button>
                    <Typography variant='subtitle2' component={'span'} >{i?.name || 'No file chosen'}</Typography>
                </div>
                <input
                    accept="image/*"
                    {...getInputProps()}
                    multiple={false}
                />
            </Box> */}
            <PGInputLabel>Main Image</PGInputLabel>
            {(errorText) ? <Typography variant={'caption'} color={errorText ? 'error' : undefined} >{errorText}</Typography> : null}
            <Box
                position={'relative'}
                width={450}
                className={clsx({ [classes.activeDropzone]: isDragActive })}
                {...getRootProps()}
            >
                <input
                    {...getInputProps()}
                    accept="image/*"
                    multiple={true}
                />
                {
                    i
                        ?
                        <div className={classes.preview} >
                            {uploadTask.status === 'PROCESSING' ? <div className={clsx(classes.loader, 'breathe-loader')} /> : null}
                            {
                                image?.url ?
                                    <img src={image.url} className={classes.previewImg} alt={i.name} />
                                    :
                                    <>
                                        <img src={i?.base64 as string} className={classes.previewImg} alt={i.name} />
                                    </>
                            }
                        </div>
                        :
                        <div className={classes.emptyPreview}>
                            <Typography variant='subtitle2' component={'span'} >{'Drop file here to upload'}</Typography>
                        </div>
                }
                {
                    image?.url
                        ?
                        <>
                            <Fab className={classes.delBtn} size='small' onClick={handleRemovePic} >
                                <DeleteIcon fontSize={'small'} />
                            </Fab>
                            {/* <Typography variant={'caption'} color={'textSecondary'} component={'div'} >{image.name}</Typography> */}
                        </>
                        : null
                }
            </Box>
        </Box >
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey }, typography: { subtitle2 } } = theme;
    return (createStyles({
        root: {
        },
        input: {
            padding: '7px 12px',
            borderRadius: 4,
            backgroundColor: grey['500'],
            marginTop: 8,
            ...subtitle2,
            color: grey['900']

        },
        activeDropzone: {
            color: grey['800']
        },
        emptyPreview: {
            width: '100%',
            minHeight: 150,
            // border: `1px Solid ${grey['900']}`,
            marginTop: 8,
            borderRadius: 3,
            backgroundColor: grey['500'],
            '& span': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: "translate(-50%, -50%)"
            }
        },
        loader: {
            position: 'absolute',
            left: '20%', right: '20%',
            top: 'calc(50% - 12px)',
            height: 24,
            borderRadius: 12,
            backgroundColor: '#fff'
        },
        preview: {
            minHeight: 100,
            height: 'auto',
            width: '100%',
            borderRadius: '100%',
            padding: 10,
            position: "relative",
            marginTop: 8,
        },
        previewImg: {
            width: '100%',
            height: 'auto',
            borderRadius: 12
        },
        nativeButton: {
            lineHeight: 1,
        },
        delBtn: {
            position: 'absolute',
            right: '22px',
            bottom: '22px'
        }
    }))
})

export default PGMainImageInput