import { FormControl, InputBase, InputBaseProps, InputLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export interface TextInputProps extends InputBaseProps {
    label?: string;
    subLabel?: string;
    helperText?: string;
    errorText?: string;
    charLimit?: number;
    options?: string[];
    warning?: boolean;
}

const TextInput = (props: TextInputProps) => {
    const classes = useStyles();
    const { label = '', errorText = '', error, helperText = '', onChange, charLimit = 500, options = [], warning = false, subLabel, ...inputBaseProps } = props;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.target.value = e.target.value.slice(0, charLimit);
        onChange?.(e);
    }

    return (
        <FormControl fullWidth className={classes.root} >
            {
                label ?
                    <InputLabel shrink={true} >
                        <Typography color={'textPrimary'} variant={'caption'} component={'div'} >{label}</Typography>
                        <Typography color={'primary'} variant={'overline'} >{subLabel}</Typography>
                    </InputLabel>
                    :
                    null
            }
            {errorText ? <Typography variant={'overline'} color={error ? 'error' : undefined} >{errorText}</Typography> : null}
            <Autocomplete
                id={inputBaseProps.name}
                freeSolo
                options={options}
                onInputChange={(e, v) => { e && handleChange({ ...e, ...{ target: { value: v, name: inputBaseProps.name, ...e.target } } } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) }}
                value={inputBaseProps.value || ''}
                renderInput={(params) => (
                    <InputBase fullWidth ref={params.InputProps.ref} error={error} placeholder={label} classes={{ input: classes.input }} inputProps={params.inputProps} {...inputBaseProps} />
                )}
            />
            {helperText ? <Typography color={warning ? 'primary' : undefined} variant={'overline'} >{helperText}</Typography> : null}

        </FormControl >
    )
}


const useStyles = makeStyles<Theme>((theme) => {
    const { palette: { grey } } = theme;
    return (createStyles({
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& label': {
                position: 'static',
                transform: 'unset',
                marginBottom: 12,
                marginLeft: 4
            }
        },
        input: {
            backgroundColor: grey['500'],
            border: `1px Solid ${grey['500']}`,
            height: 24,
            padding: '7px 12px',
            color: grey['700']
        }
    }))
})

export default TextInput