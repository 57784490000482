import { useState, useCallback } from "react"
import AxiosUtils from "Resources/AxiosUtils";
import { SearchResponse } from "Typings/Global";
import utilities from "Resources/Utils";
import { TFilter } from "Models/App/@types";

export const useSearch = <T>(fetchData: (term: string, filter?: TFilter) => Promise<SearchResponse<T>>, filter?: TFilter, parser: (item: any) => T = _ => _) => {

    const [searchTerm, setSearchTerm] = useState('');

    const [results, setSearchResults] = useState<T[]>([]);

    const handleChange = useCallback((() => {
        let timeout: NodeJS.Timeout
        return (term: string) => {
            if (!term) {
                setSearchResults([])
                setSearchTerm('');
            }
            setSearchTerm(term);
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(async () => {
                const data = await fetchData(term, filter).catch(AxiosUtils.throwError);
                setSearchResults(data.results.map(r => parser(r.hit)));
            }, 300)
        }
    })(), []);

    const updateItem = (item: T) => {
        const list = [...results];
        setSearchResults(utilities.updateItemList(list, item, 'UPDATE'));
    }

    return {
        handleChange,
        searchTerm,
        results,
        updateItem,
        setSearchResults
    }

}