import { Box, Switch } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { filterItemStyles } from './DateFilters';
import { TFilterType } from './EventFilter';

export interface OngoingProps {
    onChange: (type: TFilterType, value: any) => void
    clear?: boolean
}

const RecurringFilter: FC<OngoingProps> = (props) => {
    const classes = filterItemStyles();
    const { onChange, clear } = props;
    const [selected, setSelected] = useState<boolean>(true);
    const handleChange = () => {
        setSelected(!selected);
        onChange('recurring', !selected)
    }
    useEffect(() => {
        if (clear) {
            setSelected(true);
        }
    }, [clear])
    return (
        <Box className={classes.wrapper}>
            <Switch
                checked={selected}
                onChange={handleChange}
                color='primary'
            // style={{
            //     color: '#fff',
            //     //backgroundColor: selected ? '#2196F3' : 'rgba(0, 0, 0, 0.04)'
            // }}
            />
        </Box>
    )
}


export default RecurringFilter