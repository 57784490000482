import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import PlaylistForm from './PlaylistForm';
import PlaylistsList from './PlaylistsList';
import PlaylistDetails from './PlaylistDetails';

export interface PlaylistsProps { }

const Playlists: FC<PlaylistsProps> = (props) => {

    const BASE_URL = '/dashboard/playlists'

    return (
        <Switch>
            <Route exact path={`${BASE_URL}`} component={PlaylistsList} />
            <Route exact path={`${BASE_URL}/create`} component={PlaylistForm} />
            <Route exact path={`${BASE_URL}/:id`} component={PlaylistDetails} />
            <Route exact path={`${BASE_URL}/update/:id`} component={PlaylistForm} />
        </Switch>
    )
}


export default Playlists