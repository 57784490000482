import React from 'react'
import { Theme, TypographyProps, Typography, Link } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export interface TypoProps extends TypographyProps {
	weight?: 'regular' | 'medium' | 'bold' | number
	underline?: boolean
	fontVariant?: 'bold' | 'semibold' | 'regular' | 'black' | 'helvetica' | 'sharp'
	component?: React.ElementType<any>
	// whiteSpace?: React.CSSProperties['whiteSpace']
}


const getFontWeight = (theme: Theme, weight: TypoProps['weight']) => {
	if (typeof weight === 'number') return weight
	if (weight === 'bold') return theme.typography.fontWeightBold
	if (weight === 'medium') return theme.typography.fontWeightMedium
	if (weight === 'regular') return theme.typography.fontWeightRegular
	return theme.typography.fontWeightRegular
}
const getFont = (theme: Theme, fontVariant: TypoProps['fontVariant']) => {
	if (fontVariant === 'bold') return "NeuePlak-ExtendedBold"
	if (fontVariant === 'semibold') return "NeuePlak-ExtendedSemibold"
	if (fontVariant === 'black') return "NeuePlak-ExtendedBlack"
	if (fontVariant === 'helvetica') return "Helvetica"
	if (fontVariant === 'regular') return 'NeuePlak-Regular'
	if (fontVariant === 'sharp') return "sharp"
	return "NeuePlak-ExtendedSemiBold"
}
const Typo: React.FC<TypoProps> = (props) => {
	const { children, weight = 'regular', component = 'div', fontVariant = 'regular', /* whiteSpace, */  underline = false, ...typographyProps } = props;
	const theme = useTheme()
	const style = { ...typographyProps.style, textDecoration: underline ? 'underline' : undefined, fontWeight: getFontWeight(theme, weight)/* , whiteSpace */, fontFamily: getFont(theme, fontVariant) }

	if (typeof props.children === 'string' && isUrl(props.children)) {
		return <Link rel="noopener" href={props.children} target="_blank" {...typographyProps} style={{ ...style, cursor: 'pointer' }}>{props.children}</Link>
	}

	return (
		<Typography style={{ ...style }} {...typographyProps} component={component}  >{children}</Typography>
	)
}


const isUrl = (text: string) => {
	return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ig.test(text)
}

export default Typo