import { request } from "Resources/AxiosUtils";
import { parseTwitterAutocomplete } from "./twitterParser";

class TwitterModel {
    static searchUsers = async (term: string) => {
        const res = await request<any[]>({ url: `twitters/search-users`, params: { query: term } })
        return res.map(parseTwitterAutocomplete);
    }
}

export default TwitterModel;