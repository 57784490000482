import { TParams } from "Models/App/@types";
import AxiosUtils, { request } from "Resources/AxiosUtils";
import { JSONType, SearchResponse } from "Typings/Global";
import { Playlist } from "./@types";
import { parseToPlayListType, parsePlaylistFormData } from "./PlayListParser";

class PlayListModel {
    static getPlaylists = async (params?: TParams) => {
        const res = await request<JSONType>({
            url: '/Playlists',
            method: 'GET',
            params
        })
        return res
    }

    static getSinglePlaylist = async (id: string, params?: TParams) => {
        const res = await request<Playlist>({
            url: `/playlists/${id}`,
            params
        }).catch(AxiosUtils.throwError);
        return parseToPlayListType(res);
    }

    static deletePlaylists = async (ids: string[]) => {

        const res = await request({
            url: '/playlists/trash',
            method: 'DELETE',
            data: { ids: ids }
        })
        return res
    }

    static postPlaylist = async (data: Partial<Playlist>): Promise<Playlist> => {
        if (data.id) {
            return await PlayListModel.updatePlaylist({ ...data, id: data.id }).catch(AxiosUtils.throwError);
        }

        const res = await request({
            url: `/playlists`,
            method: 'post',
            data: { ...parsePlaylistFormData(data) }
        }).catch(AxiosUtils.throwError);
        return { ...data, ...parseToPlayListType(res) } as Playlist;
    }

    static updatePlaylist = async (data: (Partial<Playlist> & { id: string })): Promise<Playlist> => {
        const res = await request({
            url: `/playlists/${data.id}`,
            method: 'patch',
            data: { ...parsePlaylistFormData(data) }
        }).catch(AxiosUtils.throwError);
        return { ...data, ...parseToPlayListType(res) } as Playlist;
    }

    static searchPlaylist = (term: string) => request<SearchResponse<Playlist>>({ url: `/playlists/search`, params: { term, filter: { include: ['tags'] } } });

    static togglePublishPlaylist = (id: string, publish: boolean) => request<JSONType>({ url: `/playlists/${id}/publish`, method: 'post', params: { publish } });

    static addToFavourites = (playlistIds: string[]) => request({ url: `/playlists/addToFavourites`, method: 'post', params: { playlistIds } });

    static removeFromFavourites = (playlistIds: string[]) => request({ url: `/playlists/removeFromFavourites`, method: 'delete', params: { playlistIds } });

    static updatePlaylistFlag = (key: keyof Playlist, playlist: Playlist[] = [], ids: string[] = [], flag: boolean = true) => [...(playlist || [])].map(e => ids.includes(e.id) ? ({ ...e, [key]: flag }) : e);

}

export default PlayListModel;