import { Address } from "./@types"

export const parseAddress = (res: any) => {
    const address: Address = {
        ...res,
    }
    return address;
}

export const parseAddressFormData = (address: Address) => {
    const formData = {
        ...address
    }
    return formData;
}