import { useStoreState, useStoreActions } from "Stores";
import useAsyncTask from "Hooks/useAsyncTask";
import { useEffect } from "react";
import { TEvent } from "Models/Event/@types";

export const useEventDetails = (eventId?: string) => {
    const { eventDetails = {} as TEvent } = useStoreState(({ Events: { eventDetails } }) => ({ eventDetails }));
    const { fetchSingleEvent } = useStoreActions(({ Events: { fetchSingleEvent } }) => ({ fetchSingleEvent }))
    const fetchEvent = useAsyncTask(fetchSingleEvent)
    useEffect(() => {
        if (eventDetails && eventDetails.id === eventId) return;
        if (eventId)
            fetchEvent.run(eventId)
        // eslint-disable-next-line
    }, [eventId]);

    return {
        eventDetails,
        loadingEvent: fetchEvent.status === 'PROCESSING'
    }

}