import Checkbox, { CheckboxProps } from 'Components/Inputs/Checkbox';
import _get from 'lodash/get';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';

export interface PGCheckboxProps extends IFieldProps {
	fieldProps?: CheckboxProps;
}

const PGCheckbox: FC<PGCheckboxProps> = (props) => {
	const { fieldProps = {}, formikProps, fieldConfig } = props;
	const value = _get(formikProps?.values, `${fieldConfig?.valueKey}`);
	const updatedProps: CheckboxProps = {
		...fieldProps,
		onCheck: () => formikProps?.setFieldValue(fieldConfig?.valueKey!, !value),
		isChecked: value,
	};
	return <Checkbox {...updatedProps} />;
};

export default PGCheckbox;
