import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TFilter } from 'Models/App/@types';
import React, { FC, useEffect, useState } from 'react';
import { useStoreState } from 'Stores';
import UserRoleFilter from './UserRoleFilter';

export type UserFilterType = 'role' | 'clear';

export interface UsersFilterProps {
    handleFilterChange: (filter: TFilter) => void
}



const UsersFilter: FC<UsersFilterProps> = (props) => {
    const classes = useStyles();

    const [clear, setClear] = useState<boolean>(false);

    const [filter, setFilter] = useState<TFilter['where']>({});

    const { appConstants } = useStoreState(({ App: { appConstants } }) => ({ appConstants }));


    const onChange = (type: UserFilterType, value: any, toggle: boolean) => {
        let where: TFilter['where'] = { ...filter };
        let not_match = {};
        switch (type) {
            case 'clear':
                where = {};
                break;
            case 'role':
                if (!where.roles) where.roles = [];

                if (toggle) {
                    delete where.roles;
                }
                else if (value === 'USER') {
                    delete where.roles;
                    not_match = { roles: appConstants?.Roles.map(r => r.value) }
                }
                else {
                    where.roles = [value]
                }
                break;
        }

        setFilter(where);
        props.handleFilterChange({
            where,
            ...(value === 'USER' ? { not_match } : {})
        })
    }

    useEffect(() => {
        if (clear)
            setClear(false);
    }, [clear])

    return (
        <Box className={classes.root} >
            <Paper elevation={4} className={classes.paper} >
                <Box position='absolute' top={'5px'} right='5px' >
                    <Button className={classes.button} disableFocusRipple disableRipple color='secondary' variant='text' onClick={() => { onChange('clear', '', false); setClear(true); }}>Clear Filters</Button>
                </Box>
                <Box mb={1}><Typography variant='h6'>{'User types'}</Typography></Box>
                <UserRoleFilter onChange={onChange} clear={clear} />
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: '100%',
            // height: 540,
        },
        paper: {
            height: '100%',
            width: '100%',
            padding: 16,
            position: 'relative'
        }
    }))
})

export default UsersFilter