import DateTimePicker, { DateTimePickerProps } from 'Components/Inputs/DateTimePicker';
import _ from 'lodash';
import React, { FC, memo } from 'react';
import { IFieldProps } from 'react-forms';

export interface DateTimePickerGroupProps extends IFieldProps {
    fieldProps?: {
        offset?: string;
        timezone?: string;
        dateTimePickerProps?: DateTimePickerProps;
    }
}


const DateTimePickerGroup: FC<DateTimePickerGroupProps> = (props) => {

    const { fieldConfig, formikProps, fieldProps = {} } = props;

    const { offset, timezone, dateTimePickerProps = {} } = fieldProps;

    const valueKey = fieldConfig?.valueKey || '';

    const startValueKey = valueKey ? valueKey + '.startDate' : 'startDate';
    const endValueKey = valueKey ? valueKey + '.endDate' : 'endDate';


    const startTimeDate = _.get(formikProps?.values, startValueKey);
    const endTimeDate = _.get(formikProps?.values, endValueKey);


    const handleChange = (value: DateTimePickerProps['value']) => {
        formikProps?.setFieldValue(startValueKey, value?.startDate);
        formikProps?.setFieldValue(endValueKey, value?.endDate);
    }


    return (
        <DateTimePicker
            value={{
                startDate: startTimeDate,
                endDate: endTimeDate
            }}
            onChange={handleChange}
            offset={offset}
            timezone={timezone}
            {...dateTimePickerProps}
        />
    )
}

export default memo(DateTimePickerGroup)