import SelectInput from 'Components/Inputs/SelectInput';
import _ from 'lodash';
import { TEvent } from 'Models/Event/@types';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import { timeZones } from 'Resources/TimeZone';
import DateTimeUtils from 'Resources/DateTimeUtils';

export interface TimezoneInputProps extends IFieldProps {
    onTimezoneChange?: (timezone: TEvent['timeZone']) => void;
    value?: string;
    errorText?: string;
}

const TimezoneInput: FC<TimezoneInputProps> = (props) => {

    const { formikProps, fieldConfig, onTimezoneChange, value, errorText = '' } = props;

    const valueKey = fieldConfig?.valueKey || '';

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const value = event.target.value;
        const data = timeZones.find(tz => `${tz.abbreviation}-${tz.timezone}` === value);
        if (data) {
            if (onTimezoneChange) {
                onTimezoneChange({ name: data.timezone, code: data.abbreviation, offset: data.offset });
            }
            else formikProps?.setFieldValue(valueKey, { name: data.timezone, code: data.abbreviation, offset: data.offset });
        }
    }

    const tzValue = value || `${_.get(formikProps?.values, `${valueKey}.code`)}-${_.get(formikProps?.values, `${valueKey}.name`)}` || '';

    return (
        <SelectInput
            label={'Time Zone'}
            options={[{ label: "Select timezone", value: '' }, ...timeZones.map((tz) => ({ label: `${tz.abbreviation} ${tz.country}/UTC ${DateTimeUtils.getOffsetFromTimezone(tz.timezone)}`, value: `${tz.abbreviation}-${tz.timezone}` || '' }))]}
            value={tzValue}
            onChange={handleChange}
            error={!!errorText}
            errorText={errorText}
        />
    )
}

export default TimezoneInput