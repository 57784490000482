import { TFilter } from "Models/App/@types";
import { request } from "Resources/AxiosUtils";
import { SearchResponse } from "Typings/Global";
import { Tag } from "./@types";
import { parseTag } from "./tagParsers";

class TagModel {
    static getTagsList = async (term?: string, filter?: TFilter) => {
        const res = await request<SearchResponse<Tag>>({
            url: '/tags/search',
            params: {
                term,
                filter
            }
        });
        res.results = res.results.map((r) => ({ hit: parseTag(r.hit) }));
        return res;
    }
}

export default TagModel;