import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import DateInput, { DateInputProps } from './DateInput';
import TimeInput from './TimeInput';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

export interface DateTimePickerProps {
    timeFormat?: string;
    dateFormat?: string;
    value?: {
        startDate?: string;
        endDate?: string;
    },
    onChange?: (value: { startDate?: string, endDate?: string }) => void;
    label?: string;
    offset?: string;
    timezone?: string;
    dateInputProps?: DateInputProps;
}

const DateTimePicker: FC<DateTimePickerProps> = (props) => {

    const classes = useStyles();
    const { dateFormat = DATE_FORMAT, timeFormat = TIME_FORMAT, value, onChange, label = 'Date', timezone = 'GMT', dateInputProps = {} } = props;

    const startDate = moment.utc(value?.startDate).tz(timezone);
    const endDate = moment.utc(value?.endDate).tz(timezone);

    // this startTime,endTime and date are not in UTC. It is in the current timezone.
    const [startTime, setStartTime] = useState<string>((startDate ?? moment()).format(timeFormat));
    const [endTime, setEndTime] = useState<string>((endDate ?? moment()).format(timeFormat));
    const [date, setDate] = useState<string>(startDate.format(dateFormat));

    useEffect(() => {
        const startDate = moment.tz(`${date} ${startTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`, timezone).utc().format();
        const endDate = moment.tz(`${date} ${endTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`, timezone).utc().format();

        onChange?.({ startDate, endDate });

        // eslint-disable-next-line
    }, [startTime, endTime, date, timezone]);

    return (
        <div className={classes.root} >
            <div className={classes.col} >
                <DateInput
                    label={label}
                    onChange={(v: any) => { setDate(moment.utc(v).format(dateFormat)) }}
                    utc
                    value={date}
                    dateFormat={DATE_FORMAT}
                    {...dateInputProps}
                />
            </div>
            <div className={classes.col} >
                <TimeInput
                    label={'Start Time'}
                    onChange={v => { setStartTime(v as string) }}
                    value={startTime}
                    format={timeFormat}
                />
            </div>
            <div className={classes.col} >
                <TimeInput
                    label={'End Time'}
                    onChange={v => setEndTime(v as string)}
                    value={endTime}
                    format={timeFormat}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex'
        },
        col: {
            flex: 1,
            maxWidth: '100%',
            paddingRight: 24
        }
    }))
})

export default DateTimePicker