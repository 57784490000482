import { request } from "Resources/AxiosUtils";
import { AppConstants, Summary } from "./@types";

class AppModel {
    static fetchAppConstants = async () => await request<AppConstants>({ url: 'AppModels/constants', });

    static fetchSummary = () => request<Summary>({ url: 'AppModels/summary' });

}

export default AppModel;