import PermissionAccess from 'Features/Authentication/PermissionAccess';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../Screens/Dashboard';
import Landing from '../Screens/Landing/Landing';
import PageNotFound from '../Screens/PageNotFound';
const AppNavigation: FC = () => {
    return (
        <Switch>
            <Route exact path='/' component={Landing} />
            <Route exact path='/forgot-password' component={Landing} />
            <Route exact path='/reset-password' component={Landing} />
            <PermissionAccess roles={['ADMIN', 'COMMUNITY', 'CURATOR']} ><Route path='/dashboard' component={Dashboard} /></PermissionAccess>
            <Route path='/*' exact component={PageNotFound} />
        </Switch>
    )
}

export default AppNavigation;