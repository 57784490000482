import { Box, Button } from '@material-ui/core';
import Typo from 'Components/Typo';
import { Tag } from 'Models/Tags/@types';
import React, { FC, useEffect, useState } from 'react';
import { useStoreState } from 'Stores';
import { filterItemStyles } from './DateFilters';
import { TFilterType } from './EventFilter';

export interface TagFIltersProps {
    onChange: (type: TFilterType, value: any, toggle: boolean) => void
    clear?: boolean
}

const TagFIlters: FC<TagFIltersProps> = (props) => {
    const classes = filterItemStyles();
    const { clear } = props;
    const { tagList = { list: [] as Tag[] } } = useStoreState(({ Tag: { tagList } }) => ({ tagList }));
    const [selected, setSelected] = useState<string>()
    const tagItems = tagList.list;
    const handleChange = (item: string) => {
        setSelected(selected === item ? '' : item)
        props.onChange('tag', item, selected === item);
    }
    useEffect(() => {
        if (clear) {
            setSelected('');
        }
    }, [clear])
    if (!tagItems.length)
        return <div />
    return (
        <Box className={classes.wrapper}>
            {
                tagItems.map((it, i) => {
                    return <Box key={i} mr={1}> <Button variant='contained'
                        style={{
                            background: it.background,
                            color: it.color,
                            border: selected === it.id ? `1px solid ${it.color}` : 'none'
                        }}
                        onClick={() => handleChange(it.id)}
                    >
                        <Typo weight='bold' variant='overline'>{it.name}</Typo>

                    </Button></Box>
                })
            }
        </Box>
    )
}


export default TagFIlters