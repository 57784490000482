import App from './App';
import Auth from './Auth';
import User from './User';
import Events from './Event';
import PlayList from './Playlist';
import { createTypedHooks, createStore, State } from 'easy-peasy';
import TagState from './Tag';
import EventModel from 'Models/Event';
import _isEqual from 'lodash/isEqual';

export interface Injections {
	EventModel: typeof EventModel;
}
const RootStore = {
	App,
	Auth,
	User,
	Events,
	PlayList,
	Tag: TagState,
};

export type TRootStore = typeof RootStore;
const typedHooks = createTypedHooks<TRootStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = <Result,>(a: (state: State<TRootStore>) => Result, b?: any) => typedHooks.useStoreState(a, _isEqual);

export default createStore(RootStore, {
	injections: {
		EventModel,
	},
});
