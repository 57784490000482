import useBoot from 'Boot';
import LayoutWithLoader from 'Components/LayoutWithLoader';
import config from 'config';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import AppNavigation from 'Router/AppNavigation';
import { useStoreState } from 'Stores';
import { usePermission } from 'Features/Authentication/usePermission';

const RootLayout: FC = () => {

    const { isCompleted } = useBoot();
    const user = useStoreState(state => state.Auth.user);
    const history = useHistory();
    const { isPermitted } = usePermission(['ADMIN', 'COMMUNITY', 'CURATOR']);

    useEffect(() => {

        const urlPath = history.location.pathname;
        if (user && user.id && urlPath !== '/' && urlPath !== '' && isPermitted)
            return;
        if (user && user.id && isPermitted) {
            history.push(config.get('POST_LOGIN_REDIRECT'));
        }
        else if (urlPath === '/forgot-password' || urlPath === '/reset-password') {
            return;
        }
        else if (isCompleted) {
            history.push(config.get('UNAUTHENTICATED_REDIRECT'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isCompleted, isPermitted]);

    return (
        <LayoutWithLoader loading={!isCompleted} ><AppNavigation /></LayoutWithLoader>

    )
};


export default RootLayout;