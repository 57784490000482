import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typo from 'Components/Typo';
import PGTextInput from 'Features/ReactFormsInputs/PGTextInput';
import React, { FC } from 'react';
import { attachField, FormConfig, IFormActionProps, ReactForm } from 'react-forms';
import * as Yup from 'yup';

// @ts-ignore
attachField('textInput', <PGTextInput />)

const formValidation = Yup.object({
	email: Yup.string().email('Invalid Email').required('Email is required'),
	password: Yup.string().required('Password is required')
})

export interface LoginFormProps {
	email: string
	password: string
}

interface IProps {
	onSubmit: Function,
	isSubmitting?: boolean
}
// const formFieldStyles = { border: '1px solid #E3E3E3', background: 'white', borderRadius: 4, color: '#565656', height: 38, '::before':{border: 'none'} }
const CONFIG: Array<Array<FormConfig> | FormConfig> = [{
	type: 'textInput',
	valueKey: 'email',
	fieldProps: {
		placeholder: 'Email',
		fullWidth: true,
		variant: 'outlined'
	},
}, {
	type: 'textInput',
	valueKey: 'password',
	fieldProps: {
		placeholder: 'Password',
		fullWidth: true,
		variant: 'outlined',
		type: 'password'
	},

},];

const useFormActionConfig = () => {
	const classes = useStyles();
	const config: IFormActionProps = {
		submitButtonText: ('SIGN IN'),
		submitButtonLayout: 'fullWidth',
		submitButtonProps: {
			size: 'large',
			className: classes.submitButton
		},
		containerClassNames: classes.buttonContainer,

	};
	return config;
}

export const LoginForm: FC<IProps> = (props) => {
	const classes = useStyles();
	const formActionConfig = useFormActionConfig();
	return (<div className={classes.root} >
		<Typo fontVariant="sharp" weight="medium" variant="h3" component="div" className={classes.loginHeading}>Sign in here</Typo>
		<ReactForm formId="login-form"
			config={CONFIG}
			actionConfig={formActionConfig}
			onSubmit={props.onSubmit}
			validationSchema={formValidation}
			formSettings={{
				verticalSpacing: 20,
				horizontalSpacing: 2
			}}
			isInProgress={props.isSubmitting}
		/>
	</div>
	);
}

export default LoginForm;

const useStyles = makeStyles<Theme>((theme) => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& > div': { marginBottom: 20 },
		'& > form': { width: '100%' }
	},
	buttonContainer: {
		marginTop: 40,
	},
	submitButton: {
		backgroundColor: theme.palette.grey["700"],
		height: 32,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: 14,
		fontFamily: 'NeuePlak-ExtendedSemiBold',
		'&:hover': {
			backgroundColor: theme.palette.grey["700"] + ' !important',
		}
	},
	loginHeading: {
		color: '#505448',
	},
}))