import { TwitterAutocompleteResult } from "./@types";

/**
 * 
 * @param res {
    "name": "Siraj Alam",
    "screen_name": "sirajalam049",
    "profile_image_url_https": "https://pbs.twimg.com/profile_images/1277560566697975808/1RSlPBac_normal.jpg"
  }
 */
export const parseTwitterAutocomplete = (res: any): TwitterAutocompleteResult => {
    const { name, screen_name, profile_image_url_https } = res;
    return {
        name,
        handle: screen_name,
        profileImage: profile_image_url_https
    }
}