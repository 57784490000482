import { Typography } from '@material-ui/core';
import EntityCard from 'Components/Card/EntityCard';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import _ from 'lodash';
import { getName } from 'Models/User';
import { User, Role } from 'Models/User/@types';
import React, { FC } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import RoleUtils from 'Resources/RoleUtils';
import moment from 'moment';

export interface UserListCardProps {
	user: User;
	initialCheckedState?: boolean;
	onClick?: (flag: boolean, event: User) => void;
	onRoleChange?: (id: string[], role: Role, action: 'add' | 'remove') => void;
	onUserUpdate?: (user: User) => void;
}

const UserListCard: FC<UserListCardProps> = ({ user, initialCheckedState = false, onClick, onRoleChange, onUserUpdate }) => {
	const { toggleFeatured, changeRoles, toggleBlocked } = useStoreActions(({ User: { toggleFeatured, changeRoles, toggleBlocked } }) => ({
		toggleFeatured,
		changeRoles,
		toggleBlocked,
	}));
	const { user: appUser } = useStoreState(({ Auth: { user } }) => ({ user }));

	const toggleFeaturedTask = useAsyncTask(toggleFeatured);
	const changeRolesTask = useAsyncTask(changeRoles);
	const toggleBlockedTask = useAsyncTask(toggleBlocked);

	const withToast = useToastMessage();

	const handleToggleFeatured = () => {
		withToast(
			async () => {
				await toggleFeaturedTask.run({ data: { id: user.id, isFeatured: !user.isFeatured } });
				onUserUpdate?.({ ...user, isFeatured: !user.isFeatured });
			},
			{ successToastMessage: `User ${user.isFeatured ? 'un-featured' : 'marked as featured'} successfully` }
		);
	};

	const handleRemoveRole = (role: Role) => {
		withToast(
			async () => {
				await changeRolesTask.run({ ids: [user.id], role: role, add: false }).then(() => {
					onRoleChange?.([user.id], role, 'remove');
				});
			},
			{ successToastMessage: `User role removed` }
		);
	};

	const handleToggleBlock = () => {
		withToast(
			async () => {
				await toggleBlockedTask.run({ data: user });
				onUserUpdate?.({ ...user, isBlocked: !user.isBlocked });
			},
			{ successToastMessage: `User ${user.isBlocked ? 'un-blocked' : 'blocked'}` }
		);
	};

	return (
		<EntityCard
			onClick={(flag: boolean) => {
				onClick?.(flag, user);
			}}
			title={getName(user)}
			titleTopTextNode={<Typography>{user.roles.join(', ')}</Typography>}
			subtitle={user.email}
			description={`Joined on ${moment(user.created).format('YYYY-MM-DD')}`}
			initialCheckedState={initialCheckedState}
			img={user.profileImages?.[0]}
			actionsProps={{
				config: [
					{
						action: user.isBlocked ? 'block' : 'un-block',
						label: user.isBlocked ? 'Blocked' : 'Un-blocked',
						noIcon: true,
						onClick: handleToggleBlock,
						disabled: toggleBlockedTask.status === 'PROCESSING',
						hidden: !RoleUtils.isAdmin(appUser),
					},
					{
						action: user.isFeatured ? 'featured' : 'un-featured',
						label: user.isFeatured ? 'Featured' : 'Un-featured',
						noIcon: true,
						onClick: handleToggleFeatured,
						disabled: toggleFeaturedTask.status === 'PROCESSING',
						hidden: !RoleUtils.isAdmin(appUser) || RoleUtils.isRegularUser(appUser),
					},
					...user.roles
						.filter((r) => r !== 'ADMIN')
						.map((role) => {
							return {
								label: `Remove ${_.capitalize(role)}`,
								noIcon: true,
								onClick: () => handleRemoveRole(role),
								disabled: changeRolesTask.status === 'PROCESSING',
							};
						}),
				],
				align: undefined,
			}}
		/>
	);
};

export default UserListCard;
