import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import EventForm from './EventForm';
import EventList from './EventList';
import Preview from './Preview';
export interface EventsProps {}

const Events: FC<EventsProps> = (props) => {
	const BASE_URL = '/dashboard/events';

	return (
		<Switch>
			<Route exact path={`${BASE_URL}`} component={EventList} />
			<Route exact path={`${BASE_URL}/create`} component={EventForm} />
			<Route path={`${BASE_URL}/update/:id`} component={EventForm} />
			<Route path={`${BASE_URL}/duplicate/:duplicateId`} component={EventForm} />
			<Route path={`${BASE_URL}/preview/:id`} component={Preview} />
		</Switch>
	);
};

export default Events;
