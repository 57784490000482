import React, { FC, useState } from 'react';
import { SnackbarProps, Portal } from '@material-ui/core';
import { ToastMessage, ToastProps } from 'mui-wrapper-utils';

interface ContextProps {
    open: boolean,
    message: string,
    variant: "success" | "error" | "info" | "warning" | undefined,
    showToast: (message: string, options?: Partial<IToastContext>) => void,
    hideToast: () => void
}

export const ToastContext = React.createContext<ContextProps>({
    open: false,
    message: '',
    variant: undefined,
    showToast: (message: string, options?: Partial<IToastContext>) => { },
    hideToast: () => { }
});

export interface IToastContext extends SnackbarProps {
    message?: string,
    variant?: "success" | "error" | "info" | "warning" | undefined
}

export const useAppToast = (initialState: IToastContext) => {
    const [toastState, setToastState] = useState({ open: false, message: '', ...initialState });

    const showToast = (message: string, options?: Partial<IToastContext>) => {
        setToastState({
            ...toastState,
            message,
            open: true,
            ...options
        })
    };
    const hideToast = () => {
        setToastState({
            ...toastState,
            open: false,
            message: ''
        })
    }
    return { showToast, hideToast, message: toastState.message || '', open: toastState.open, variant: toastState.variant };
}

const AppToastProvider: FC = (props) => {
    const { showToast, hideToast, message, open, variant } = useAppToast({ open: false });
    return (
        <ToastContext.Provider value={{
            message,
            open,
            showToast,
            hideToast,
            variant
        }}>
            {props.children}
            <Portal>
                <ToastMessage autoHideDuration={6000} message={message} open={open} variant={variant as ToastProps['variant']} onClose={hideToast} />
            </Portal>
        </ToastContext.Provider>
    )
}

export default AppToastProvider;