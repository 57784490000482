import { Fab, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import clsx from 'clsx';
import PGInputLabel from 'Features/ReactFormsInputs/PGInputLabel';
import { usePGInputStyle } from 'Features/ReactFormsInputs/useInputStyles';
import React, { FC } from 'react';
import Datetime, { DatetimepickerProps as DateTimePickerProps } from "react-datetime";
import "react-datetime/css/react-datetime.css";

export interface DateInputProps extends DateTimePickerProps {
    label?: string;
}

const DateInput: FC<DateInputProps> = (props) => {
    const classes = useStyles();
    const inputClass = usePGInputStyle();

    const { label, ...rest } = props;

    const handleClear = () => {
        rest.onChange?.('');
    }

    return (
        <>
            {label ? <PGInputLabel>{label}</PGInputLabel> : null}
            <div className={classes.root} >
                <Datetime
                    timeFormat={false}
                    dateFormat={'YYYY-MM-DD'}
                    className={classes.container}
                    inputProps={{
                        className: clsx(classes.input, inputClass.root),
                    }}
                    closeOnSelect
                    closeOnClickOutside
                    {...rest}
                />
                <IconButton size={'small'} onClick={handleClear} >
                    <CloseIcon fontSize={'small'} />
                </IconButton>
                <Fab size={'small'} >
                    <EventNoteRoundedIcon fontSize={'small'} />
                </Fab>
            </div>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex',
            width: '100%',
            '& button': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            }
        },
        container: {
            flex: 1
        },
        input: {
            width: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    }))
})

export default DateInput