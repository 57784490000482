import { Button, createStyles, Theme } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import { makeStyles } from '@material-ui/styles';
import Typo from 'Components/Typo';
import config from 'config';
import { usePermission } from 'Features/Authentication/usePermission';
import { Role } from 'Models/User/@types';
import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import utilities from 'Resources/Utils';
import Events from 'Screens/Events';
import Playlists from 'Screens/Playlists';
import Users from 'Screens/Users';
import { useStoreState } from 'Stores';
import Followers from 'Screens/Followers';
import DialogProvider from './DialogProvider';

interface NavButtonConfig {
	id: string;
	name: string;
	link: string;
	component: any;
	roles: Role[];
}

const DashboardBody = () => {
	const classes = useStyles();

	const { summary, user, token } = useStoreState(({ App: { summary }, Auth: { token, user } }) => ({ summary, user, token }));

	const { checkPermit } = usePermission();

	const navButtonsConfig: NavButtonConfig[] = [
		{
			id: '1',
			name: `Events (${utilities.getTotalCountString(summary?.eventCount || 0)})`,
			link: '/dashboard/events',
			component: Events,
			roles: ['ADMIN', 'CURATOR', 'COMMUNITY'],
		},
		{
			id: '2',
			name: `PlayLists (${utilities.getTotalCountString(summary?.playlistCount || 0)})`,
			link: '/dashboard/playlists',
			component: Playlists,
			roles: ['ADMIN'],
		},
		{
			id: '3',
			name: `Users (${utilities.getTotalCountString(summary?.user.total || 0)})`,
			link: '/dashboard/users',
			component: Users,
			roles: ['ADMIN'],
		},
		{
			id: '4',
			name: `Members (${user?.followersCount || 0})`,
			link: '/dashboard/members',
			component: Followers,
			roles: ['CURATOR', 'COMMUNITY'],
		},
		// TODO_SIRAJ fix TS error;
		// @ts-ignore
	].filter((x: NavButtonConfig) => checkPermit(x.roles));

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{navButtonsConfig.map((navBtn) => {
					return (
						<NavLink key={navBtn.id} to={navBtn.link} className={classes.linkContainer} activeClassName={classes.active}>
							<Button disableRipple className={classes.navButton} id={navBtn.id} color="primary" variant={'outlined'}>
								<Typo fontVariant="bold" weight="regular" variant="button">
									{navBtn.name}
								</Typo>
							</Button>
						</NavLink>
					);
				})}
				<Button
					disabled={!!user?.isTwitterAuthenticated}
					className={classes.twitter}
					disableRipple
					color="primary"
					variant={'outlined'}
					component={'a'}
					href={`${config.get('API_URL')}twitters/authorize-account?redirectUrl=${window.location.origin}/dashboard/users&access_token=${
						token?.accessToken
					}`}
				>
					<TwitterIcon />
					<Typo fontVariant="bold" weight="regular" variant="button">
						{user?.isTwitterAuthenticated ? 'Twitter account connected' : 'Twitter Authenticate'}
					</Typo>
				</Button>
			</div>

			<Switch>
				{navButtonsConfig.map((item) => {
					return <Route key={item.id} path={item.link} component={item.component} />;
				})}
			</Switch>
			<DialogProvider />
		</div>
	);
};

const STYLES = (theme: Theme) =>
	createStyles({
		root: {
			padding: '0 10px',
			'& a': {
				textDecoration: ' none',
				color: '#505050',
			},
		},
		container: {
			marginRight: 'auto',
			marginLeft: 0,
		},
		linkContainer: {
			textDecoration: ' none',
			marginRight: 10,
		},
		navButton: {
			width: 163,
			padding: '6px 0 10px 0',
			boxShadow: '0px 0px 5px #ccc',
		},
		active: {
			'& > button': {
				backgroundColor: theme.palette.primary.main,
				color: '#fff',
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
					color: '#fff',
				},
			},
		},
		twitter: {
			'& svg': {
				marginRight: 4,
			},
		},
	});

const useStyles = makeStyles(STYLES);

export default DashboardBody;
