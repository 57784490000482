import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { TFilter } from 'Models/App/@types';
import React, { FC, useEffect, useState, memo } from 'react';
import DateFilters from './DateFilters';
import PriceFilters from './PriceFilters';
import PublishFilter from './PublishFilter';
import Reccurring from './Recurringfilter';
import TagFIlters from './TagFIlters';
import moment from 'moment';
export interface EventFilterProps {
    handleFilterChange: (filter: TFilter) => void
}

export type TFilterType = 'tag' | 'clear' | 'date' | 'price' | 'publish' | 'recurring'
const FiletKeyMap: Record<TFilterType, string> = {
    'tag': 'tagIds',
    'date': 'eventDates.startDate',
    'price': 'priceCategory',
    'publish': 'isPublished',
    'recurring': 'frequency',
    'clear': ''
}
const EventFilter: FC<EventFilterProps> = memo((props) => {

    console.log('Filter');

    const classes = useStyles();
    const [filter, setFilter] = useState<TFilter['where']>({});
    const [clear, setClear] = useState<boolean>(false);
    const onChange = (type: TFilterType, value: any, toggle: boolean = false) => {
        let where: TFilter['where'] = { ...filter };
        switch (type) {
            case 'recurring': {
                if (value)
                    where = { ...where, frequency: { neq: "" } }
                else
                    where = { ...where, frequency: '' }
                break

            }
            case 'clear': where = {}; break;
            case 'date': {
                if (!value) {
                    delete where[FiletKeyMap[type]];
                }
                else {
                    where = { ...where, [FiletKeyMap[type] as string]: { between: [moment.utc(value).startOf('day').format(), moment.utc(value).endOf('day').format()] } }
                }
                break;
            }
            default: {
                if (toggle) {
                    delete where[FiletKeyMap[type]]
                } else {
                    where = { ...where, [FiletKeyMap[type] as string]: value }
                }
            }
        }
        setFilter(where);
        props.handleFilterChange({
            where
        })
    }
    const FilterConfig: { component: JSX.Element, title: string }[] = [
        {
            title: 'Event Categories',
            component: <TagFIlters onChange={onChange} clear={clear} />
        },
        {
            title: 'Date',
            component: <DateFilters onChange={onChange} clear={clear} />
        },
        {
            title: 'Pricing',
            component: <PriceFilters onChange={onChange} clear={clear} />
        },
        {
            title: 'Other',
            component: <PublishFilter onChange={onChange} clear={clear} />
        },
        {
            title: 'Recurring Events',
            component: <Reccurring onChange={onChange} clear={clear} />
        }
    ]
    useEffect(() => {
        if (clear)
            setClear(false);
    }, [clear])
    return (
        <Box className={classes.root}>
            <Paper elevation={4} className={classes.paper}>
                <Box position='absolute' top={'5px'} right='5px' >
                    <Button disableFocusRipple disableRipple color='secondary' variant='text' onClick={() => { onChange('clear', ''); setClear(true); }}>Clear Filters</Button>
                </Box>
                {
                    FilterConfig.map((val, i) => {
                        return <Box key={i} my={1}>
                            <Box mb={1}><Typography variant='h6'>{val.title}</Typography></Box>
                            {val.component}
                        </Box>
                    })
                }
            </Paper>
        </Box>
    )
}, (prev, next) => true);

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: '100%',
            // height: 540,
        },
        paper: {
            height: '100%',
            width: '100%',
            padding: 16,
            position: 'relative'
        }
    }))
})

export default EventFilter