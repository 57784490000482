import { Box, Button } from '@material-ui/core';
import Typo from 'Components/Typo';
import React, { FC, useEffect, useState } from 'react';
import { filterItemStyles } from './DateFilters';
import { TFilterType } from './EventFilter';

export interface PublishFilterProps {
    onChange: (type: TFilterType, value: any, toggle: boolean) => void
    clear?: boolean
}

const PublishFilter: FC<PublishFilterProps> = (props) => {
    const classes = filterItemStyles();
    const { onChange } = props;
    const [selected, setSelected] = useState<boolean>();
    useEffect(() => {
        if (props.clear) {
            setSelected(undefined);
        }
    }, [props.clear])
    return (
        <Box className={classes.wrapper}>
            <Box mr={1} className={selected === true ? classes.border : ''}>
                <Button className={classes.button} variant='contained' color='default' onClick={() => { setSelected(selected === true ? undefined : true); onChange('publish', true, selected === true) }}>
                    <Typo weight='bold' variant='overline'>{'Published'}</Typo>
                </Button>
            </Box><Box mr={1} className={selected === false ? classes.border : ''}>
                <Button className={classes.button} variant='contained' color='default' onClick={() => { setSelected(selected === false ? undefined : false); onChange('publish', false, selected === false) }}>
                    <Typo weight='bold' variant='overline'>{'Draft'}</Typo>
                </Button>
            </Box>
            {/* <Box mr={1}>
                <Button className={classes.button} variant='contained' color='default'>
                    <Typo weight='bold' variant='overline'>{'Inactive'}</Typo>
                </Button>
            </Box> */}
        </Box>
    )
}



export default PublishFilter