import DateInput from 'Components/Inputs/DateInput';
import _ from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { IFieldProps } from 'react-forms';

const DATE_FORMAT = 'YYYY-MM-DD';
export interface PGDateInputProps extends IFieldProps {
    fieldProps: {
        label?: string;
    }
}

const PGDateInput: FC<PGDateInputProps> = (props) => {
    const { formikProps, fieldConfig, fieldProps } = props;

    const valueKey = fieldConfig?.valueKey || '';
    const value = _.get(formikProps?.values, valueKey);

    const [date, setDate] = useState<string>(moment(value).format(DATE_FORMAT));

    useEffect(() => {
        const dateObj = moment(`${date}`, `${DATE_FORMAT}`).toISOString();
        formikProps?.setFieldValue(valueKey, dateObj);
        // eslint-disable-next-line 
    }, [date]);

    return (
        <DateInput
            label={fieldProps.label}
            onChange={v => setDate(moment(v).format(DATE_FORMAT))}
            value={moment(date, DATE_FORMAT)}
        />
    )
}

export default PGDateInput