import TimezoneInput from 'Features/ReactFormsInputs/TimezoneInput';
import { TEvent } from 'Models/Event/@types';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import utilities from 'Resources/Utils';

export interface EventFormTimeZoneInputProps extends IFieldProps<Partial<TEvent>> { }

const EventFormTimeZoneInput: FC<EventFormTimeZoneInputProps> = (props) => {

    const { formikProps } = props;

    const handleChange = (timeZone: TEvent['timeZone']) => {
        formikProps?.setValues({ ...formikProps.values, timeZone });
    }

    const value = `${formikProps?.values.timeZone?.code}-${formikProps?.values.timeZone?.name}`;

    const errorText = formikProps ? utilities.getFieldError('timeZone', formikProps) : '';

    return (
        <TimezoneInput
            onTimezoneChange={handleChange}
            value={value}
            errorText={errorText}
        />
    )
}

export default EventFormTimeZoneInput