import TextInput, { TextInputProps } from 'Components/Inputs/TextInput';
import { FormikValues } from 'formik';
import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import utilities from 'Resources/Utils';
import _ from 'lodash';

export interface PGTextInputProps extends IFieldProps {
    fieldProps?: TextInputProps
}

const PGTextInput: FC<PGTextInputProps> = (props) => {
    const { fieldProps = {} as TextInputProps, formikProps = {} as FormikValues, fieldConfig } = props;
    const fieldError = utilities.getFieldError((fieldProps.name || ''), formikProps);
    const updatedProps:TextInputProps = {
        ...fieldProps,
        error: !!fieldError,
        errorText: fieldError || fieldProps.errorText || '',
        helperText: fieldProps.helperText || '',
        onChange: formikProps.handleChange,
        onBlur: formikProps.handleBlur,
        value: _.get(formikProps.values, fieldConfig?.valueKey || '') || '',
    };
    return (
        <TextInput {...updatedProps} />
    )
}


export default PGTextInput