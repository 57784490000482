import React from 'react';
import { FormSectionProps } from "Components/Forms/FormSection"
import { attachField, IReactFormProps } from "react-forms"
import PGTextInput from "Features/ReactFormsInputs/PGTextInput"
import PGDropZoneInput from "Features/ReactFormsInputs/PGDropZoneInput"
import PGMainImageInput from "Features/ReactFormsInputs/PGMainImageInput"
import PGSelectInput from "Features/ReactFormsInputs/PGSelectInput"
import PGTextAreaInput from "Features/ReactFormsInputs/PGTextAreaInput"
import TimezoneInput from 'Features/ReactFormsInputs/TimezoneInput';
import { Tag } from 'Models/Tags/@types';
import { Playlist } from 'Models/Playlist/@types';
import PlaylistEventAutocomplete from './PlaylistEventAutocomplete';

attachField('textInput', <PGTextInput />)
// @ts-ignore
attachField('drop-file', <PGDropZoneInput />)
// @ts-ignore
attachField('file-picker', <PGMainImageInput />)
// @ts-ignore
attachField('select-field', <PGSelectInput />)

attachField('textarea', <PGTextAreaInput />);

attachField('timezone', <TimezoneInput />);

attachField('playlist-event-suggest', <PlaylistEventAutocomplete />);

export const getPlaylistFormSchema = (tags: Tag[]): FormSectionProps<Playlist>[] => {
    return ([
        {
            heading: 'Playlist Name',
            formId: 'name',
            config: [
                {
                    type: 'textInput',
                    valueKey: 'name',
                    fieldProps: {
                        fullWidth: true,
                        placeholder: 'Playlist Name'
                    }
                }
            ]
        },
        {
            heading: 'Main Image',
            formId: 'image',
            config: getImageSectionSchema()
        },
        {
            heading: 'Details',
            formId: 'details',
            config: getDetailsSectionSchema(tags)
        }
    ])
}

const getImageSectionSchema = (): IReactFormProps['config'] => {
    return ([
        {
            type: 'drop-file',
            valueKey: 'image',
            fieldProps: {
                multiple: false
            }
        }
    ])
}

const getDetailsSectionSchema = (tags: Tag[]): IReactFormProps['config'] => {
    return ([
        {
            type: 'select-field',
            valueKey: 'categoryId',
            fieldProps: {
                label: 'Event Type',
                options: [{ label: 'Select Category', value: '' }, ...tags.map((t) => ({ label: t.name, value: t.id }))],
                fullWidth: true
            }
        },
        {
            type: 'textInput',
            valueKey: 'shortDescription',
            fieldProps: {
                fullWidth: true,
                label: 'One Liner description'
            }
        },
        {
            type: 'textarea',
            valueKey: 'description',
            fieldProps: {
                fullWidth: true,
                label: 'Description',
                rowsMin: 4,
            }
        },
        {
            type: 'playlist-event-suggest',
            valueKey: '',
        }
    ])
}