import AutoSuggest from 'Components/Inputs/AutoSuggest';
import { TFilter } from 'Models/App/@types';
import EventModel from 'Models/Event';
import { TEvent } from 'Models/Event/@types';
import React, { FC } from 'react';
import { parseEvent } from 'Models/Event/eventParsers';

export interface EventSuggestInputProps {
    onResultClick?: (u: TEvent) => void;
    filter?: TFilter;
    label?: string;
}

const EventSuggestInput: FC<EventSuggestInputProps> = ({ onResultClick, filter, label = 'Search events' }) => {

    return (
        <AutoSuggest
            fetchSuggestions={async input => {
                const res = await EventModel.searchEvents(input, filter);
                return res.results.map(x => parseEvent(x.hit));
            }}
            autoCompleteProps={{
                getOptionLabel: (option) => option.name,
                getOptionSelected: (option) => !!option.name,
            }}
            placeholder={label}
            onResultClick={onResultClick}
            retainInputAfterSelect={false}
        />
    )
}


export default EventSuggestInput