export interface TTimeZone { code: string; country: string; timezone: string; offset: string; abbreviation: string }

export let TimeZones = {
    getTimeZoneOptions() {
        return timeZones.map(i => {
            return {
                label: i.timezone.replace(/\//g, ' '),
                key: i.timezone
            }
        })
    },
    getOffset(timeZone: string) {
        const index = timeZones.findIndex(i => i.timezone === timeZone)
        return timeZones[index].offset;
    },
    getTZItem(offset: string) {
        return timeZones.find(x => x.offset === offset);
    }
}
export const getTimeZoneOptions = (tz = timeZones) => {
    return tz
    //     .map(i => {
    //     return {
    //         label: `${i.country}: ${i.timezone}`,
    //         key: JSON.stringify(i)
    //     }
    // })
}
export const getOffset = (timeZone: string) => {
    const index = timeZones.findIndex(i => i.timezone === timeZone)
    return timeZones[index].offset;
}

export const timeZones: TTimeZone[] = [
    {
        "abbreviation": "NUT",
        "code": "NU",
        "country": "Niue",
        "timezone": "Pacific/Niue",
        "offset": "UTC -11:00"
    },
    {
        "abbreviation": "HAST",
        "code": "US",
        "country": "United States",
        "timezone": "Pacific/Honolulu",
        "offset": "UTC -10:00"
    },
    {
        "abbreviation": "PHST",
        "code": "PH",
        "country": "Philippines",
        "timezone": "Asia/Manila",
        "offset": "UTC +08:00"
    },
    {
        "abbreviation": "AKST",
        "code": "US",
        "country": "United States",
        "timezone": "America/Adak",
        "offset": "UTC -09:00"
    },
    {
        "abbreviation": "AKDT",
        "code": "US",
        "country": "United States",
        "timezone": "America/Anchorage",
        "offset": "UTC -08:00"
    },
    {
        "abbreviation": "PST",
        "code": "US",
        "country": "United States",
        "timezone": "America/Los_Angeles",
        "offset": "UTC -07:00"
    },
    {
        "abbreviation": "MST",
        "code": "US",
        "country": "United States",
        "timezone": "America/Denver",
        "offset": "UTC -06:00"
    },
    {
        "abbreviation": "CST",
        "code": "US",
        "country": "United States",
        "timezone": "America/Chicago",
        "offset": "UTC -06:00"
    },
    {
        "abbreviation": "EST",
        "code": "US",
        "country": "United States",
        "timezone": "America/New_York",
        "offset": "UTC -04:00"
    },
    {
        "abbreviation": "BRT",
        "code": "BR",
        "country": "Brazil",
        "timezone": "America/Belem",
        "offset": "UTC -03:00"
    },
    {
        "abbreviation": "IST",
        "code": "IN",
        "country": "India",
        "timezone": "Asia/Kolkata",
        "offset": "UTC +05:30"
    },
    {
        "abbreviation": "CET",
        "code": "LV",
        "country": "Latvia",
        "timezone": "Europe/Riga",
        "offset": "UTC +02:00"
    },
    {
        "abbreviation": "AWST",
        "code": "AU",
        "country": "Australia",
        "timezone": "Australia/Perth",
        "offset": "UTC +08:00"
    },
    {
        "abbreviation": "ACST",
        "code": "AU",
        "country": "Australia",
        "timezone": "Australia/Darwin",
        "offset": "UTC +09:30"
    },
    {
        "abbreviation": "AEST",
        "code": "AU",
        "country": "Australia",
        "timezone": "Australia/Brisbane",
        "offset": "UTC +10:00"
    },
]
