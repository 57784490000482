import { JSONType } from 'Typings/Global';
import { User } from './@types';

export const parseUser = (data: JSONType): User => {
	const {
		id,
		firstName,
		lastName,
		username,
		name,
		email,
		created,
		updated,
		roles = [],
		_profileImages,
		featured,
		isPublished,
		isTwitterAuthenticated,
		followersCount,
		advanceFollow,
	} = data;
	const user: User = {
		id,
		firstName,
		lastName,
		username,
		name,
		created,
		updated,
		email,
		roles: roles.map((r: any) => r.name),
		profileImages: _profileImages,
		isFeatured: !!featured,
		isBlocked: !!!isPublished,
		isTwitterAuthenticated: !!isTwitterAuthenticated,
		followersCount: followersCount || 0,
		advanceFollow: !!advanceFollow,
	};
	return user as User;
};

export const parseUserFormData = (user: Partial<User>): JSONType => {
	let formData = {};

	const { id, firstName, lastName, username, name, isFeatured, phone, profileImages, password, advanceFollow } = user;

	formData = {
		...(id ? { id } : {}),
		name,
		firstName,
		lastName,
		username,
		featured: isFeatured,
		phone,
		_profileImages: profileImages,
		password,
		advanceFollow: !!advanceFollow,
	};

	return formData;
};
