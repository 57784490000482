import { Box, Collapse } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchBar from 'Components/Inputs/SearchBar';
import Pagination from 'Components/Pagination';
import DashboardActionButtonsList from 'Features/Dashboard/DashboardActionButtonsList';
import EventCard from 'Features/Event/EventCard';
import EventFilter from 'Features/Event/EventFilter/EventFilter';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { useSearch } from 'Hooks/useSearch';
import { TFilter } from 'Models/App/@types';
import EventModel from 'Models/Event';
import { TEvent } from 'Models/Event/@types';
import React, { FC, useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStoreActions, useStoreState } from 'Stores';
import LayoutWithLoader from 'Components/LayoutWithLoader';
import Loader from 'Components/Loader';
import useToastMessage from 'Hooks/useToastMessage';
import AxiosUtils from 'Resources/AxiosUtils';
import RoleUtils from 'Resources/RoleUtils';
import { parseEvent } from 'Models/Event/eventParsers';

export interface EventListProps extends RouteComponentProps {}

const EventList: FC<EventListProps> = (props) => {
	const { history } = props;

	const classes = useStyles();
	const { eventList, user } = useStoreState(({ Events: { eventList }, Auth: { user } }) => ({ eventList, user }));
	const [showFilter, setShowFilter] = useState(false);
	const { fetchEventList, deleteEvent, toggleFavourite } = useStoreActions(({ Events: { fetchEventList, deleteEvent, toggleFavourite } }) => ({
		fetchEventList,
		deleteEvent,
		toggleFavourite,
	}));
	const NUM_EVENTS_TO_SHOW = 20;
	const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
	const fetchEventsTask = useAsyncTask(fetchEventList);
	const toggleFavouriteTask = useAsyncTask(toggleFavourite);
	const [extraParams, setExtraParams] = useState<{ pageNum: number; filter?: TFilter }>({ pageNum: 0 });
	const deleteEventTask = useAsyncTask(deleteEvent);
	const withConfirmationDialog = useConfirmationDialog();

	const withToast = useToastMessage();

	const { handleChange, results: searchResults, searchTerm, setSearchResults } = useSearch<TEvent>(
		EventModel.searchEvents,
		{ ...(RoleUtils.isAdmin(user) ? {} : { where: { userId: user?.id } }) },
		parseEvent
	);

	const handleDelete = useCallback(() => {
		if (selectedEvents.length)
			withConfirmationDialog(
				async () => {
					await deleteEventTask.run({ ids: selectedEvents }).then((e) => {
						deleteFromSearchResults(selectedEvents);
					});
				},
				{ message: `These ${selectedEvents.length} events will be deleted. Are you sure you want to continue?`, agreeText: 'Confirm' },
				{ successToastMessage: 'Events deleted successfully' }
			);
		// eslint-disable-next-line
	}, [selectedEvents]);

	const handleFilterChange = useCallback((filter: TFilter) => {
		setExtraParams({ pageNum: 0, filter });
	}, []);
	const nextPage = useCallback(() => setExtraParams({ ...extraParams, pageNum: extraParams.pageNum + 1 }), [extraParams]);

	const prevPage = useCallback(() => {
		if (extraParams.pageNum > 0) setExtraParams({ ...extraParams, pageNum: extraParams.pageNum - 1 });
	}, [extraParams]);

	const handleClick = (flag: boolean, event: TEvent) => {
		if (flag) {
			setSelectedEvents([...selectedEvents, event.id]);
			return;
		}
		setSelectedEvents(selectedEvents.filter((id) => event.id !== id));
	};

	useEffect(() => {
		fetchEventsTask.run({
			params: {
				filter: {
					limit: NUM_EVENTS_TO_SHOW,
					skip: extraParams.pageNum * NUM_EVENTS_TO_SHOW,
					order: 'eventDates.startDate DESC',
					...extraParams.filter,
					where: { ...(RoleUtils.isAdmin(user) ? {} : { userId: user?.id }), ...extraParams.filter?.where },
				},
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extraParams]);

	const handleFavouriteClick = useCallback(() => {
		let publishedNonFavouritedEventIds =
			(searchTerm ? searchResults : eventList)
				.filter((e) => selectedEvents.includes(e.id))
				.filter((e) => e.isPublished)
				.filter((e) => !e.isFavourited)
				.map((e) => e.id) || [];
		if (publishedNonFavouritedEventIds.length) {
			withToast(
				async () => {
					await toggleFavouriteTask.run({ eventIds: publishedNonFavouritedEventIds, favourite: true }).catch(AxiosUtils.throwError);
					updateSearchResults(publishedNonFavouritedEventIds, 'isFavourited', true);
				},
				{ successToastMessage: 'Events favourited successfully' }
			);
		}
		// eslint-disable-next-line
	}, [searchTerm, searchResults, selectedEvents]);

	const updateSearchResults = (eventIds: string[], key: keyof TEvent, flag: boolean = true) =>
		setSearchResults(EventModel.updateEventsFlag(key, searchResults, eventIds, flag));

	const deleteFromSearchResults = (ids: string[]) => setSearchResults(searchResults.filter((e) => !ids.includes(e.id)));

	const toggleShowFilter = useCallback(() => {
		setShowFilter((showFilter) => !showFilter);
	}, []);

	const goToCreate = useCallback(() => {
		history.push('/dashboard/events/create');
	}, [history]);

	const handleSearchTermChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleChange(e.target.value);
		},
		[handleChange]
	);

	return (
		<LayoutWithLoader>
			<div className={classes.actionsContainer}>
				<DashboardActionButtonsList
					config={[
						{
							action: 'filter',
							onClick: toggleShowFilter,
						},
						{
							action: 'add',
							label: 'Add Event',
							onClick: goToCreate,
						},
					]}
					align={'ltr'}
				/>
				<SearchBar placeholder={'Search Events...'} value={searchTerm} onChange={handleSearchTermChange} />
			</div>
			<div className={classes.actionsContainer}>
				<Pagination previous={prevPage} next={nextPage} />
				<DashboardActionButtonsList
					config={[
						{
							action: 'favourite',
							label: 'Favourites',
							onClick: handleFavouriteClick,
							disabled: toggleFavouriteTask.status === 'PROCESSING',
							hidden: true,
						},
						{ action: 'delete', label: 'Delete', onClick: handleDelete, disabled: deleteEventTask.status === 'PROCESSING' },
					]}
					align={'rtl'}
				/>
			</div>
			<Collapse timeout={500} in={showFilter}>
				{showFilter ? <EventFilter handleFilterChange={handleFilterChange} /> : null}
			</Collapse>
			<Box mt={4} position={'relative'}>
				{fetchEventsTask.status === 'PROCESSING' ? (
					<Loader />
				) : (
					(searchTerm ? searchResults : eventList).map((event, index) => {
						return (
							<EventCard
								key={event.id}
								event={event}
								initialCheckedState={selectedEvents.includes(event.id)}
								onClick={handleClick}
								afterToggleFavourite={(id, f) => updateSearchResults([id], 'isFavourited', f)}
								afterToggleFeatured={(id, f) => updateSearchResults([id], 'isFeatured', f)}
								afterTogglePublish={(id, f) => updateSearchResults([id], 'isPublished', f)}
								afterDelete={() => deleteFromSearchResults([event.id])}
							/>
						);
					})
				)}
			</Box>
		</LayoutWithLoader>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		actionsContainer: {
			marginTop: 20,
			display: 'flex',
			justifyContent: 'space-between',
		},
	});
});

export default EventList;
