import { Role } from 'Models/User/@types';
import { FC, ReactElement } from 'react';
import { usePermission } from './usePermission';

export interface PermissionAccessProps {
    roles?: Role[];
    children: ReactElement
}

const PermissionAccess: FC<PermissionAccessProps> = ({ roles = [], children }) => {

    const { isPermitted } = usePermission(roles);

    return (
        isPermitted ? children : null
    )
}

export default PermissionAccess