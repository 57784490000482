import { parsePicture } from "./pictureParser";
import utilities from "Resources/Utils";
import { request } from "Resources/AxiosUtils";
import { UploadProgress } from "Typings/Global";
import { TFile } from "react-forms";
import { Picture } from "./@types";

class PictureModel {

    static uploadSequential = async (files: any[], onEachUpload: any) => {
        utilities.sequentialPromises(files, (fileItem: any) => PictureModel.upload(fileItem), onEachUpload)
    }

    static upload = async (pictureObject: TFile, fileName?: string, folderName?: string, setProgress?: (progress: number) => any) => {
        const { base64, name } = pictureObject;
        const res = await request<Picture>({
            url: 'pictures/upload',
            data: { base64img: base64, filename: fileName || name, folder: folderName || 'default' },
            method: 'POST',
            onUploadProgress: (progressEvent: UploadProgress) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress && setProgress(Math.floor(progress));

            },
        }).catch(error => { throw error });
        return parsePicture(res);
    }
}

export default PictureModel;